import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AppNavigator from "../../Navigators/AppNavigator";
import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import authModule from "../../Modules/auth/auth-module";

class RootScreen extends Component {
  async componentDidMount() {
    if (this.props.isLoggedIn) {
      authModule.initAuth({
        teamId: this.props.team?.user?._id,
      });
    }
  }

  render() {
    return <AppNavigator />;
  }
}

RootScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
