import React from "react";
import api from "../../Services/Api/api";

export const AvatarStack = ({ users }) => {
  let filteredUsers =
    users?.length > 4
      ? [
          ...users?.slice(0, 4),
          { _id: "xyz", firstName: `+${users.length - 4}` },
        ]
      : users?.slice(0, 4);

  return (
    <div className="currentActiveUsers">
      {filteredUsers?.slice(0, 5).map((user, index) => (
        <div key={index} className="cauItem">
          {user.image ? (
            <img
              src={api.getFileLink(user.image)}
              style={{ width: "100%", height: "100%" }}
              alt="User Avatar"
            />
          ) : (
            getInitials(user)
          )}
        </div>
      ))}
    </div>
  );
};

const Avatar = ({ user }) => {
  return (
    <>
      {user.image ? (
        <img
          src={api.getFileLink(user.image)}
          style={{ width: "100%", height: "100%" }}
          alt="User Avatar"
        />
      ) : (
        getInitials(user)
      )}
    </>
  );
};

const getInitials = (user) => {
  const { firstName = "", lastName = "" } = user;

  let part1, part2;
  if (firstName?.trim() && lastName?.trim()) {
    part1 = firstName;
    part2 = lastName;
  } else {
    let fullName = (firstName?.trim() || "") + " " + (lastName?.trim() || "");
    let parts = fullName.trim().split(" ");

    part1 = parts[0];
    part2 = parts[Math.max(1, parts.length - 1)];
  }

  if (part1 && part2) {
    return part1.charAt(0).toUpperCase() + part2.charAt(0).toUpperCase();
  } else if (part1 || part2) {
    let part = part1 || part2;

    return part.charAt(0).toUpperCase() + (part.charAt(1) || "").toLowerCase();
  } else {
    return "";
  }
};

export default Avatar;
