import React from "react";
import { Route, Routes } from "react-router-dom";

// screens
import MainScreen from "../Views/Root/MainScreen";
import NotFound404 from "../Views/Common/not-found-404";
import DashboardScreen from "../Views/Dashboard/DashboardScreen";
import ProjectsScreen from "../Views/Project/ProjectsScreen";
import ProjectDetailScreen from "../Views/Project/ProjectDetailScreen";
import BuilderScreen from "../Views/Builder/BuilderScreen";
import BuilderScreenUI from "../Views/Builder/BuilderScreenUI";
import DatabaseViewerScreen from "../Views/DatabaseViewer/DatabaseViewer";
import TableViewerScreen from "../Views/DatabaseViewer/TableViewer";
import EmailBuilderScreen from "../Views/Builder/EmailBuilderScreen";
import UsersScreen from "../Views/Users/UsersScreen";

function MainScreenNavigator(props) {
  return (
    <MainScreen {...props}>
      <Routes>
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route path="/projects" element={<ProjectsScreen />} />
        <Route path="/users" element={<UsersScreen />} />
        <Route path="/project/:projectId" element={<ProjectDetailScreen />} />
        <Route path="/canvas/:componentId" element={<BuilderScreen />} />
        <Route path="/canvas/:componentId/ui" element={<BuilderScreenUI />} />
        <Route path="/email-template/:componentId" element={<EmailBuilderScreen />} />
        <Route path="/database" element={<DatabaseViewerScreen />} />
        <Route path="/database/:databaseId/table/:tableName" element={<TableViewerScreen />} />
        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </MainScreen>
  );
}
export default MainScreenNavigator;
