import React from "react";
import api from "../../Services/Api/api";
import { AvatarStack } from "../../Components/etc/Avatar";

class UserPresence extends React.Component {
  state = {
    userPresences: [],
    users: [],
  };

  componentDidMount() {
    this.load();
    this.timer = setInterval(() => {
      this.load();
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  load() {
    if (this.props.component._id) {
      api
        .socket("v1/component/user-presence", {
          component: this.props.component._id,
        })
        .then(({ userPresences }) => {
          this.setState({
            userPresences,
            users: userPresences.map((x) => x.user),
          });
        })
        .catch((e) => {
          console.log("Error updating user presence");
        });
    }
  }

  render() {
    return <AvatarStack users={this.state.users} />;
    return (
      <div style={{ display: "none" }}>
        <div style={{ display: "flex", gap: "2px" }}>
          {this.state.userPresences?.map((userPresence) => (
            <div key={userPresence._id}>
              <div>{userPresence.user.firstName}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default UserPresence;
