import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import api from "../../Services/Api/api";
import NavBar from "../Common/NavBar";
import withRouter from "../../Components/Wrapper/with-router";
import { DatabasesPopup } from "./ProjectDatabase";
import { AddUserPopup } from "./AddUser";
import Screens from "./Screens";
import ChangeLog from "./ChangeLog";

const ProjectDetailInner = (props) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(null);
  const [triggerReload, setTriggerReload] = useState(null);

  const projectId = props.projectId;

  const load = useCallback(async () => {
    setLoading(true);
    try {
      const { project, components } = await api.get("v1/project/" + projectId);
      setProject(project);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
    }
  }, [projectId]);

  useEffect(() => {
    load();
  }, [load]);

  const editProject = async (obj) => {
    let payload = { ...obj };
    if (payload.permissions) {
      payload.permissions = payload.permissions.map((x) => ({
        ...x,
        user: x.user._id || x.user,
      }));
    }

    await api.put("v1/project", {
      _id: projectId,
      ...payload,
    });

    await load();
  };

  const removeUser = async (userId) => {
    try {
      await editProject({
        permissions: project.permissions?.filter((x) => x.user._id !== userId),
      });
      await load();
    } catch (e) {
      toast.error(e.message);
    }
  };

  const addUser = async (userId) => {
    await editProject({
      permissions: [
        ...(project.permissions || []),
        { user: userId, permission: "owner" },
      ],
    });
  };

  return (
    <>
      <NavBar activeLink="/projects" />
      {loading && !project ? (
        <div className="loader"></div>
      ) : project ? (
        <div>
          <div style={{ display: "flex" }}>
            <div>
              {project.image ? (
                <img
                  src={api.getFileLink(project.image)}
                  style={{ width: "100px", height: "100px" }}
                />
              ) : null}
            </div>
            <div>
              <div>{project?.name}</div>
              <div>{project?.subdomain}</div>
              <div>{project?.description}</div>
            </div>
          </div>

          <div>
            <div>Database Configuration</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{project.databases?.map((x) => x.name).join(",")}</div>
              <div onClick={() => setVisibleModal("database")}>Edit</div>
            </div>
            <DatabasesPopup
              {...{
                key: (visibleModal === "daatbases").toString(),
                visible: visibleModal === "database",
                setVisible: (x) => setVisibleModal(x),
                selectedDatabases: project.databases,
                onSubmit: editProject,
              }}
            />
          </div>

          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Users</div>
              <div onClick={() => setVisibleModal("addUser")}>Add</div>
            </div>
            <div>
              {project.permissions?.map(({ user }) => (
                <div key={user._id}>
                  <div>{user.firstName} </div>
                  {user._id !== props.user._id ? (
                    <div onClick={() => removeUser(user._id)}>Remove</div>
                  ) : null}
                </div>
              ))}
            </div>
            <AddUserPopup
              {...{
                visible: visibleModal === "addUser",
                setVisible: (x) => setVisibleModal(x),
                addUser,
              }}
            />
          </div>

          <Screens projectId={projectId} key={triggerReload} />

          <ChangeLog
            projectId={projectId}
            reload={() => {
              load();
              setTriggerReload(Date.now());
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.pState.AUTH.user,
});

const ProjectDetail = connect(mapStateToProps)(ProjectDetailInner);

const ProjectDetailScreen = withRouter((props) => {
  const projectId = props.router.params.projectId;
  return <ProjectDetail projectId={projectId} />;
});

export default ProjectDetailScreen;
