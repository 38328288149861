import React, { useImperativeHandle, useRef, useState } from "react";
import { DropTarget, DragSource } from "react-dnd";
import _ from "lodash";

import hoverPositionModule from "../../appxolo-engine/builder/hover-position";
const { getHeirarchyPosition } = hoverPositionModule;

const Hierarchy = ({
  dom,
  builderData: { focusedElement },
  setBuilderData,
}) => {
  const [hoverPosition, setHoverPosition] = useState("");

  const setFocusedElement = (x) => setBuilderData({ focusedElement: x });

  const renderNameBox = (element) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {_.startCase(element.value.elementType)}{" "}
        <span style={{ fontSize: "9px", color: "#999" }}>(#{element.id})</span>
      </div>
    );
  };

  const renderElement = (element, indices) => {
    const isSelected = focusedElement?.element?.id === element.id;

    return (
      <div key={element.id}>
        <DroppableItem
          {...{
            index: indices,
            element,
            dom,
            hoverPosition,
            setHoverPosition,
            setBuilderData,
          }}
        >
          <div>
            <div
              className="hierarchyItemUnit"
              style={{
                margin: `0px 16px 12px ${16 * indices.length}px`,
                ...(isSelected ? { border: "1px solid #212121" } : {}),
              }}
              onClick={() => {
                setFocusedElement({ element, indices });
              }}
            >
              {renderNameBox(element)}
            </div>
          </div>
        </DroppableItem>
        <div style={{ borderBottom: "0px #eeeeee solid" }}></div>
        <div style={{}}>
          {element.children?.map((x, i) => renderElement(x, [...indices, i]))}
        </div>
      </div>
    );
  };

  return (
    <div className="builderComboBox">
      <div className="builderComboBoxHeader">
        <div className="builderComboBoxHeaderItem active">Hierarchy</div>
        <div className="builderComboBoxHeaderItem">Backups</div>
      
        
      </div>
      <div className="builderHierarchyList">{dom?.tree?.children?.map((x, i) => renderElement(x, [i]))}</div>
    </div>
  );
};

const DroppableItemInner = React.forwardRef(
  (
    {
      hover,
      hoverPosition,
      isDragging,
      connectDragSource,
      connectDropTarget,
      children,
    },
    ref
  ) => {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);

    const opacity = isDragging ? 0.2 : 1;

    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));

    return (
      <div
        className="wrapper"
        ref={elementRef}
        style={Object.assign(
          {},
          { opacity },
          hover
            ? hoverPosition === "top"
              ? {
                  borderTop: "1px solid blue",
                  borderBottom: "none",
                }
              : hoverPosition === "bottom"
              ? {
                  borderTop: "none",
                  borderBottom: "1px solid blue",
                }
              : {
                  borderTop: "none",
                  borderBottom: "none",
                }
            : {}
        )}
      >
        {children}
      </div>
    );
  }
);

const DroppableItem = DropTarget(
  "item",
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragItem = monitor.getItem();
      const hoveredElement = props.element;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (
        dragItem.set === "canvasElement" &&
        dragItem.id === hoveredElement.id
      ) {
        return;
      }

      let hoverPosition = getHeirarchyPosition(
        node,
        monitor,
        hoveredElement.value.elementType
      );

      // if hovered item is a child of dragged item
      if (hoverIndex?.join()?.match(new RegExp(`^${dragItem.index?.join()}`))) {
        hoverPosition = null;
      }

      if (props.hoverPosition !== hoverPosition)
        props.setHoverPosition(hoverPosition);
    },
    drop(props, monitor, component) {
      if (!component) {
        return undefined;
      }
      const dragItem = monitor.getItem();
      const dropIndex = props.index;
      const dropItem = props.element;
      const dom = props.dom;

      // Don't replace items with themselves
      if (dragItem.set === "canvasElement") {
        if (dragItem.id === dropItem.id) return;

        if (dropIndex?.join()?.match(new RegExp(`^${dragItem.index.join()}`)))
          return;
      }

      let movingNode = dragItem;

      if (dragItem.set !== "canvasElement") {
        const { newNode } = dom.addNodeValue("ROOT", dragItem);
        movingNode = newNode;
      }

      console.log({ dom, movingNode, dragItem, dropItem, dropIndex });
      dom.moveNode(
        movingNode.id,
        dropItem.id,
        {
          center: "inside",
          top: "before",
          bottom: "after",
          left: "before",
          right: "after",
        }[props.hoverPosition]
      );
    },
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      hover: monitor.isOver({ shallow: true }),
    };
  }
)(
  DragSource(
    "item",
    {
      beginDrag: (props) => ({
        index: props.index,
        set: "canvasElement",
        id: props.element.id,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(DroppableItemInner)
);

export default Hierarchy;
