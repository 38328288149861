import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";

const JustifyContent = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const items = [
    [
      {
        key: "flex-start",
        label: "Left",
        subtext: "",
        decorClass: "jLeft",
      },
      {
        key: "flex-end",
        label: "Right",
        subtext: "",
        decorClass: "jRight",
      },
      {
        key: "space-between",
        label: "Stretch",
        subtext: "",
        decorClass: "jSpaceBetween",
      },
      // {
      //   key: "space-evenly",
      //   label: "Even",
      //   subtext: "",
      //   decorClass: "jSpaceEvenly",
      // },
      {
        key: "center",
        label: "Center",
        subtext: "",
        decorClass: "jCenter",
      },
    ],
  ];

  return (
    <div className="optionItemBody revealOffset">
      {items.map((items, i) => (
        <div className="optionItemRow foiUnit aCenter" key={i}>
          <div className="optionItemListLabelContainer">
            Horizontal
            <img
              className="oillIco"
              src={require("../../../../../Assets/img/options/container/widthIco.png")}
            ></img>
          </div>
          {items.map((item) => {
            const active = item.key === styles.justifyContent;
            return (
              <div className="optionItemBox" key={item.key}>
                <div
                  className={
                    "advancedRadioItem flexOptionsItem" +
                    (active ? " lightActive" : "")
                  }
                  onClick={() =>
                    mergeStyle({
                      justifyContent: item.key,
                      display: styles.display || "flex",
                    })
                  }
                >
                  <div className={"horizontalOptions " + item.decorClass}>
                    <img
                      className="horizontalArrowIco one"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>

                    <div className="horizontal one"></div>

                    <img
                      className="horizontalArrowIco two"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>

                    <div className="horizontal two"></div>

                    <img
                      className="horizontalArrowIco three"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>

                    <div className="horizontal three"></div>

                    <img
                      className="horizontalArrowIco four"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>
                  </div>
                  <div className="advancedMultiLabel">
                    {item.label} <span>{item.subtext}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default JustifyContent;
