import React from "react";
import { OptionFourInputs } from "../../../../../Components/etc/OptionItem";

const Margin = (props) => {
  return (
    <>
      <div className="spacingSubline mtm6">Margin (outside)</div>
      <div className="optionItemBody pt0">
        <OptionFourInputs
          {...{
            ...props,
            options: [
              { key: "marginTop", iconClass: "" },
              { key: "marginBottom", iconClass: "rotate180" },
              { key: "marginLeft", iconClass: "rotate270" },
              { key: "marginRight", iconClass: "rotate90" },
            ],
          }}
        />
      </div>
    </>
  );
};

export default Margin;
