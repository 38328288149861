import React from "react";
import update from "immutability-helper";
import MarkLocation from "./MarkLocation";
import MapCenter from "./MapCenter";
import MapIcon from "./MapIcon";
import DatabaseDataSelector from "../../../../Common/DatabaseOperations/DatabaseDataSelector";
import ColorButton from "../../../../../Components/Input/ColorButton";
import { RadioBoxHorizontal } from "../../../../../Components/etc/RadioBox";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ImageData from "../image/ImageData";
import FixedMark from "./FixedMark";
import { RepeatingDatabaseSelector } from "../../../../Common/DatabaseOperations_v2/DatabaseDataSelector";
import { LinkingBoxesPopup } from "../../../../Common/Linking/LinkingBox";

const CreateRoute = (props) => {
  const { value, onChange } = props;
  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  return (
    <>
      {operation.typeOfData === "repeating" ? (
        <RepeatingRoute {...props} value={value} onChange={onChange} />
      ) : (
        <FixedRoute {...props} value={value} onChange={onChange} />
      )}
    </>
  );
};

const RepeatingRoute = (props) => {
  const { value, onChange } = props;

  const operationObj = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operationObj, { $merge: obj }));
  };
  const setTableObj = (obj) => {
    setOperationObj({
      tableObj: update(operationObj.tableObj || {}, { $merge: obj }),
    });
  };

  return (
    <>
      <div style={{ padding: "0px 16px 16px 16px" }}>
        <div className="calcItemUnit">
          <RepeatingDatabaseSelector
            {...{
              ...props,
              value: operationObj.tableObj,
              onChange: (x) => setTableObj(x),

              valueTypeData: { tableSelector: { maxNoOfInputs: 0 } },
              immediateProps: {
                externalApi: false,
                webrtcStreams: false,
                databaseSortingQuery: true,
                pagination: false,
              },
            }}
          />

          <div className="calcItemUnitFooter">
            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Address</div>

              <ColumnSelector
                {...{
                  ...props,
                  value: operationObj?.tableObj?.columns?.[0],
                  tableObj: operationObj?.tableObj,
                  onChange: (x) =>
                    setOperationObj({
                      tableObj: {
                        ...(operationObj?.tableObj || {}),
                        columns: update(operationObj?.tableObj?.columns || [], {
                          $merge: { [0]: x },
                        }),
                      },
                    }),
                }}
              />
            </div>

            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Map Line Color</div>

              <div className="optionColorItem">
                <ColorButton
                  className="optionColorCircle"
                  value={operationObj.polylineStrokeColor}
                  onChange={(val) =>
                    setOperationObj({ polylineStrokeColor: val })
                  }
                />

                <div className="optionInputIconBoxText">Map Line Color</div>
              </div>
            </div>

            <div className="calcItemUnitMain">
              <div className="optionDatabaseBoxLabelMultiUnit">
                <div className="calcItemUnitMainLabel">Map Icon</div>
                <ToggleButton
                  classNames={{
                    container: "optionHeadTabs headTabsDark",
                    item: "optionHeadTabItem",
                  }}
                  overideDefaultStyle
                  value={operationObj.icon?.valueType || "database"}
                  onChange={(x) =>
                    setOperationObj({
                      icon: {
                        ...(operationObj.icon || {}),
                        valueType: x,
                      },
                    })
                  }
                  options={[
                    { value: "database", label: "Database" },
                    { value: "textParts", label: "Custom" },
                  ]}
                />
              </div>

              <IconData
                {...props}
                operationObj={operationObj}
                setOperationObj={setOperationObj}
                value={operationObj.icon}
                onChange={(x) => {
                  setOperationObj({
                    icon: {
                      ...operationObj.icon,
                      ...x,
                    },
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FixedRoute = (props) => {
  const { value, onChange } = props;

  const operationObj = value || {};
  const setOperationObj = (obj) => {
    console.log({ obj });
    onChange(update(operationObj, { $merge: obj }));
  };

  const locationMarks = [
    (operationObj.locationMarks && operationObj.locationMarks[0]) || {
      uid: Math.random(),
    },
    (operationObj.locationMarks && operationObj.locationMarks[1]) || {
      uid: Math.random(),
    },
  ];

  const setLocationMarks = (obj) =>
    setOperationObj({
      locationMarks: update(locationMarks, { $merge: obj }),
    });

  const setMark = (obj) =>
    setLocationMarks(update(locationMarks, { $merge: obj }));

  return (
    <div className="optionMapDatabaseWrapper">
      {locationMarks.map((location, markerNo) => {
        const value = location || {};
        const onChange = (value) => setMark({ [markerNo]: value });
        const remove = null;

        return (
          <FixedMark
            {...{
              ...props,
              value,
              onChange,
              markerNo,
              remove,
              key: value.uid,
            }}
          />
        );
      })}

      <div className="linkingBoxFooter">
        <div className="optionItemBody">
          <div className="optionItemRow">
            <div className="optionItemBox">
              <div className="optionColorItem">
                <ColorButton
                  className="optionColorCircle"
                  value={operationObj.polylineStrokeColor}
                  onChange={(val) =>
                    setOperationObj({ polylineStrokeColor: val })
                  }
                />
                <div className="optionInputIconBoxText">Map Line Color</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ColumnSelector = (props) => {
  const { value, onChange, tableObj = {} } = props;

  const database = props.databases?.find((x) => x._id === tableObj.dbId);
  const table = database?.tables?.find((x) => x._id === tableObj.tableId);
  const databaseColumns = table?.columns || [];

  return (
    <CustomSelect
      value={value || ""}
      onChange={(option) => onChange(option.value)}
      options={databaseColumns.map((x) => ({
        value: x.name,
        label: x.name,
      }))}
      placeholder={"Select Column"}
      classNames={{
        head: "calcItemUnitMainDropdown",
        label: "calcItemUnitMainDropdownLabel",
      }}
      jointActionRow={
        <div className="calcItemUnitMainDropdownFooter">
          <div className="calcItemUnitMainDropdownFooterArrow">
            <div className="one"></div>
            <div className="two"></div>
          </div>
        </div>
      }
    />
  );
};

class IconData extends ImageData {
  databaseColumnConfig() {
    return (
      <ColumnSelector
        {...{
          ...this.props,
          value: this.props.operationObj?.tableObj?.columns?.[1],
          tableObj: this.props.operationObj?.tableObj,
          onChange: (x) =>
            this.props.setOperationObj({
              icon: {
                ...(this.props.operationObj?.icon || {}),
                valueType: "database",
              },
              tableObj: {
                ...(this.props.operationObj?.tableObj || {}),
                columns: update(
                  this.props.operationObj?.tableObj?.columns || [],
                  {
                    $merge: { [1]: x },
                  }
                ),
              },
            }),
        }}
      />
    );
  }

  render() {
    return this.props.value?.valueType === "textParts"
      ? this.textPartsConfig()
      : this.databaseColumnConfig();
  }
}

export default CreateRoute;
