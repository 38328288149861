import React, { useEffect } from "react";

import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import { getSocket } from "../../Providers/socket";

const MainScreen = ({ isLoggedIn, children, user, team }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  if (!isLoggedIn || !user || !team || !getSocket())
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="loader large"></div>
      </div>
    );

  return (
    <div className="mainwrapper">
      <div className="gaouter">{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  socket: state.vState.APP?.socket,
  user: state.pState.AUTH?.user,
  team: state.pState.AUTH?.team,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
