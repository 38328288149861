import React, { useEffect, useState } from "react";
import { OptionItemBody } from "../../../Components/etc/OptionItem";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import ValueOfElementSelector from "../DataQueryOperations/ValueOfElementSelector";
import LinkingBox, { LinkingBoxes, LinkingBoxesPopup } from "./LinkingBox";
import _dom from "../../../appxolo-engine/modules/dom/Dom";
import _pp from "../../../appxolo-engine/modules/passedParameter/passedParemeter";
import CustomSelect from "../../../Components/etc/CustomSelect";
import DatabaseDataSelector from "../DatabaseOperations/DatabaseDataSelector";
import DatabaseQueryFilter from "../DatabaseOperations_v2/DatabaseQueryFilter";
import DataSelectorFooter from "../DatabaseOperations_v2/DataSelectorFooter";
import { getDatabaseSelectorOptions } from "../DatabaseOperations_v2/DatabaseSelector";
const { DomTree, DomTreeNode } = _dom;
const { getContainerPassedParameters } = _pp;

const loopTypes = [
  { value: "repeatingContainer", label: "Repeating container" },
  { value: "databaseData", label: "Database" },
  { value: "staticValues", label: "Static Values" },
  { value: "noOfLoop", label: "Number of repetition" },
];

const RepeatingAction = (props) => {
  const { value = {}, onChange, linkBoxOptions, triggerOption } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const [passedParameterObj, setPassedParameterObj] = useState({});

  useEffect(() => {
    if (!props.value?.loopType) mergeChange({ loopType: loopTypes[0].value });
  }, []);

  useEffect(() => {
    let passedParameters;
    if (props.value?.loopType === "databaseData") {
      passedParameters = getDatabaseDataPassedParameters(props);
    } else if (props.value?.loopType === "repeatingContainer") {
      passedParameters = getRepeatingContainerPassedParameters(props);
    } else if (props.value?.loopType === "staticValues") {
      passedParameters = getStaticValuesPassedParameters(props);
    } else if (props.value?.loopType === "noOfLoop") {
      passedParameters = getNoOfLoopPassedParameters(props);
    }

    if (passedParameters)
      setPassedParameterObj({
        ...passedParameterObj,
        [props.value?.loopType]: passedParameters,
      });
  }, [
    props.value?.loopType,
    props.value?.databaseData,
    props.value?.repeatingContainer?.id,
    props.value?.staticValues,
    props.value?.noOfLoop,
  ]);

  const getRepeatingContainerPassedParameters = (props) => {
    const elementIds = props.value?.repeatingContainer?.id?.split("-");
    if (!elementIds?.length) return;

    const lastElemntId = elementIds.pop();
    console.log({ elementIds, lastElemntId });

    let dom = props.dom;
    for (let i = 0; i < elementIds.length; i++) {
      const elementId = elementIds[i];
      const { node } = dom.findNodeAndParent(elementId);

      const tabs =
        node.value?.data?.dataType === "repeating"
          ? node.value?.data?.tabs || []
          : [node.value?.data?.tabs?.[0]].filter((x) => x);

      let activeTab = tabs?.[node.value?.data?.activeTabIndex || 0];

      let tab;
      if (tab?.containerType?.type === "screen") tab = activeTab;

      if (!tab)
        tab = tabs?.find((tab) => tab?.containerType?.type === "screen");

      if (!tab) return;

      const component = props.components?.find(
        (x) => x._id === tab?.containerType?.screenConfig?.to
      );

      if (!component?.data?.dom?.children?.length) {
        return;
      }

      const domTreeNode = new DomTreeNode(
        "ROOT",
        { elementType: "container" },
        component?.data?.dom?.children
      );
      dom = new DomTree(domTreeNode);

      console.log({ node, tabs, domTreeNode });
    }

    const indices = dom.findRecursiveIndices(lastElemntId);
    const parents = dom.findNodeAndParentsByIndices(indices);
    const element = parents?.[parents?.length - 1];

    console.log({ element, parents });
    if (!element) return null;

    const passedParameters = getContainerPassedParameters(parents, {
      databases: props.databases,
    });

    return passedParameters;
  };

  const getDatabaseDataPassedParameters = (props) => {
    const data = props.value?.databaseData || {};

    const table = props.databases
      ?.find((x) => x._id === data.dbId)
      ?.tables?.find((x) => x._id === data.tableId);

    let passedParameter = {
      uid: Math.random(),
      label: `Database table: ${table?.name}`,
      groupId: data.dbId + "-" + data.tableId,

      sourceType: "dataGroup",
    };

    const options = table?.columns?.map((column) => ({
      ...passedParameter,
      optionValue: column.name,
      optionLabel: `${table?.name}.${column.name}`,
    }));

    passedParameter.options = options;

    console.log({ passedParameter });
    return [...(props.passedParameters || []), passedParameter];
  };

  const getStaticValuesPassedParameters = (props) => {
    let passedParameter = {
      uid: Math.random(),
      label: `Loop Data`,
      groupId: "staticValueLoopData",
      sourceType: "dataGroup",
    };

    const options = [
      {
        ...passedParameter,
        optionValue: "staticValue.index",
        optionLabel: `loop.index`,
      },
      {
        ...passedParameter,
        optionValue: "staticValue.value",
        optionLabel: `loop.value`,
      },
    ];

    passedParameter.options = options;

    return [...(props.passedParameters || []), passedParameter];
  };

  const getNoOfLoopPassedParameters = (props) => {
    let passedParameter = {
      uid: Math.random(),
      label: `Loop Data`,
      groupId: "noOfLoopLoopData",
      sourceType: "dataGroup",
    };

    const options = [
      {
        ...passedParameter,
        optionValue: "noOfLoop.index",
        optionLabel: `loop.index`,
      },
    ];

    passedParameter.options = options;

    return [...(props.passedParameters || []), passedParameter];
  };

  const elementFilter = (x) => {
    const tabs =
      x.value?.data?.dataType === "repeating"
        ? x.value?.data?.tabs || []
        : [x.value?.data?.tabs?.[0]].filter((x) => x);

    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];

      if (
        tab?.containerData?.repeating ||
        tab?.containerType?.type === "screen"
      ) {
        return true;
      }
    }
    return false;
  };

  const propsToPass = {
    ...props,
    immediateProps: null,
  };

  return (
    <>
      <div className="loopHead">
        <div className="removeX" onClick={props.delete}>
          <div className="removeXLine one"></div>
          <div className="removeXLine two"></div>
        </div>
        <div className="loopDecor"></div>
        <div className="loopContext">
          <div className="optionItemBody">
            <div className="linkLabel">Loop type</div>

            <CustomSelect
              onChange={(option) => mergeChange({ loopType: option.value })}
              value={value?.loopType || ""}
              options={loopTypes}
              placeholder={"Select loop type"}
              className={"linkingToBox flexColumn flex1 br10 pad6"}
              classNames={{
                head: "pad6 flexRow aCenter",
                label: "cDark flex1 cPurple",
                chevron: "optionDatabaseSelectChevron",
              }}
            />

            <div className="linkLabel mt10">
              {loopTypes.find((x) => x.value === value.loopType)?.label}
            </div>

            {value.loopType === "repeatingContainer" ? (
              <div className="databaseOperationModule loopDatabase">
                <div className="domHeader">
                  <ValueOfElementSelector
                    {...{
                      ...propsToPass,
                      immediateProps: {
                        classNames: {
                          head: "calcItemUnitMainDropdown",
                          label: "calcItemUnitMainDropdownLabel",
                          chevron: "optionDatabaseSelectChevron",
                        },
                      },
                      elementType: "container",
                      styles: { dd: { width: "100%" } },
                      value: props.value?.repeatingContainer,
                      onChange: (x) =>
                        props.onChange({
                          ...(props.value || {}),
                          repeatingContainer: x,
                        }),
                      placeholder: "Select Repeating Container",
                      elementFilter,
                    }}
                  />
                </div>
              </div>
            ) : value.loopType === "databaseData" ? (
              <LoopDatabaseSelector
                {...{
                  ...propsToPass,
                  value: value?.databaseData || {},
                  onChange: (x) => mergeChange({ databaseData: x }),
                }}
              />
            ) : value.loopType === "staticValues" ? (
              <div className={"optionItemBody "}>
                <div className="optionItemRow">
                  <div className="optionConditionTableSelectorFilterItemContext">
                    <div className="optionConditionTableSelectorFilterItemHead">
                      <div className="optionConditionTableFilterMetaRequirementLabel">
                        Values
                      </div>
                    </div>
                    <RichTextData
                      {...{
                        ...propsToPass,
                        placeholder: "Comma separated values",
                        value: value.staticValues?.valueObj,
                        onChange: (valueObj) => {
                          mergeChange({
                            staticValues: {
                              valueObj,
                              valueType: "textParts",
                            },
                          });
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : value.loopType === "noOfLoop" ? (
              <div className={"optionItemBody "}>
                <div className="optionItemRow">
                  <div className="optionConditionTableSelectorFilterItemContext">
                    <div className="optionConditionTableSelectorFilterItemHead">
                      <div className="optionConditionTableFilterMetaRequirementLabel">
                        Repetition Count
                      </div>
                    </div>
                    <RichTextData
                      {...{
                        ...propsToPass,
                        placeholder: "Integer Value",
                        value: value.noOfLoop?.valueObj,
                        onChange: (valueObj) => {
                          mergeChange({
                            noOfLoop: {
                              valueObj,
                              valueType: "textParts",
                            },
                          });
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="loopSeperator"></div>

      <LinkingBox
        {...{
          ...props,
          key: value.loopType,
          passedParameters: passedParameterObj[value.loopType],
          value: props.value?.linking || {},
          onChange: (linking) =>
            props.onChange({ ...(props.value || {}), linking }),
          delete: null,

          linkBoxOptions: {
            conditionalDataType: false,
            showTriggerOption: false,
            mode: "loopLinking",
            hideLinkDropdown: true,
          },
        }}
      />

      {linkBoxOptions.showTriggerOption ? (
        <div className="linkingBoxFooter">{triggerOption}</div>
      ) : null}
    </>
  );

  return (
    <>
      <OptionItemBody label="Loop Type">
        <div className="optionConditionTableSelectorFilterItemContext">
          <CustomSelect
            onChange={(option) => mergeChange({ loopType: option.value })}
            value={value?.loopType || ""}
            options={loopTypes}
            placeholder={"Select loop type"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>
      </OptionItemBody>

      <LinkingBox
        {...{
          ...props,
          key: value.loopType,
          passedParameters: passedParameterObj[value.loopType],
          value: props.value?.linking || {},
          onChange: (linking) =>
            props.onChange({ ...(props.value || {}), linking }),
          delete: null,
          linkBoxOptions: {
            conditionalDataType: false,
            showTriggerOption: false,
            mode: "selectorOnly",
          },
        }}
      />

      <div>
        <div>On Completion</div>

        <LinkingBoxes
          {...{
            ...props,
            value: value.onCompletion?.linkings,
            onChange: (linkings) => mergeChange({ onCompletion: { linkings } }),
            autoAddOneEmptyLinking: false,
            linkBoxOptions: {
              conditionalDataType: false,
              showTriggerOption: false,
            },
          }}
        />
      </div>
    </>
  );
};

class LoopDatabaseSelector extends React.Component {
  render() {
    const { value, onChange } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    return [
      <div className="databaseOperationModule loopDatabase">
        <div className="domHeader">
          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown",
              label: "calcItemUnitMainDropdownLabel",
            }}
            labelIcon={
              <img
                className="calcItemUnitMainDropdownIco"
                src={require("../../../Assets/img/options/options/database.png")}
              ></img>
            }
            placeholder={"Select"}
            {...getDatabaseSelectorOptions({
              ...this.props,
              onChange: (obj) => mergeChange(obj),
            })}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />
        </div>

        <div className="domBody">
          <DatabaseQueryFilter
            {...this.props}
            dbId={value?.dbId}
            tableId={value?.tableId}
            value={value}
            onChange={(obj) => mergeChange(obj)}
            valueTypeData={null}
          />
        </div>
      </div>,
    ];
  }
}

export default RepeatingAction;
