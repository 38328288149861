import React, { useState } from "react";
import _ from "lodash";

import ControlledInput from "../../../../../Components/Input/ControlledInput";
import ColorButton from "../../../../../Components/Input/ColorButton";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";
import ToggleButton from "../../../../../Components/Input/ToggleButton";

const borderStyleKeys = {
  borderTop: {
    display: "Top",
    width: "borderTopWidth",
    color: "borderTopColor",
    style: "borderTopStyle",
    iconClass: "",
  },
  borderBottom: {
    display: "Bottom",
    width: "borderBottomWidth",
    color: "borderBottomColor",
    style: "borderBottomStyle",
    iconClass: "rotate180",
  },
  borderLeft: {
    display: "Left",
    width: "borderLeftWidth",
    color: "borderLeftColor",
    style: "borderLeftStyle",
    iconClass: "rotate270",
  },
  borderRight: {
    display: "Right",
    width: "borderRightWidth",
    color: "borderRightColor",
    style: "borderRightStyle",
    iconClass: "rotate90",
  },
};

const Border = (props) => {
  const [customized, setCustomized] = useState(false);

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs headTabsDark",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={customized}
      onChange={(x) => setCustomized(x)}
      options={[
        { value: false, label: "All" },
        { value: true, label: "Customize" },
      ]}
    />
  );

  return (
    <OptionItem optionHeadTabs={optionHeadTabs} optionItemLabel="Border">
      <div className="optionItemBody revealOffset">
        {customized ? (
          Object.keys(borderStyleKeys).map((key) => {
            return (
              <BorderRow
                {...{
                  ...props,
                  borderKeys: [key],
                  key: key,
                  icon: (
                    <img
                      className={
                        "optionInputIconBoxArrowIco " +
                        borderStyleKeys[key].iconClass
                      }
                      src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                      alt="arrow_icon"
                    ></img>
                  ),
                }}
              />
            );
          })
        ) : (
          <BorderRow
            {...{
              ...props,
              borderKeys: Object.keys(borderStyleKeys),
              icon: (
                <div style={{ position: "relative", marginTop: '-7px', width: '23px' }}>
                  <img
                    className={"optionInputIconBoxArrowIco "}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                    alt="arrow_icon"
                  ></img>
                  <img
                    className={
                      "optionInputIconBoxArrowIco rotate90 position-absolute"
                    }
                    style={{left: '4px', top: '8px'}}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                    alt="arrow_icon"
                  ></img>
                  <img
                    className={
                      "optionInputIconBoxArrowIco rotate180 position-absolute"
                    }
                    style={{left: 0, top: '12px'}}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                    alt="arrow_icon"
                  ></img>
                  <img
                    className={
                      "optionInputIconBoxArrowIco rotate270 position-absolute"
                    }
                    style={{left: '-4px', top: '8px'}}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                    alt="arrow_icon"
                  ></img>
                </div>
              ),
              key: "all",
            }}
          />
        )}
      </div>
    </OptionItem>
  );
};

const BorderRow = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const borderKeys = props.borderKeys;
  const border = getBorderStyles(styles, borderKeys);

  const updateStyle = (value, styleColumn) => {
    let updatedStyles = { ...styles };

    for (let i = 0; i < borderKeys.length; i++) {
      const borderKey = borderKeys[i];
      updatedStyles[borderStyleKeys[borderKey][styleColumn]] = value;
    }
    mergeStyle(updatedStyles);
  };

  return (
    <div className="optionItemRow">
      <div className="optionItemBox fInitial">
        <ColorButton
          className="borderColorPicker"
          style={{ width: "100%", height: "100%" }}
          value={border.color || "#000000"}
          onChange={(val) => updateStyle(val, "color")}
        />
      </div>
      <div className="optionItemBox">
        <div className="optionInputIconBox">
          {props.icon}
          <ControlledInput
            className="optionInputIconBoxSpacingField"
            onChange={(event) => updateStyle(event.target.value, "width")}
            value={border.width || ""}
          />
        </div>
      </div>

      <div className="optionItemBox">
        <CustomSelect
          onChange={(option) => updateStyle(option.value, "style")}
          value={border.style || ""}
          options={["solid", "dotted", "dashed"].map((x) => ({
            value: x,
            label: _.startCase(x),
          }))}
          placeholder={"Select"}
          classNames={{
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          }}
        />
      </div>
    </div>
  );
};

const getBorderStyles = (styles, borderKeys) => {
  let border = {};

  const styleColumns = ["width", "color", "style"];

  for (let i = 0; i < styleColumns.length; i++) {
    const styleColumn = styleColumns[i];

    let isSame = true;

    for (let i = 0; i < borderKeys.length && isSame; i++) {
      const borderKey = borderKeys[i];
      const borderStyleKey = borderStyleKeys[borderKey][styleColumn];
      let value = styles[borderStyleKey];

      if (i && value !== border[styleColumn]) {
        isSame = false;
        border[styleColumn] = "";
      } else {
        border[styleColumn] = value;
      }
    }
  }

  return border;
};

export default Border;
