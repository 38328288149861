import React from "react";

import PropertyClass from "../../Classes/PropertyClass";
import ContainerType, {
  ContainerTypeScreen,
} from "./ContainerType/ContainerType";
import PositionStyle from "../../Common/StyleProperties/PositionStyle";
import etcModule from "../../../../../Modules/etc/etc-module";
import Alignment from "../../Common/StyleProperties/Alignment";
import Border from "../../Common/StyleProperties/Border";
import BorderRadius from "../../Common/StyleProperties/BorderRadius";
import Opacity from "../../Common/StyleProperties/Opacity";
import Background from "../../Common/StyleProperties/Background";
import BoxShadow from "../../Common/StyleProperties/BoxShadow";
import ContainerData from "./ContainerData";
import ContainerAppearance from "../../Common/StyleProperties/ContainerAppearance";
import Spacing from "../../Common/StyleProperties/Spacing";
import ContainerOptions from "../../Common/StyleProperties/ContainerOptions";

class ContainerProperties extends PropertyClass {
  render() {
    const {
      props: { value = {}, onChange },
    } = this;
    const mergeChange = (obj) => onChange({ ...value, ...obj });
    const mergeStyleData = (styleData) => mergeChange({ styleData });

    const styleProps = {
      ...this.props,
      hover: {
        value: value.styleData?.hover?.default || {},
        onChange: (obj) => {
          const updatedStyleData = etcModule.mergeObjects(
            value.styleData,
            obj,
            ["hover", "default"]
          );
          mergeStyleData(updatedStyleData);

          this.processStyleWithThrottle(
            updatedStyleData.hover?.default,
            (styleData) =>
              mergeStyleData({ ...updatedStyleData, hover: styleData })
          );
        },
      },
      value: value.styleData?.container?.default || {},
      onChange: (obj) => {
        const updatedStyleData = etcModule.mergeObjects(value.styleData, obj, [
          "container",
          "default",
        ]);
        mergeStyleData(updatedStyleData);

        this.processStyleWithThrottle(
          updatedStyleData.container?.default,
          (styleData) =>
            mergeStyleData({ ...updatedStyleData, container: styleData })
        );
      },
    };

    return (
      <>
        <ContainerAppearance {...styleProps} />
        {["emailTemplate"].includes(this.props.availableFor) ? null : (
          <ContainerType
            {...this.props}
            value={value.containerType}
            onChange={(x) => mergeChange({ containerType: x })}
          />
        )}
        
        {/* <ContainerTypeScreen
          {...this.props}
          value={value.containerType}
          onChange={(x) => mergeChange({ containerType: x })}
        /> */}
        <Spacing {...styleProps} />

        <BorderRadius {...styleProps} />
        <Border {...styleProps} />
        <BoxShadow {...styleProps} />

        <ContainerOptions
          {...{
            ...styleProps,
            safeAreaViewConfig: value.safeAreaViewConfig || {},
            setSafeAreaViewConfig: (x) =>
              mergeChange({ safeAreaViewConfig: x }),
          }}
        />

        
        <PositionStyle {...styleProps} />
        
        
        
        {/* <Opacity {...styleProps} /> */}

        <ContainerData
          {...this.props}
          onChange={mergeChange}
          linkBoxOptions={{
            conditionalDataType: true,
            showTriggerOption: true,
            styles: {
              container: {
                margin: "3px 0",
                padding: "5px",
                border: "solid 0.5px grey",
                borderRadius: "2px",
                backgroundColor: "#fff",
                color: "#333",
              },
            },
          }}
        />
      </>
    );
  }
}

export default ContainerProperties;
