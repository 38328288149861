import React, { useCallback } from "react";
import SliderConfig from "./SliderConfig";
import ContainerTabsConfig from "./ContainerTabsConfig";
import ScreenConfig from "./ScreenConfig";
import OptionItem from "../../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../../Components/Input/ToggleButton";

const ContainerType = (props) => {
  const { value = {}, onChange } = props;
  const renderItem = useCallback(
    (item) => {
      const active = item.value === (value.type || "container");
      return (
        <div className="optionItemBox" key={item.value}>
          <div
            className={"advancedRadioItem" + (active ? " lightActive" : "")}
            onClick={() => {
              if (!active) onChange({ ...value, type: item.value });
            }}
          >
            <div className="verticalOptions jStart">{item.decor}</div>
            <div className="advancedMultiLabel tCenter">{item.label}</div>
          </div>
        </div>
      );
    },
    [value]
  );

  return (
    <>
      <OptionItem className="" optionItemLabel="Type">
        <div className="optionItemBody pad5t revealOffset">
          <div className="optionItemRow">
            {[
              {
                value: "container",
                label: "Container",
                decor: (
                  <div className="containerDecor">
                    <div className="containerDecorPlusButton"></div>
                  </div>
                ),
              },
              {
                value: "slider",
                label: "Slider",
                decor: (
                  <div className="containerDecorPlusButtonSecond">
                    <div className="containerDecorPlusButtonLine one"></div>
                    <div className="containerDecorPlusButtonLine two"></div>
                  </div>
                ),
              },
              {
                value: "containerTabs",
                label: "Tabs",
                decor: (
                  <div className="containerDecorNew">
                    <div className="containerDecorPlusButtonTabs">
                      <div className="containerDecorInner"></div>
                      <div className="containerDecorInnerTwo"></div>
                    </div>
                  </div>
                ),
              },
            ].map((item) => {
              return renderItem(item);
            })}
          </div>
        </div>
      </OptionItem>

      {value.type === "slider" ? (
        <SliderConfig
          {...props}
          value={value.sliderConfig}
          onChange={(x) => onChange({ ...value, sliderConfig: x })}
        />
      ) : value.type === "containerTabs" ? (
        <ContainerTabsConfig
          {...props}
          value={value.containerTabsConfig}
          onChange={(x) => onChange({ ...value, containerTabsConfig: x })}
        />
      ) : null}
    </>
  );
};

export const ContainerTypeScreen = (props) => {
  const { value = {}, onChange } = props;

  const isContainerScreen = value.type === "screen";

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs headTabsDark",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={isContainerScreen ? "on" : "off"}
      onChange={(x) =>
        onChange({
          ...value,
          ...(x === "on"
            ? { type: "screen", prevType: value?.type || "container" }
            : { type: value.prevType || "container" }),
        })
      }
      options={[
        { value: "on", label: "On" },
        { value: "off", label: "Off" },
      ]}
    />
  );

  const content = (
    <div className="optionItemBody">
      <ScreenConfig
        {...props}
        value={value.screenConfig}
        onChange={(x) => onChange({ ...value, screenConfig: x })}
      />
    </div>
  );

  const full = (
    <OptionItem optionItemLabel="PAGE" optionHeadTabs={optionHeadTabs}>
      {content}
    </OptionItem>
  );

  return content;
};

export default ContainerType;
