import React from "react";
import RichTextData from "../../Builder/Properties/Common/DataProperties/RichTextData";
import CustomSelect from "../../../Components/etc/CustomSelect";
import KeyValuePairs from "./KeyValuePairs";
import { DatabaseSelector } from "../DatabaseOperations_v2/DatabaseDataSelector";

class ExternalApiDataSelector extends React.Component {
  tabs = [
    {
      value: "externalApiHeaders",
      label: "Headers",
      buttonLabel: "Add Header",
    },
    {
      value: "externalApiParams",
      label: "Params",
      buttonLabel: "Add param",
    },
    {
      value: "externalApiBody",
      label: "Body",
      buttonLabel: "Add body",
    },
  ];

  state = {
    selectedTab: this.tabs[0],
  };

  componentDidMount() {
    const { value, onChange, focusedElement } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    if (!value?.externalApiRequestId && focusedElement) {
      const id = `${focusedElement?.element?.id || ""}-${
        focusedElement?.element?.value?.data?.activeTabIndex || 0
      }-${this.generateRandomString(5)}`;

      mergeChange({ externalApiRequestId: id });
    }
  }

  generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

  render() {
    const { value = {}, onChange, mode = "repeatingContainer" } = this.props;
    const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

    const propsToPass = { ...this.props, mode: null };

    const isPrev = value.action === "prevApiRequest";

    const calcUnitmain = (
      <div className="calcItemUnitMain">
        {!["updateExternalApiReq"].includes(mode) ? (
          <div className="calcItemUnitMainCombo">
            <div className="calcItemUnitMainLabel">API Request</div>
            {!isPrev ? (
              <div className="calcItemUnitSublineLabel">
                ID:{" "}
                <span>
                  {value?.externalApiRequestId || ""}
                  {/* TODO make this editable by uncommenting below and adjust the UI */}
                  {/* <input
              {...{
                value: value?.externalApiRequestId || "",
                onChange: (e) =>
                  mergeChange({
                    externalApiRequestId: e.target.value,
                  }),
              }}
            /> */}
                </span>
              </div>
            ) : null}
          </div>
        ) : null}

        <CustomSelect
          classNames={{
            head: "calcItemUnitMainDropdown adjust",
            label: "calcItemUnitMainDropdownLabel",
          }}
          onChange={(option) => mergeChange({ action: option.value })}
          value={value?.action || "newApiRequest"}
          options={[
            { value: "newApiRequest", label: "New API Request" },
            { value: "prevApiRequest", label: "Previous API Request" },
          ]}
          jointActionRow={
            <div className="calcItemUnitMainDropdownFooter">
              <div className="calcItemUnitMainDropdownFooterArrow">
                <div className="one"></div>
                <div className="two"></div>
              </div>
            </div>
          }
        />

        {!isPrev ? (
          <div className="spaceTopify">
            <RichTextData
              {...{
                ...propsToPass,
                immediateProps: {
                  classNames: {
                    wrapper: " ",
                    editor: "calcDatabaseFilterMultiBox",
                    paramListRow: "",
                  },
                },
                placeholder: "Endpoint URL",
                value: value?.externalApiUrl?.valueObj,
                onChange: (valueObj) =>
                  mergeChange({
                    externalApiUrl: { valueType: "textParts", valueObj },
                  }),
              }}
            />
          </div>
        ) : null}

        {!isPrev ? (
          <CustomSelect
            classNames={{
              head: "calcItemUnitMainDropdown adjust spaceTopify",
              label: "calcItemUnitMainDropdownLabel",
            }}
            onChange={(option) =>
              mergeChange({ externalApiMethod: option.value })
            }
            value={value?.externalApiMethod || "GET"}
            options={["GET", "POST", "PUT", "DELETE"].map((x) => ({
              value: x,
              label: x,
            }))}
            jointActionRow={
              <div className="calcItemUnitMainDropdownFooter">
                <div className="calcItemUnitMainDropdownFooterArrow">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            }
          />
        ) : null}
      </div>
    );

    return (
      <div className="calcItemUnit">
        {mode === "repeatingContainer" ? (
          <div className="calcItemUnitBox">
            <div className="calcItemUnitDecor"></div>
            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Database Table</div>

              <DatabaseSelector
                {...this.props}
                externalApi={true}
                webrtcStreams={true}
              />
              {calcUnitmain}
            </div>
          </div>
        ) : (
          <div className="calcItemUnitBox">
            <div className="calcItemUnitDecor"></div>

            {calcUnitmain}
          </div>
        )}

        {!isPrev ? (
          <div className="calcItemUnitBox">
            <div className="calcItemDatabaseFilter">
              <div className="calcItemHeadTab apiTabItem spaceTopify">
                {this.tabs.map((item) => (
                  <div
                    key={item.value}
                    className={
                      "calcItemHeadTabItem" +
                      (this.state.selectedTab?.value === item.value
                        ? " active"
                        : "")
                    }
                    onClick={() => this.setState({ selectedTab: item })}
                  >
                    {item.label}
                  </div>
                ))}
              </div>

              <div className="calcItemDatabaseFilterLabel">
                {value?.[this.state.selectedTab?.value]?.length || 0} Header
                {value?.[this.state.selectedTab?.value]?.length > 1 ? "s" : ""}
              </div>

              <KeyValuePairs
                {...{
                  ...propsToPass,
                  value: value?.[this.state.selectedTab?.value],
                  onChange: (x) =>
                    mergeChange({ [this.state.selectedTab?.value]: x }),
                  addButtonLevel: this.state.selectedTab.buttonLabel,
                }}
              />
            </div>
          </div>
        ) : null}

        {isPrev ? (
          <div className="calcItemUnitBox">
            <div className="calcItemDatabaseFilter">
              <div className="calcItemDatabaseFilterBox">
                <div className="calcItemDatabaseFilterBoxInner">
                  <div className="calcItemDatabaseFilterBoxInnerRow">
                    <div className="calcItemDBLabel">Request ID</div>
                  </div>
                  <RichTextData
                    {...{
                      ...propsToPass,
                      immediateProps: {
                        classNames: {
                          wrapper: " ",
                          editor: "calcDatabaseFilterMultiBox",
                          paramListRow: "",
                        },
                      },
                      placeholder: "Type here",
                      value: value?.sourceRequestId?.valueObj,
                      onChange: (valueObj) =>
                        mergeChange({
                          sourceRequestId: { valueType: "textParts", valueObj },
                        }),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="calcItemUnitFooter">
          {[
            "repeatingContainer",
            "valueFromApi",
            "externalApiInfinitePagination",
          ].includes(mode) ? (
            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Path to Result</div>
              <div className="calcItemUnitMainDropdown">
                <input
                  className="calcItemUnitMainDropdownLabel"
                  placeholder="data.Email"
                  onChange={(e) =>
                    mergeChange({ externalApiPathToResult: e.target.value })
                  }
                  value={value?.externalApiPathToResult || ""}
                />
              </div>
            </div>
          ) : null}

          {!isPrev ? (
            <div className="calcItemUnitMain">
              <div className="calcItemUnitMainLabel">Content Type</div>

              <CustomSelect
                classNames={{
                  head: "calcItemUnitMainDropdown",
                  label: "calcItemUnitMainDropdownLabel",
                }}
                onChange={(option) =>
                  mergeChange({ externalApiContentType: option.value })
                }
                value={value?.externalApiContentType || "json"}
                options={[
                  { value: "json", label: "JSON" },
                  { value: "formData", label: "Form Data" },
                  { value: "raw", label: "Row" },
                ]}
                jointActionRow={
                  <div className="calcItemUnitMainDropdownFooter">
                    <div className="calcItemUnitMainDropdownFooterArrow">
                      <div className="one"></div>
                      <div className="two"></div>
                    </div>
                  </div>
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    );

    return (
      <div>
        {mode === "repeatingContainer" ? (
          <div className="optionConditionTableSelector">
            <DatabaseSelector
              {...this.props}
              value={value}
              onChange={(obj) => mergeChange(obj)}
              externalApi={true}
              webrtcStreams={true}
            />
          </div>
        ) : null}

        {!["updateExternalApiReq"].includes(mode) ? (
          <div className="optionConditionTableSelectorHead">
            <div className="optionConditionTableSelectorHeadSubline">
              API request id
            </div>
          </div>
        ) : null}

        <div className="optionConditionTableSelectorHead">
          <div className="optionConditionTableSelectorHeadSubline">
            Endpoint
          </div>
          <RichTextData
            {...{
              ...this.props,
              value: value?.externalApiUrl?.valueObj,
              onChange: (valueObj) =>
                mergeChange({
                  externalApiUrl: { valueType: "textParts", valueObj },
                }),
            }}
          />
        </div>

        <div className="optionConditionTableSelectorHead">
          <div className="optionConditionTableSelectorHeadSubline">Method</div>
          <CustomSelect
            classNames={{
              head: "optionConditionTableSelectorHeadSelect",
              label: "optionConditionTableSelectorHeadSelectLabel",
              chevron: "optionConditionTableSelectorHeadSelectIco",
            }}
            onChange={(option) =>
              mergeChange({ externalApiMethod: option.value })
            }
            value={value?.externalApiMethod || "GET"}
            options={["GET", "POST", "PUT", "DELETE"].map((x) => ({
              value: x,
              label: x,
            }))}
          />
        </div>

        <div className="optionConditionTableSelectorHead">
          <div className="optionConditionTableSelectorBody">
            <div className="optionConditionTableSelectorHeadSublineFilter">
              Headers:
            </div>

            <KeyValuePairs
              {...{
                ...this.props,
                value: value?.externalApiHeaders,
                onChange: (x) => mergeChange({ externalApiHeaders: x }),
                addButtonLevel: value?.externalApiHeaders?.lenght
                  ? "Add More Headers"
                  : "Add Headers",
              }}
            />
          </div>
        </div>

        <div className="optionConditionTableSelectorHead">
          <div className="optionConditionTableSelectorBody">
            <div className="optionConditionTableSelectorHeadSublineFilter">
              Params:
            </div>

            <KeyValuePairs
              {...{
                ...this.props,
                value: value?.externalApiParams,
                onChange: (x) => mergeChange({ externalApiParams: x }),
                addButtonLevel: value?.externalApiParams?.lenght
                  ? "Add More Params"
                  : "Add Params",
              }}
            />
          </div>
        </div>

        {["POST", "PUT"].includes(value?.externalApiMethod) ? (
          <>
            <div className="optionConditionTableSelectorHead">
              <div className="optionConditionTableSelectorBody">
                <div className="optionConditionTableSelectorHeadSublineFilter">
                  Body:
                </div>

                <KeyValuePairs
                  {...{
                    ...this.props,
                    value: value?.externalApiBody,
                    onChange: (x) => mergeChange({ externalApiBody: x }),
                    addButtonLevel: value?.externalApiBody?.lenght
                      ? "Add More Data"
                      : "Add Data",
                  }}
                />
              </div>
            </div>
            <div className="optionConditionTableSelectorHead">
              <div className="optionConditionTableSelectorHeadSubline">
                Content Type
              </div>
              <CustomSelect
                classNames={{
                  head: "optionConditionTableSelectorHeadSelect",
                  label: "optionConditionTableSelectorHeadSelectLabel",
                  chevron: "optionConditionTableSelectorHeadSelectIco",
                }}
                onChange={(option) =>
                  mergeChange({ externalApiContentType: option.value })
                }
                value={value?.externalApiContentType || "json"}
                options={[
                  { value: "json", label: "JSON" },
                  { value: "formData", label: "Form Data" },
                  { value: "raw", label: "Row" },
                ]}
              />
            </div>
          </>
        ) : null}

        {[
          "repeatingContainer",
          "valueFromApi",
          "externalApiInfinitePagination",
        ].includes(mode) ? (
          <div className="optionConditionTableSelectorHead">
            <div className="optionConditionTableSelectorHeadSubline">
              Path To Result
            </div>
            <input
              onChange={(e) =>
                mergeChange({ externalApiPathToResult: e.target.value })
              }
              value={value?.externalApiPathToResult || ""}
            />
          </div>
        ) : null}

        {["repeatingContainer"].includes(mode) ? (
          <div className="optionConditionTableSelectorHead">
            <div className="optionConditionTableSelectorHeadSubline">
              Columns in API result
            </div>
            <input
              onChange={(e) =>
                mergeChange({ externalApiDataColumns: e.target.value })
              }
              value={value?.externalApiDataColumns || ""}
              placeholder="Comma Separated"
            />
          </div>
        ) : null}

        {/* <div><button>Test Connection</button></div> */}
      </div>
    );
  }
}

export default ExternalApiDataSelector;
