import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";
import CustomSelect from "../../../../../Components/etc/CustomSelect";

const ContainerOptions = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  return (
    <OptionItem optionItemLabel={"Options"}>
      {[
        {
          value: styles.scrollable || "",
          onChange: (x) =>
            mergeStyle({ scrollable: x === styles.scrollable ? "" : x }),
          label: "Scrolling",
          options: [
            { value: "vertical", label: "Vertical" },
            { value: "horizontal", label: "Horizontal" },
          ],
        },
        {
          value: styles.display || "flex",
          onChange: (x) => mergeStyle({ display: x }),
          label: "Visibility",
          options: [
            { value: "flex", label: "Show" },
            { value: "none", label: "Hide" },
          ],
        },
        {
          value: styles.flexWrap || "",
          onChange: (x) => mergeStyle({ flexWrap: x }),
          label: "Wrap",
          options: [
            { value: "", label: "Off" },
            { value: "wrap", label: "On" },
          ],
        },
        {
          value: props.safeAreaViewConfig?.enable || "",
          onChange: (x) =>
            props.setSafeAreaViewConfig({
              enable: x,
            }),
          label: "Safe Area",
          options: [
            { value: "", label: "Off" },
            { value: "enable", label: "On" },
          ],
        },
      ].map((item) => (
        <div key={item.label} className="optionItemBody revealOffset">
          <div className="optionItemRow">
            <div className="optionItemListLabel">
              {item.label} <div className="optionItemListLabelIco">i</div>
            </div>

            <OptionToggle
              value={item.value}
              onChange={(option) => item.onChange(option.value)}
              options={item.options}
            />
          </div>
        </div>
      ))}
    </OptionItem>
  );
};

const OptionToggle = ({ value = {}, onChange, options }) => {
  return (
    <>
      {options.map((item) => {
        return (
          <div className="optionItemBox" key={item.value}>
            <div
              className={
                "advancedRadioItem" +
                (value === item.value ? " lightActive" : "")
              }
              onClick={() => onChange(item)}
            >
              <div className="advancedRadioLabel">{item.label}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ContainerOptions;
