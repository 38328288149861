import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import ImageInput from "../../../Components/Input/ImageInput";
import api from "../../../Services/Api/api";
import config from "../../../Config";
import SearchInput from "../../../Components/Input/SearchInput";
import ProjectDetailsBase from "./ProjectDetailsBase";

class ProjectDetailsInfo extends ProjectDetailsBase {
  iosconfigform = React.createRef();

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setLoading("credentials", true);

      const credentialP = api.get("v1/project/credential", {
        filter: JSON.stringify({
          where: {
            project: this.props.project?._id,
            credentialType: { $in: ["iosDeployment", "androidDeployment"] },
          },
        }),
      });

      const latestSuccessTaskP = api.get("v1/project/buildanddeploy", {
        project: this.props.project?._id,
      });

      const { credentials } = await credentialP;
      const latestBuildInfo = await latestSuccessTaskP;

      const iosDeploymentConfig = credentials?.find(
        (x) => x.credentialType === "iosDeployment"
      )?.credential;
      const androidDeploymentConfig = credentials?.find(
        (x) => x.credentialType === "androidDeployment"
      )?.credential;

      const androidBuildTask = latestBuildInfo?.tasks?.android?.[0];
      const iosBuildTask = latestBuildInfo?.tasks?.ios?.[0];

      this.setLoading("credentials", false);
      this.setState({
        iosDeployment: _.omit(iosDeploymentConfig, ["appstoreConnectApiKey"]),
        androidDeployment: androidDeploymentConfig,

        savedIosDeployment: iosDeploymentConfig,
        savedAndroidDeployment: androidDeploymentConfig,
        latestBuildInfo,
        androidBuildTask,
        iosBuildTask,
      });
    } catch (e) {
      this.setLoading("credentials", false);
      console.warn(e);
      toast.error(e.message);
    }
  }

  getBuildStatusMessage = (task) => {
    if (!task) return "Unpublished";
    else if (task.status === "processing")
      return (
        <>
          Processing started at <span>{this.formatDate(task.updatedAt)}</span>
        </>
      );
    else if (task.status === "complete")
      return (
        <>
          {task?.data?.platform === "ios" ? "Published" : "Created"}
          {/* version: {task?.comment?.version}, */} build{" "}
          {task?.comment?.build} on{" "}
          <span>{this.formatDate(task?.comment?.ts)}</span>
        </>
      );
    else if (task.status === "fail")
      return (
        <>
          Build failed at <span>{this.formatDate(task.updatedAt)}</span>
        </>
      );
    else
      return (
        <>
          Build status {task.status}{" "}
          <span>{this.formatDate(task.updatedAt)}</span>
        </>
      );
  };

  async updateCredential(credentialType, credential) {
    const loadingKey = credentialType + "updateCredential";
    try {
      if (this.state.loadingObj[loadingKey]) return;

      this.setLoading(loadingKey, true);

      const payload = {
        project: this.props.project._id,
        credentialType,
        credential,
      };

      await api.post("v1/project/credential", payload);
      this.load();

      this.setLoading(loadingKey, false);
    } catch (error) {
      this.setLoading(loadingKey, false);
    }
  }

  updateLogo = async (file) => {
    try {
      if (this.state.loadingObj.image) return;

      this.setLoading("image", true);
      let image = null;
      if (file) {
        const fileRes = await api.media("v1/file", { file });
        image = fileRes?.file?._id;
      }

      await this.submitEdit({ image }).catch(async (e) => {
        if (image) {
          api.delete(`v1/file/${image}`).catch(console.error);
        }
        throw e;
      });
      this.setLoading("image", false);
    } catch (e) {
      this.setLoading("image", false);
      toast.error(e.message);
    }
  };

  handleSubdomain = async (subdomain) => {
    try {
      this.setState({ project: { ...this.state.project, subdomain } });

      this.setToggleables("subdomainCheck", null);
      const { available } = await api.get("v1/project/subdomain-check", {
        subdomain,
        projectId: this.state.project._id,
      });

      if (available) {
        this.setToggleables("subdomainCheck", subdomain);
      } else {
        this.setToggleables("subdomainCheck", false);
      }
    } catch (error) {
      this.setToggleables("subdomainCheck", null);
    }
  };

  getProductionDomainName() {
    const name = this.props.project?.productionDomains?.[0]?.name || "";

    let result;

    const nameParts = name.split(".");

    if (!name) {
      result = `${this.props.project?.subdomain || ""}.${config.frontDomain}`;
    } else if (nameParts.length > 2) {
      result = `https://${name}`;
    } else {
      result = `https://${name}, https://www.${name}`;
    }

    return result;
  }

  async checkConnection() {
    try {
      if (this.state.loadingObj.domainConnectionCheck) return;

      const domain = this.state.project?.productionDomains?.[0]?.name || "";

      this.setState({ domainConnectionCheck: null, connectionTestMessage: "" });
      this.setLoading("domainConnectionCheck", true);

      const { connected, message } = await api.get(
        "v1/project/domain-connection-check",
        {
          project: this.state.project._id,
          ip: config.frontProductionIp,
          domain,
        }
      );
      this.setState({
        domainConnectionCheck: connected,
        connectionTestMessage: message,
      });
      this.setLoading("domainConnectionCheck", false);

      if (
        connected &&
        domain === this.props.project?.productionDomains?.[0]?.name
      ) {
        this.submit(
          {
            productionDomains: this.state.project.productionDomains?.map(
              (x) => ({ ...x, name: x.name.replace(/^www\./, "") })
            ),
          },
          "productionDomains"
        );
      }
    } catch (e) {
      toast.error(e.message);
      this.setState({
        connectionTestMessage: `Connection test failed: ${e.message}`,
        domainConnectionCheck: false,
      });
      this.setLoading("domainConnectionCheck", false);
    }
  }

  async handleIosPublish() {
    try {
      if (this.state.loadingObj.iosPublish) return;
      this.setLoading("iosPublish", true);

      const result = await api.post("v1/project/buildanddeploy", {
        project: this.state.project._id,
        platform: "ios",
      });

      console.log({ result });

      this.setLoading("iosPublish", false);
      toast.success("Publish request is submitted successfully");
    } catch (error) {
      toast.error(error.message);
      this.setLoading("iosPublish", false);
    }
  }

  async handleAndroidPublish() {
    try {
      if (this.state.loadingObj.androidPublish) return;
      this.setLoading("androidPublish", true);

      const result = await api.post("v1/project/buildanddeploy", {
        project: this.state.project._id,
        platform: "android",
      });

      console.log({ result });

      this.setLoading("androidPublish", false);
      toast.success("Publish request is submitted successfully");
    } catch (error) {
      toast.error(error.message);
      this.setLoading("androidPublish", false);
    }
  }

  async handleTransferClick() {

  }

  render() {
    const {
      project,
      toggleables,
      loadingObj,
      connectionTestMessage = "",
      domainConnectionCheck = null,
      infoRowEdit = false,
    } = this.state;
    const { project: projectProps } = this.props;

    return (
      <>
        <div className="pageTitle">
          <div>Project Details</div>
          <span>View and manage your project</span>
        </div>

        <div className="contentBody">
          <div className="tLabel mb10">Information</div>

          {infoRowEdit ? (
            <div className="borderBox upmRowGroup mb20">
              <div className="appIconRow">
                <ImageInput
                  value={api.getFileLink(project.image)}
                  onChange={(image) => this.updateLogo(image)}
                  loading={loadingObj.image}
                />
                <div
                  className="defaultButton"
                  onClick={() =>
                    this.submit(
                      { name: project.name, description: project.description },
                      "infoRow"
                    )
                  }
                >
                  {loadingObj.infoRow ? "Saving" : "Save"}
                </div>
              </div>
              <div className="upmRow">
                <div className="upmRowLabel">App Name</div>
                <div className="upmRowValue">
                  <input
                    type="text"
                    placeholder="Enter name"
                    className="upmRowInput"
                    value={project.name || ""}
                    onChange={(e) =>
                      this.setState({
                        project: { ...project, name: e.target.value },
                      })
                    }
                  />
                </div>
              </div>

              <div className="upmRow rowTopify">
                <div className="upmRowLabel">Description</div>
                <div className="upmRowValue">
                  <textarea
                    type="text"
                    placeholder="Provide application description"
                    className="upmRowInput"
                    value={project.description || ""}
                    onChange={(e) =>
                      this.setState({
                        project: { ...project, description: e.target.value },
                      })
                    }
                  ></textarea>
                </div>
              </div>

              <div className="upmRow">
                <div className="upmRowLabel">Type</div>
                <div className="upmRowValue">
                  <div className="selectBox">
                    Web Application
                    <img
                      className="selectIco"
                      src={require("../../../Assets/img/user/lock1.png")}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="borderBox upmRowGroup mb20">
              <div className="appIconRow">
                <img
                  className="projectIco"
                  src={api.getFileLink(project.image)}
                />
                <div
                  className="defaultButton"
                  onClick={() => this.setState({ infoRowEdit: true })}
                >
                  Edit
                </div>
              </div>
              <div className="upmRow">
                <div className="upmRowLabel">App Name</div>
                <div className="upmRowValue">{project.name || ""}</div>
              </div>

              <div className="upmRow rowTopify">
                <div className="upmRowLabel">Description</div>
                <div className="upmRowValue">{project.description}</div>
              </div>

              <div className="upmRow">
                <div className="upmRowLabel">Type</div>
                <div className="upmRowValue">
                  <div className="selectBox">
                    {_.startCase(project.data?.platform)} Application
                    <img
                      className="selectIco"
                      src={require("../../../Assets/img/user/lock1.png")}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="tLabel mb10">Status</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">iOS App</div>
              <div className="publishRow">
                <div className="mValue">
                  {this.getBuildStatusMessage(this.state.iosBuildTask)}
                </div>

                {toggleables.iosPublish ? (
                  <div
                    className="defaultButton mtm15"
                    onClick={() => this.handleIosPublish()}
                  >
                    {this.state.loadingObj.iosPublish ? "Loading" : "Publish"}
                  </div>
                ) : (
                  <div
                    className="defaultButton mtm15"
                    onClick={() =>
                      this.setToggleables("iosPublish", !toggleables.iosPublish)
                    }
                  >
                    Details
                  </div>
                )}
              </div>

              <form
                ref={this.iosconfigform}
                style={
                  toggleables.iosPublish
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="publishDetails">
                  {[
                    {
                      value: "appid",
                      columns: [
                        { value: "appIdentifier", label: "Bundle Id" },
                        { value: "appName", label: "App Name" },
                      ],
                    },
                    {
                      value: "appstoreConnectApiKey",
                      label: "Api Key",
                      multiline: true,
                      placeholder: this.state.savedIosDeployment
                        ?.appstoreConnectApiKey
                        ? this.state.savedIosDeployment?.appstoreConnectApiKey.substr(
                            0,
                            35
                          ) +
                          " xxxxxxx " +
                          this.state.savedIosDeployment?.appstoreConnectApiKey.substr(
                            -33
                          )
                        : `-----BEGIN PRIVATE KEY-----\nxxxxxxxxxx \n-----END PRIVATE KEY-----`,
                    },
                    {
                      value: "apikeydata",
                      columns: [
                        {
                          value: "appstoreConnectApiKeyId",
                          label: "Api Key Id",
                        },
                        {
                          value: "appstoreConnectApiKeyIssuerId",
                          label: "Api Key Issuer Id",
                        },
                      ],
                    },
                    { value: "appleUserEmail", label: "Email Id" },
                    {
                      value: "teamdata",
                      columns: [
                        { value: "developerPortalTeamId", label: "Team Id" },
                        {
                          value: "appstoreConnectTeamId",
                          label: "ITC Team Id",
                        },
                      ],
                    },
                  ].map((item) => {
                    return (
                      <div className="pbRow" key={item.value}>
                        {!item.columns ? (
                          <div className="pbItem">
                            <div className="pbLabel">{item.label}</div>
                            {item.multiline ? (
                              <textarea
                                rows={3}
                                type={item.type || "text"}
                                className="mInput"
                                placeholder={item.placeholder || item.label}
                                value={
                                  this.state.iosDeployment?.[item.value] || ""
                                }
                                onChange={(e) =>
                                  this.setState({
                                    iosDeployment: {
                                      ...(this.state.iosDeployment || {}),
                                      [item.value]: e.target.value,
                                    },
                                  })
                                }
                              />
                            ) : (
                              <input
                                type={item.type || "text"}
                                className="mInput"
                                placeholder={item.placeholder || item.label}
                                value={
                                  this.state.iosDeployment?.[item.value] || ""
                                }
                                onChange={(e) =>
                                  this.setState({
                                    iosDeployment: {
                                      ...(this.state.iosDeployment || {}),
                                      [item.value]: e.target.value,
                                    },
                                  })
                                }
                                required
                              />
                            )}
                          </div>
                        ) : (
                          <>
                            {item.columns.map((item) => (
                              <div className="pbItem" key={item.value}>
                                <div className="pbLabel">{item.label}</div>
                                <input
                                  type={item.type || "text"}
                                  className="mInput"
                                  placeholder={item.label}
                                  value={
                                    this.state.iosDeployment?.[item.value] || ""
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      iosDeployment: {
                                        ...(this.state.iosDeployment || {}),
                                        [item.value]: e.target.value,
                                      },
                                    })
                                  }
                                  required
                                />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    );
                  })}

                  {/*                
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Camera permission text</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                </div>

                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Microphone permission text</div>
                    <input type="text" className="mInput" placeholder="" />
                  </div>
                </div> */}

                  <div className="minActionRow">
                    <div
                      className="minButton"
                      onClick={() => {
                        if (this.state.loadingObj.iosDeploymentupdateCredential)
                          return;

                        if (!this.iosconfigform?.current?.reportValidity())
                          return;

                        this.updateCredential("iosDeployment", {
                          appstoreConnectApiKey:
                            this.state.savedIosDeployment
                              ?.appstoreConnectApiKey,
                          ...(this.state.iosDeployment || {}),
                        });
                      }}
                    >
                      {this.state.loadingObj.iosDeploymentupdateCredential
                        ? "Loading"
                        : "Save"}
                    </div>
                    <div
                      className="minLightButton"
                      onClick={() => {
                        this.setToggleables("iosPublish", false);

                        if (this.state.loadingObj.iosDeploymentupdateCredential)
                          return;
                        this.setState({
                          iosDeployment: _.omit(this.state.savedIosDeployment, [
                            "appstoreConnectApiKey",
                          ]),
                        });
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="borderItem">
              <div className="mLabel">Android App</div>
              <div className="publishRow">
                <div className="mValue">
                  {this.getBuildStatusMessage(this.state.androidBuildTask)}
                </div>
                <div className="publishRow">
                  {this.state.androidBuildTask?.linkedFile?.is_deleted ===
                  false ? (
                    <a
                      href={api.getFileLink(
                        this.state.androidBuildTask.linkedFile
                      )}
                    >
                      <div className="defaultButton mtm15">Download</div>
                    </a>
                  ) : null}

                  {toggleables.androidPublish ? (
                    <div
                      className="defaultButton mtm15"
                      onClick={() => this.handleAndroidPublish()}
                    >
                      {this.state.loadingObj.androidPublish
                        ? "Loading"
                        : "Publish"}
                    </div>
                  ) : (
                    <div
                      className="defaultButton mtm15"
                      onClick={() =>
                        this.setToggleables(
                          "androidPublish",
                          !toggleables.androidPublish
                        )
                      }
                    >
                      Details
                    </div>
                  )}
                </div>
              </div>

              <div
                className="publishDetails"
                style={
                  toggleables.androidPublish
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {[
                  { value: "packageName", label: "Package Name" },
                  {
                    value: "appName",
                    label: "App Name",
                    placeholder: "My App",
                  },
                ].map((item) => {
                  return (
                    <div className="pbRow" key={item.value}>
                      <div className="pbItem">
                        <div className="pbLabel">{item.label}</div>

                        <input
                          type={item.type || "text"}
                          className="mInput"
                          placeholder={item.placeholder || item.label}
                          value={
                            this.state.androidDeployment?.[item.value] || ""
                          }
                          onChange={(e) =>
                            this.setState({
                              androidDeployment: {
                                ...(this.state.androidDeployment || {}),
                                [item.value]: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="minActionRow">
                  <div
                    className="minButton"
                    onClick={() => {
                      if (
                        this.state.loadingObj.androidDeploymentupdateCredential
                      )
                        return;
                      this.updateCredential("androidDeployment", {
                        ...(this.state.androidDeployment || {}),
                      });
                    }}
                  >
                    {this.state.loadingObj.androidDeploymentupdateCredential
                      ? "Loading"
                      : "Save"}
                  </div>
                  <div
                    className="minLightButton"
                    onClick={() => {
                      this.setToggleables("androidPublish", false);

                      if (
                        this.state.loadingObj.androidDeploymentupdateCredential
                      )
                        return;
                      this.setState({
                        androidDeployment: _.omit(
                          this.state.savedAndroidDeployment,
                          ["appstoreConnectApiKey"]
                        ),
                      });
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tLabel mb10">Status</div>
          <div className="borderBox mb20">
            <div className="borderItem">
              <div className="mLabel">Staging subdomain</div>
              <div className="publishRow">
                <div className="mValue">
                  {`${projectProps?.subdomain || ""}.${config.frontDomain}`}
                </div>
                <div
                  className="defaultButton mtm15"
                  onClick={() =>
                    this.setToggleables("subdomain", !toggleables.subdomain)
                  }
                >
                  Change
                </div>
              </div>

              <div
                className="publishDetails"
                style={
                  toggleables.subdomain
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Staging Subdomain</div>
                    <div className="pbDomainRow">
                      <SearchInput
                        type="text"
                        className="mInput"
                        placeholder="subdomain"
                        value={project.subdomain || ""}
                        onChange={(text) => this.handleSubdomain(text)}
                      />
                      <div className="pbDomain">.{config.frontDomain}</div>
                    </div>
                    {toggleables.subdomainCheck === project.subdomain ? (
                      <div className="domainLabel mt10 success">
                        Subdomain is available
                      </div>
                    ) : toggleables.subdomainCheck === false ? (
                      <div className="domainLabel mt10 fail">
                        Subdomain is not available
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="minActionRow">
                  <div
                    className="minButton"
                    onClick={() =>
                      this.submit(
                        { subdomain: project.subdomain },
                        "subdomain",
                        {
                          onSuccess: () =>
                            this.setToggleables("subdomain", false),
                        }
                      )
                    }
                  >
                    Submit
                  </div>
                  <div
                    className="minLightButton"
                    onClick={() => {
                      this.setToggleables("subdomain", false);
                      this.setState({
                        project: {
                          ...project,
                          subdomain: projectProps?.subdomain,
                        },
                      });
                    }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
            <div className="borderItem">
              <div className="mLabel">Production domain</div>
              <div className="publishRow">
                <div className="mValue">{this.getProductionDomainName()}</div>

                {toggleables.webPublish ? (
                  <div
                    className="defaultButton mtm15"
                    onClick={() =>
                      this.submit(
                        {
                          productionDomains: project.productionDomains?.map(
                            (x) => ({
                              ...x,
                              name: x.name.replace(/^www\./, ""),
                            })
                          ),
                        },
                        "productionDomains"
                      )
                    }
                  >
                    Publish
                  </div>
                ) : (
                  <div
                    className="defaultButton mtm15"
                    onClick={() =>
                      this.setToggleables("webPublish", !toggleables.webPublish)
                    }
                  >
                    Details
                  </div>
                )}
              </div>

              <div
                className="publishDetails"
                style={
                  toggleables.webPublish
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">Domain Name</div>
                    <input
                      type="text"
                      className="mInput"
                      placeholder="example.com"
                      value={project?.productionDomains?.[0]?.name || ""}
                      onChange={(e) =>
                        this.setState({
                          domainConnectionCheck: null,
                          project: {
                            ...this.state.project,
                            productionDomains: [
                              {
                                name: e.target.value.toLowerCase(),
                              },
                            ],
                          },
                        })
                      }
                    />
                  </div>
                </div>

                <div className="minActionRow">
                  <div
                    className="minButton"
                    onClick={() => this.checkConnection()}
                  >
                    Check Connection
                  </div>
                  <div
                    className={
                      "domainLabel " +
                      (domainConnectionCheck === false
                        ? "fail"
                        : domainConnectionCheck === true
                        ? "success"
                        : "")
                    }
                  >
                    {this.state.loadingObj.domainConnectionCheck
                      ? "Checking.."
                      : connectionTestMessage}
                  </div>
                </div>
              </div>

              <div
                className="publishDetails"
                style={
                  toggleables.webPublish
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">A Record</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue={config.frontProductionIp}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">CNAME</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue="appxolo"
                    />
                  </div>
                </div>
                <div className="pbRow">
                  <div className="pbItem">
                    <div className="pbLabel">TXT</div>
                    <input
                      type="text"
                      className="mInput"
                      defaultValue="g7df78d87g98sf"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="tLabel mb10">Details</div>
          <div className="borderBox mb20">
            <div>
              <div className="borderItem">
                <div className="mLabel">Screens</div>
                <div className="mValue">
                  {
                    this.props.components?.filter(
                      (x) => x.category === "screen"
                    )?.length
                  }
                </div>
              </div>

              <div className="borderItem">
                <div className="mLabel">Action</div>
                <div className="minActionRow jStart">
                  <div
                    className="defaultButton"
                    onClick={() => {
                      this.setToggleables(
                        "transferApp",
                        !toggleables.transferApp
                      );
                    }}
                  >
                    Transfer App
                  </div>
                  <div
                    className="negativeButton"
                    onClick={() => this.deleteProject()}
                  >
                    Delete App
                  </div>
                </div>

                <div
                  className="publishDetails"
                  style={
                    toggleables.transferApp
                      ? { display: "flex" }
                      : { display: "none" }
                  }
                >
                  <div className="pbRow">
                    <div className="pbItem">
                      <div className="pbLabel">Send to:</div>
                      <input
                        type="text"
                        className="mInput"
                        placeholder="example@gmail.com"
                        value={toggleables.transerferEmail || ""}
                        onChange={(e) =>
                          this.setToggleables('transerferEmail', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="minActionRow">
                    <div
                      className="minButton"
                      onClick={() => this.handleTransferClick()}
                    >
                      Submit
                    </div>
                    <div
                      className="minLightButton"
                      onClick={() => {
                        this.setToggleables(
                          "transferApp",
                          !toggleables.transferApp
                        );
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectDetailsInfo;
