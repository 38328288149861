import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import withRouter from "../../Components/Wrapper/with-router";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import SoruceElement from "./SoruceElement";
import Canvas from "./Canvas";
import Hierarchy from "./Hierarchy";
import SaveComponent from "./ActionButtons.js/SaveComponent";
import { ShowPaymentMethodBtn } from "./ActionButtons.js/PaymentMethods";
import { ShowCredentialBtn } from "./ActionButtons.js/ThirdPartyCredentials";
import Logout from "./ActionButtons.js/Logout";
import config from "../../Config";
import ElementProperties from "./Properties/ElementProperties";
import { ShowTriggerBtn } from "../Common/Triggers/Triggers";
import defaultStyles from "../../appxolo-engine/modules/styling/defaultStyles";

import sourceElements from "../../appxolo-engine/modules/source-elements/source-elements";
import _dom from "../../appxolo-engine/modules/dom/Dom";
import { ShowRtcLoggerBtn } from "../Common/RtcLogger";
import { ShowOnlineDeviceLoggerBtn } from "../Common/OnlineDeviceLogger";
import DatabasePanel from "../DatabasePanel/DatabasePanel";
import ChangeLog from "./ChangeLog";
import UserPresence from "./UserPresence";
import { socketService } from "../../Services/Socket/socketListeners";
import { ProjectDetailPopupButton } from "../Project/ProjectDetailPopup";

const { DomTree, DomTreeNode } = _dom;

export class BuilderInner extends React.Component {
  constructor(props) {
    super(props);
    this.sourceElements = this.prepareSourceElements(sourceElements);
  }

  state = {
    error: null,
    loading: false,
    component: null,
    ts: Date.now(),
    showStylePanel: process.env.REACT_APP_DEVELOPER !== "bis",
  };

  componentDidMount() {
    this.load();

    socketService.joinSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    this.componentReloadSocketSubId = socketService.subscribeSocketEvent(
      "v1/component/reload",
      (data) => {
        if (data.component.toString() === this.props.componentId?.toString()) {
          this.load();
        }
      }
    );
  }

  componentWillUnmount() {
    socketService.leavSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    socketService.unsubscribeSocketEvent(
      "v1/component/reload",
      this.componentReloadSocketSubId
    );
  }

  triggerRenderTimer = null;
  triggerRender() {
    clearTimeout(this.triggerRenderTimer);
    this.triggerRenderTimer = setTimeout(() => {
      this.setState({ ts: Date.now() });
    }, 50);
  }

  prepareSourceElements(sourceElements) {
    return sourceElements.map((sourceElement) => ({
      ...sourceElement,
      data: {
        ...sourceElement.data,
        tabs: (sourceElement?.data?.tabs || []).map((tab) => ({
          ...tab,
          styleData: defaultStyles[sourceElement.elementType],
        })),
      },
    }));
  }

  async load() {
    try {
      if (this.state.loading) return;

      this.dom = null;
      this.props.setScreenState({
        project: null,
        components: null,
      });

      this.setState({ error: null, loading: true });
      const { project, component, components, databases } = await api.get(
        "v1/project/builder-data",
        { component: this.props.componentId }
      );
      // console.log({ project, component, components });

      const domTreeNode = new DomTreeNode(
        "ROOT",
        { elementType: "container" },
        component?.data?.dom?.children
      );

      const domTree = new DomTree(domTreeNode, this.triggerRender.bind(this));
      this.dom = domTree;

      this.setState({ loading: false, component });
      this.props.setScreenState({ project, components, databases });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      toast.error(e.message, {
        hideProgressBar: true,
        pauseOnHover: true,
      });
    }
  }

  getCommonPropsToPass() {
    const {
      props: { components, setScreenState, allBuilderData, project, databases },
      state: { ts, component },
      dom,
    } = this;

    const builderData = _.pick(allBuilderData, [
      "hoverPosition",
      "hoverIndex",
      "focusedElement",
      "hoveredIndices",
    ]);

    const commonPropsToPass = {
      project,
      dom,
      builderData,
      setBuilderData: (x) => {
        setScreenState(x);
      },
      triggerRender: this.triggerRender.bind(this),
      ts,
      availableFor: "front",
      databases,
      component,
      components,
    };

    return commonPropsToPass;
  }

  screenList() {
    const {
      props: { components },
      state: { component },
    } = this;

    return (
      <div className="builderPanel two">
        <div className="builderScreens">
          <div className="card-body">
            <div className="builderScreensLabel">
              Screens
              <span>+ Add</span>
            </div>

            <div className="screenSelectorItem displayNone">
              <div className="optionInputIconBoxField">
                Screen Name
              </div>
              <img
                className="optionDatabaseSelectChevron"
                src={require("../../Assets/img/options/container/chevronDown.png")}
              ></img>
            </div>

            <div className="builderScreenList">
              <div className="screenItem addScreen displayNone">
                <div className="screenItemLabel">+ Add Screen</div>
              </div>
              {components
                ?.filter((x) => x.category === "screen")
                ?.map((item) => {
                  return (
                    <Link
                      key={item._id}
                      to={"/canvas/" + item._id}
                      className="screenItem"
                      style={Object.assign(
                        {},
                        {
                          border: "",
                        },
                        item._id === component?._id
                          ? {
                              background: "transparent",
                              borderColor: "#212121",
                            }
                          : {}
                      )}
                    >
                      <div
                        className="screenItemLabel"
                      >
                        {item.name}
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  leftPanel() {
    const {
      props: { project, databases },
      dom,
      sourceElements,
    } = this;

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="builderPane">
        <div className="builderPanel one">
          <div className="builderTools">
            <div className="builderElementsLabel">Elements</div>
            <div className="builderElements">
              {sourceElements.map((item) => (
                <SoruceElement
                  {...commonPropsToPass}
                  key={item.elementType}
                  item={item}
                />
              ))}
            </div>
          </div>
        </div>

        {this.screenList()}

        <UserPresence {...commonPropsToPass} />

        <div className="builderPanel displayNone">
          <div className="builderActions">
            <div className="card-body">
              <ShowPaymentMethodBtn
                filter={{
                  where: { project: project?._id },
                }}
              />
              <ShowCredentialBtn
                filter={{
                  where: { project: project?._id },
                }}
              />
              <ShowTriggerBtn {...{ project, databases, dom }} />
              <ShowRtcLoggerBtn {...{ project, databases, dom }} />
              <ShowOnlineDeviceLoggerBtn {...{ project, databases, dom }} />
              <Link to={`/database?projectId=${project?._id}`}>
                <button>Database Viewer</button>
              </Link>
              <ProjectDetailPopupButton
                {...{
                  ...commonPropsToPass,
                  projectId: commonPropsToPass.project?._id,
                }}
              />
              <Logout />
            </div>
          </div>
        </div>

        <div className="builderPanel three">
          <div className="builderHierarchy">
            <Hierarchy {...commonPropsToPass} />
          </div>
        </div>

        {commonPropsToPass?.component?._id ? (
          <div>
            <ChangeLog
              {...commonPropsToPass}
              reload={() => {
                this.load().then(this.triggerRender.bind(this));
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }

  centerPanel() {
    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="builderCanvas">
        <Canvas {...commonPropsToPass} />
        <DatabasePanel />
      </div>
    );
  }

  rightPanel() {
    const {
      props: { allBuilderData },
    } = this;

    const builderData = _.pick(allBuilderData, [
      "hoverPosition",
      "hoverIndex",
      "focusedElement",
      "hoveredIndices",
    ]);

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="builderOptions">
        <div className="optionsPanel">
          {builderData.focusedElement?.element?.id ? (
            <ElementProperties
              key={builderData.focusedElement?.element?.id}
              focusedElement={builderData.focusedElement}
              {...commonPropsToPass}
            />
          ) : null}
        </div>
      </div>
    );
  }

  headerRight() {
    const {
      props: { project },
      state: { component },
      dom,
    } = this;

    const commonPropsToPass = this.getCommonPropsToPass();

    return (
      <div className="xoloPush">
        <div className="currentActiveUsers">
          <div className="cauItem">SK</div>
          <div className="cauItem">AS</div>
        </div>

        <ProjectDetailPopupButton
          {...{
            ...commonPropsToPass,
            projectId: commonPropsToPass.project?._id,
          }}
        />

        <SaveComponent
          component={{
            ...component,
            data: { ...(component?.data || {}), dom: dom?.tree },
          }}
        />
        {/* <div className="xoloStatus">
          <div className="xoloStatusInner"></div>
        </div> */}
        <a
          href={`//${project?.subdomain}.${config.frontDomain}/${component?.name}`}
          target="execute"
        >
          <div className="xoloRepublish">
            <div className="xoloRepublishLabel">Republish</div>
            <div className="xoloRepublishIco"></div>
          </div>
        </a>
      </div>
    );
  }

  headerBottom() {
    const {
      props: { project },
    } = this;

    return (
      <div className="xoloHeaderBottom">
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link className="xoloBionicLabel" to={`/project/${project?._id}`}>
              Screens
            </Link>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link
              className="xoloBionicLabel"
              to={`/database?projectId=${project?._id}`}
            >
              Database
            </Link>
          </div>
        </div>
        <div className="xoloPlatform">
          <div className="xoloPlatformItem active">iOS</div>
          <div className="xoloPlatformItem">Android</div>
        </div>
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Templates</div>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Admin Panel</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { components, project, databases },
      state: { loading, component },
      dom,
    } = this;

    return (
      <>
        <NavBar
          activeLink="/projects"
          right={this.headerRight()}
          bottom={this.headerBottom()}
          project={this.props.project}
        />
        {loading && !components ? (
          <center>Loading...</center>
        ) : dom && component && project ? (
          <div className="appxoloBuilder">
            {this.leftPanel()}
            {this.centerPanel()}
            {this.rightPanel()}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const Builder = DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(BuilderInner)
);

const BuilderScreen = withRouter((props) => (
  <Builder
    key={props.router.params.componentId}
    {...{ ...props.router.params }}
  />
));

export default BuilderScreen;
