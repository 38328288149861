import React from "react";
import update from "immutability-helper";

import RepeatingMark from "./RepeatingMark";
import FixedMark from "./FixedMark";

const PlotMarks = (props) => {
  const { value, onChange } = props;
  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  const locationMarks = operation.locationMarks || [];
  const setMarks = (value) =>
    setOperationObj(update(operation, { $merge: { locationMarks: value } }));

  const setMark = (obj) => setMarks(update(locationMarks, { $merge: obj }));
  const appendMark = () =>
    setMarks(update(locationMarks, { $push: [{ uid: Math.random() }] }));

  if (operation.typeOfData === "repeating")
    return (
      <>
        <RepeatingMark
          {...props}
          value={locationMarks[0] || {}}
          // eslint-disable-next-line no-useless-computed-key
          onChange={(value) => setMark({ [0]: value })}
          mapCenter={operation.mapCenter}
          changeMapCenter={(value) => setOperationObj({ mapCenter: value })}
        />
      </>
    );
  else
    return (
      <>
        {locationMarks.map((location, markerNo) => {
          const value = location || {};
          const onChange = (value) => setMark({ [markerNo]: value });
          const remove = () =>
            setMarks(update(locationMarks, { $splice: [[markerNo, 1]] }));

          return (
            <FixedMark
              {...{
                ...props,
                value,
                onChange,
                markerNo,
                remove,
                key: value.uid,
              }}
            />
          );
        })}

        <div onClick={() => appendMark()} className="mapLinkingWrapper">
          <div className="optionAddLinkingButton">Add Marker</div>
        </div>
      </>
    );
};

export default PlotMarks;
