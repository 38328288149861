import React from "react";
import { connect } from "react-redux";
import CustomSelect from "../../../../../../Components/etc/CustomSelect";
import CustomUrlParameters from "../../../../../Common/Linking/CustomUrlParameters";

const ScreenConfig = (props) => {
  const {
    value = {},
    onChange,
    components: screens,
    component: currentScreen,
  } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  return (
    <>
      <div className="optionItemRow revealOffset">
        <CustomSelect
          value={value.to || ""}
          onChange={(option) =>
            mergeChange({ to: option.value, name: option.label })
          }
          options={screens
            .filter((x) => x?._id !== currentScreen._id)
            .map((screen) => ({ value: screen._id, label: screen.name }))}
          placeholder={"Screen Name"}
          className="optionItemBox"
          classNames={{
            head: "optionInputIconBox",
            label: "optionInputIconBoxField",
            chevron: "optionDatabaseSelectChevron",
          }}
        />
      </div>

      <CustomUrlParameters
        {...{
          ...props,
          value: value.urlParameters,
          onChange: (urlParameters) => mergeChange({ urlParameters }),
        }}
      />
    </>
  );
};

export default connect((state) => ({
  components: state.vState.BUILDER.components,
}))(ScreenConfig);
