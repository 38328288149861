import React from "react";
import { toast } from "react-toastify";
import update from "immutability-helper";
import _ from "lodash";

import api from "../../../Services/Api/api";
import CustomSelect from "../../../Components/etc/CustomSelect";
import LinkingBox from "../../Common/Linking/LinkingBox";
import ConditionsBox from "../../Common/DataQueryOperations/ConditionsBox";
import ToggleButton from "../../../Components/Input/ToggleButton";

class ProjectTriggers extends React.Component {
  state = {
    items: null,
    loading: false,
    error: null,
    selected: [],
  };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });
      const { triggers } = await api.get("v1/project/trigger", {
        filter: JSON.stringify({
          where: {
            project: this.props.project._id,
          },
        }),
      });
      this.setState({ loading: false, items: triggers });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: null });
    }
  }

  async deleteTrigger({ _id }, index) {
    try {
      if (_id) {
        this.setState({ loading: true, error: null });
        await api.delete("v1/project/trigger/" + _id);
        this.setState({ loading: false });

        index = this.state.items?.findIndex((x) => x._id === _id);
      }

      this.setState({
        items: update(this.state.items, { $splice: [[index, 1]] }),
      });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
    }
  }

  async deleteTriggers(items) {
    if (!items.length) return;

    let deletedItems = [];
    try {
      this.setState({ loading: true, error: null });
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        let index = this.state.items?.findIndex((x) =>
          item._id ? x._id === item._id : x.uid ? x.uid === item.uid : false
        );

        if (index > -1) {
          if (item?._id) {
            await api.delete("v1/project/trigger/" + item._id);
          }
          deletedItems.push(item);
        }
      }
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false, error: e.message });
      toast.error(e.message);
    }

    if (deletedItems.length) {
      const updated = this.state.items.filter(
        (item) =>
          !deletedItems.find((x) =>
            item._id ? x._id === item._id : x.uid ? x.uid === item.uid : false
          )
      );

      console.log({ items, deletedItems, updated });

      this.setState({
        items: updated,
      });
    }
  }

  addTrigger() {
    this.setState({
      items: [
        {
          uid: Date.now(),
          data: { dataType: "conditional" },
        },
        ...(this.state.items || []),
      ],
    });
  }

  toggleSelection(item) {
    this.setState({
      selected: this.isSelected(item, this.state.selected)
        ? this.state.selected.filter((x) =>
            item._id ? x._id !== item._id : x.uid !== item.uid
          )
        : [...this.state.selected, item],
    });
  }

  isSelected(item, selected) {
    return !!selected.find((x) =>
      item._id ? x._id === item._id : item.uid ? x.uid === item.uid : false
    );
  }

  render() {
    const {
      state: { selected, items },
    } = this;

    return (
      <>
        <div className="pageTitle">
          <div>Project Triggers</div>
          <span>Manage your project triggers</span>
        </div>

        <div className="contentBody">
          <div className="dataTableActionRow">
            <div className="defaultButton" onClick={() => this.addTrigger()}>
              <div className="buttonPlus">
                <div></div>
                <div></div>
              </div>
              Add
            </div>
            <div
              onClick={() => this.deleteTriggers(selected)}
              className="negativeButton"
            >
              Delete Selected
            </div>
          </div>
          <div className="dataTable removeBorder">
            <table>
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>On</th>
                  <th>Database</th>
                  <th>Action</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {items?.map((item, index) => (
                  <Trigger
                    {...{
                      ...this.props,
                      key: item.uid || item._id,
                      trigger: item,
                      index,
                      toggleSelection: () => this.toggleSelection(item),
                      selected: this.isSelected(item, selected),
                      onSuccess: () => this.load(),
                      deleteTrigger: () => this.deleteTriggers([item]),
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

class Trigger extends React.Component {
  initialState = {
    loading: false,
    visible: null,
    error: null,
    trigger: {
      project: this.props.project?._id,
      triggerOn: "create",
      triggerLocation: "remote",
      data: {
        tabs: [{ uid: Math.random() }],
      },
      status: "active",
      ...this.props.trigger,
    },
  };

  state = { ...this.initialState };

  async onSubmit() {
    try {
      if (this.state.loading) return;
      this.setState({ loading: true, error: null });

      const payload = {
        _id: this.state.trigger._id,
        project: this.state.trigger.project,
        triggerOn: this.state.trigger.triggerOn,
        triggerLocation: this.state.trigger.triggerLocation,
        triggerInterval: this.state.trigger.triggerInterval,
        triggerIntervalUnit: this.state.trigger.triggerIntervalUnit,
        data: this.state.trigger.data,
      };

      await api.post("v1/project/trigger", payload);

      this.setState({ loading: false, visible: false });
      this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  async toggleStatus() {
    try {
      if (this.state.loading) return;
      // this.setState({ loading: true, error: null });

      let status =
        this.state.trigger.status === "active" ? "disable" : "active";
      this.mergeTrigger({ status });

      const payload = {
        _id: this.state.trigger._id,
        project: this.state.trigger.project,
        status: status,
      };

      await api.post("v1/project/trigger", payload);

      // this.setState({ loading: false, visible: false });
    } catch (e) {
      toast.error(e.message);
      // this.setState({ error: e.message, loading: false });
      this.mergeTrigger({ status: this.props.trigger.status });
    }
  }

  mergeTrigger = (obj) => {
    this.setState({ trigger: { ...this.state.trigger, ...obj } });
  };

  mergeTriggerData = (obj) => {
    this.mergeTrigger({
      data: { ...(this.state.trigger.data || {}), ...obj },
    });
  };

  mergeLinkingTab = (obj, index = 0) => {
    const existing = this.state.trigger?.data?.linkingTabs?.[index] || {};
    const updatedLinkingTab = { ...existing, ...obj };
    this.mergeTriggerData({
      linkingTabs: update(this.state.trigger?.data?.linkingTabs || [], {
        $merge: { [index]: updatedLinkingTab },
      }),
    });
  };

  render() {
    const {
      props: { index, selected, toggleSelection },
      state: { trigger, error, loading },
    } = this;

    const {
      triggerOn,
      triggerLocation,
      data,
      triggerInterval,
      triggerIntervalUnit,
      status,
    } = trigger;

    const databases = this.props.databases;
    const remoteDatabases = databases?.filter((x) => x.type === "remote");

    return (
      <>
        <tr>
          <td>
            <div className="tableData">
              <div
                className={"tdRadio " + (selected ? "active" : "")}
                onClick={() => toggleSelection()}
              >
                <div className="tdRadioDecor"></div>
              </div>
              <div className="tdID">{index + 1}</div>
            </div>
          </td>
          <td>
            <CustomSelect
              value={triggerOn}
              onChange={(option) => {
                const triggerOn = option.value;
                this.mergeTrigger(
                  ["onCallAnswer"].includes(triggerOn)
                    ? { triggerOn, triggerLocation: "local" }
                    : { triggerOn }
                );
              }}
              options={[
                { value: "create", label: "Add Record" },
                { value: "update", label: "Edit Record" },
                { value: "delete", label: "Delete Record" },
                { value: "auto", label: "Auto Interval" },
                { value: "onCallAnswer", label: "On Call Answer" },
              ]}
              placeholder={"Select"}
              className={"tableData"}
              classNames={{
                head: "tdDropdown add",
                label: "tdDropdownLabel",
              }}
              jointActionRow={
                <div className="tdDropdownChev">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              }
            />

            {triggerOn === "auto" ? (
              <div>
                Trigger Interval:{" "}
                <input
                  type={triggerIntervalUnit === "cron" ? "text" : "number"}
                  value={triggerInterval || ""}
                  onChange={(e) =>
                    this.mergeTrigger({ triggerInterval: e.target.value })
                  }
                ></input>
                <select
                  value={triggerIntervalUnit}
                  onChange={(e) =>
                    this.mergeTrigger({ triggerIntervalUnit: e.target.value })
                  }
                >
                  {[
                    { value: "sec", label: "Second" },
                    { value: "min", label: "Minute" },
                    { value: "hour", label: "Hour" },
                    { value: "day", label: "Day" },
                    { value: "cron", label: "Cron String" },
                  ]
                    .filter((x) =>
                      triggerLocation === "local" ? x.value !== "cron" : true
                    )
                    .map((x) => (
                      <option key={x.value} value={x.value}>
                        {x.label}
                      </option>
                    ))}
                </select>
              </div>
            ) : null}
          </td>
          <td>
            {/* <div className="tableData">
              <div className="tdDropdown">
                <img
                  className="tdDropdownIco"
                  src={require("../../../Assets/img/options/options/database.png")}
                ></img>
                <div className="tdDropdownLabel">iMessenger</div>
                <div className="tdDropdownChev">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              </div>
            </div> */}

            <CustomSelect
              value={triggerLocation}
              onChange={(option) =>
                this.mergeTrigger({
                  triggerLocation: option.value,
                  triggerInterval:
                    triggerIntervalUnit === "cron" ? "1" : triggerInterval,
                  triggerIntervalUnit:
                    triggerIntervalUnit === "cron"
                      ? "min"
                      : triggerIntervalUnit,
                  data: {
                    tabs: [{ uid: Math.random() }],
                  },
                })
              }
              options={(["onCallAnswer"].includes(triggerOn)
                ? ["local"]
                : ["remote", "local"]
              ).map((x) => ({ value: x, label: _.startCase(x) }))}
              placeholder={"Select"}
              className={"tableData"}
              classNames={{
                head: "tdDropdown add",
                label: "tdDropdownLabel",
              }}
              jointActionRow={
                <div className="tdDropdownChev">
                  <div className="one"></div>
                  <div className="two"></div>
                </div>
              }
            />
          </td>
          <td>
            <div className="tableData">
              <div className="minActionRow">
                <div className="minButton" onClick={() => this.onSubmit()}>
                  {loading ? "Set" : "Set"}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="tableData">
              <div
                onClick={() => this.toggleStatus()}
                className={
                  "switchInput" + (status === "active" ? " active" : "")
                }
              >
                <div className="switchBall"></div>
              </div>
            </div>
          </td>
          <td>
            <div className="tableData">
              <div className="minActionRow">
                <div
                  className="removeButton"
                  onClick={() => this.props.deleteTrigger()}
                >
                  Delete
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={7} className="triggerWrapper">
            <div className="triggerContainer">
              <div className="triggerCondition shadow">
                <div className="conditionHead active">
                  <div className="conditionHeadContext">
                    <div className="conditionTitle">Action</div>
                    <ToggleButton
                      classNames={{
                        container: "optionHeadTabs",
                        item: "optionHeadTabItem",
                      }}
                      overideDefaultStyle
                      value={data?.dataType || "fixed"}
                      onChange={(x) =>
                        this.mergeTrigger({
                          data: {
                            ...(data || {}),
                            dataType: x,
                            activeTabIndex: 0,
                          },
                        })
                      }
                      options={["fixed", "conditional"]?.map((x) => ({
                        value: x,
                        label: _.startCase(x),
                      }))}
                    />
                  </div>

                  <div className="conditionAction">
                    <div></div>
                  </div>
                </div>

                {data.dataType === "conditional" ? (
                  <DataCondition
                    {...this.props}
                    value={data}
                    onChange={(x) => this.mergeTrigger({ data: x })}
                    availableFor={
                      triggerLocation === "remote" ? "backend" : null
                    }
                    databases={
                      triggerLocation === "remote" ? remoteDatabases : databases
                    }
                  />
                ) : null}
              </div>

              <div className="triggerSeperator"></div>

              <LinkingBoxes
                {...{
                  ...this.props,
                  value: data?.tabs?.[data?.activeTabIndex || 0]?.linkings,
                  onChange: (linkings) => {
                    let tabs = update(data?.tabs || [], {
                      $merge: {
                        [data?.activeTabIndex || 0]:
                          data?.tabs?.[data?.activeTabIndex || 0] || {},
                      },
                    });
                    this.mergeTrigger({
                      data: {
                        ...(data || {}),
                        tabs: update(tabs, {
                          [data?.activeTabIndex || 0]: { $merge: { linkings } },
                        }),
                      },
                    });
                  },
                  availableFor: triggerLocation === "remote" ? "backend" : null,
                  autoAddOneEmptyLinking: true,
                  linkBoxOptions: {
                    conditionalDataType: true,
                    showTriggerOption: false,
                    mode: "trigger",
                    hideLinkDropdown: false,
                  },
                }}
              />

              <div className="saveTrigger shadow">Exit Builder</div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export const LinkingBoxes = (props) => {
  const { value = [], onChange, autoAddOneEmptyLinking } = props;

  React.useEffect(() => {
    if (!value.length && autoAddOneEmptyLinking) {
      onChange([{ uid: Math.random() }]);
    }
  }, [value.length, onChange, autoAddOneEmptyLinking]);

  return (
    <>
      {value.map((link, i) => {
        return (
          <LinkingBox
            {...{
              ...props,
              key: link.uid,
              value: link,
              delete: null,
              onChange: (link) =>
                onChange(update(value || [], { $merge: { [i]: link } })),
            }}
          />
        );
      })}
    </>
  );
};

const DataCondition = (props) => {
  const activeTab = props.value?.tabs?.[props.value?.activeTabIndex || 0] || {};

  const setActiveTab = (tab) => {
    props.onChange({
      ...(props.value || {}),
      tabs: update(props.value?.tabs || [], {
        $merge: { [props.value?.activeTabIndex || 0]: tab },
      }),
    });
  };

  const conditions = activeTab.conditions;
  const setConditions = (conditions) => {
    setActiveTab({ ...activeTab, conditions });
  };

  return (
    <>
      <ConditionsBox
        {...{
          ...props,
          value: conditions,
          onChange: setConditions,
        }}
      />
    </>
  );
};

export default ProjectTriggers;
