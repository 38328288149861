import React from "react";
import OptionItem from "../../../../../Components/etc/OptionItem";

const AlignItems = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const items = [
    [
      {
        key: "flex-start",
        label: "Top",
        subtext: "Start top",
        decorClass: "jStart",
      },
      {
        key: "flex-end",
        label: "Bottom",
        subtext: "start end",
        decorClass: "jEnd",
      },
      {
        key: "stretch",
        label: "Stretch",
        subtext: "End to end",
        decorClass: "jSpaceBetween",
      },
      {
        key: "center",
        label: "Center",
        subtext: "Middle",
        decorClass: "jCenter",
      },
    ],
  ];

  return (
    <div className="optionItemBody revealOffset">
      {items.map((items, i) => (
        <div className="optionItemRow aCenter" key={i}>
          <div className="optionItemListLabelContainer">
            Vertical
            <img
              className="oillIco rotate90"
              src={require("../../../../../Assets/img/options/container/widthIco.png")}
            ></img>
          </div>

      
          {items.map((item) => {
            const active = item.key === styles.alignItems;
            return (
              <div className="optionItemBox" key={item.key}>
                <div
                  className={
                    "advancedRadioItem nvoItem" + (active ? " lightActive" : "")
                  }
                  onClick={() =>
                    mergeStyle({
                      alignItems: item.key,
                      display: styles.display || "flex",
                    })
                  }
                >
                  <div className={"verticalOptions " + item.decorClass}>
                    <img
                      className="verticalArrowIco one"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>
                    <img
                      className="verticalArrowIco oneOnly"
                      src={require("../../../../../Assets/img/options/container/arrowOnly.png")}
                    ></img>

                    <div className="vertical one"></div>

                    <img
                      className="verticalArrowIco two"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>
                    <img
                      className="verticalArrowIco twoOnly"
                      src={require("../../../../../Assets/img/options/container/arrowOnly.png")}
                    ></img>

                    <div className="vertical two"></div>

                    <img
                      className="verticalArrowIco three"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>
                    <img
                      className="verticalArrowIco threeOnly"
                      src={require("../../../../../Assets/img/options/container/arrowOnly.png")}
                    ></img>

                    <div className="vertical three"></div>

                    <img
                      className="verticalArrowIco four"
                      src={require("../../../../../Assets/img/options/container/arrow.png")}
                    ></img>
                    <img
                      className="verticalArrowIco fourOnly"
                      src={require("../../../../../Assets/img/options/container/arrowOnly.png")}
                    ></img>
                  </div>
                  <div className="advancedMultiLabel">
                    {item.label} <span>{item.subtext}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default AlignItems;
