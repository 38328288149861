import React, { useState } from "react";
import OptionItem, {
  OptionToggle1,
} from "../../../../../Components/etc/OptionItem";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import JustifyContent from "./JustifyContent";
import AlignItems from "./AlignItems";
import Background from "./Background";
const ContainerAppearance = (props) => {
  const [hover, setHover] = useState(false);
  const [popup, setPopup] = useState(false);

  const _styles = hover ? props.hover.value || {} : props.value || {};
  const styles = hover ? { ...(props.value || {}), ..._styles } : _styles;
  const mergeStyle = (obj) =>
    (hover ? props.hover.onChange : props.onChange)({ ..._styles, ...obj });

  const flexDirection = styles.flexDirection;

  const optionHeadTabs = (
    <>
      <div className="bgSetButton" onClick={() => setPopup(!popup)}>+</div>

      <ToggleButton
        classNames={{
          container: "optionHeadTabs headTabsDark",
          item: "optionHeadTabItem",
        }}
        overideDefaultStyle
        value={styles.flex || null}
        onChange={(x) =>
          mergeStyle(
            x
              ? {
                  flex: x,
                  width: null,
                  height: null,
                }
              : { flex: null }
          )
        }
        options={[
          { value: null, label: "Custom" },
          { value: 1, label: "fill" },
        ]}
      />
    </>
  );

  const hoverSelector = (
    <div
      className={"hover-selector " + (hover ? "active" : "")}
      onClick={() => setHover(!hover)}
    >
      H
    </div>
  );

  return (
    <>
      <OptionItem
        optionItemLabel={"Appearance"}
        optionHeadTabs={optionHeadTabs}
        optionHeadRight={hoverSelector}
        key={hover}
      >
        <div className="optionItemBody revealOffset">
          {styles.flex !== 1 ? (
            <div className="optionItemRow">
              <div className="optionItemBox">
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ width: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    value={styles.width || ""}
                    placeholder="Auto"
                  />
                </div>
              </div>
              <div className="optionItemBox">
                <div className="optionInputIconBox">
                  <img
                    className="optionInputIconBoxIco rotate90"
                    src={require("../../../../../Assets/img/options/container/widthIco.png")}
                    alt="width"
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ height: event.target.value })
                    }
                    className="optionInputIconBoxField"
                    value={styles.height || ""}
                    placeholder="Auto"
                  />
                </div>
              </div>
            </div>
          ) : null}
          <OptionToggle1
            value={flexDirection}
            onChange={(item) => mergeStyle({ flexDirection: item.value })}
            options={[
              { value: "column", label: "Vertical" },
              { value: "row", label: "Horizontal" },
            ]}
          />
        </div>
        <div className="optionItemHead pad5t containerDirectionLabel">
          <div className="optionItemLabels">Horizontal Orientation</div>
        </div>

        <JustifyContent {...props} />

        <div className="optionItemHead pad5t containerDirectionLabel">
          <div className="optionItemLabels">Vertical Orientation</div>
        </div>

        <AlignItems {...props} />
      </OptionItem>
      {popup ? (
        <div>
          <Background {...{ ...props, backgroundImage: true }} />{" "}
        </div>
      ) : null}
    </>
  );
};

export default ContainerAppearance;
