import React from "react";
import { connect } from "react-redux";
import CustomSelect from "../../../Components/etc/CustomSelect";

const DatabaseSelector = (props) => {
  return (
    <div className="optionConditionTableSelectorHead">
      <div className="optionConditionTableSelectorHeadSubline">Data Source</div>
      <CustomSelect
        classNames={{
          head: "optionConditionTableSelectorHeadSelect",
          label: "optionConditionTableSelectorHeadSelectLabel",
          chevron: "optionConditionTableSelectorHeadSelectIco",
        }}
        placeholder={"Select"}
        {...getDatabaseSelectorOptions(props)}
      />
    </div>
  );
};

export const getDatabaseSelectorOptions = (props) => {
  const databases = props.databases || props.defaultDatabases || [];
  const localDatabases = databases.filter((x) => x.type === "local");
  const remoteDatabases = databases.filter((x) => x.type === "remote");

  let options = [
    {
      label: "Local Database",
      options: localDatabases.map((x) => ({
        valueObj: { dbId: x._id },
        label: x.name,
        options: x.tables?.map((table) => ({
          value: `${x._id}-${table?._id}`,
          valueObj: { dbId: x._id, tableId: table?._id },
          label: table?.name,
        })),
      })),
    },
    {
      label: "Remote Database",
      options: remoteDatabases.map((x) => ({
        valueObj: { dbId: x._id },
        label: x.name,
        options: x.tables?.map((table) => ({
          value: `${x._id}-${table?._id}`,
          valueObj: { dbId: x._id, tableId: table?._id },
          label: table?.name,
        })),
      })),
    },
  ];

  if (props.externalApi || props.webrtcStreams) {
    options.push({
      label: "Other",
      options: [
        props.externalApi && {
          value: "externalApi-",
          valueObj: { dbId: "externalApi", tableId: "" },
          label: "External API",
        },
        props.externalApi && {
          value: "staticValues-",
          valueObj: { dbId: "staticValues", tableId: "" },
          label: "Comma Separated Value",
        },
        props.webrtcStreams && {
          value: "webrtcStreams-",
          valueObj: { dbId: "webrtcStreams", tableId: "" },
          label: "Webrtc Streams",
        },
      ].filter((x) => x),
    });
  }

  return {
    options,
    value: `${props.value?.dbId || ""}-${props.value?.tableId || ""}`,
    onChange: (option) => props.onChange(option.valueObj),
  };
};

export default connect((state) => ({
  defaultDatabases: state.vState.BUILDER.databases,
}))(DatabaseSelector);
