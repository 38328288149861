import dateTimeModule from "./datetime.js";

var placeAutoCompleteCache = {};
var reverseGeocode = {};

const valueTypes = [
  {
    label: "Current Table Name",
    value: "currentTableName",
    outputType: "string",
    availableFor: ["backend"],
    category: "database",
  },
  {
    label: "Value: Affected Column",
    placeholder: "Enter column name",
    value: "valueOfAffectedColumn",
    outputType: "any",
    availableFor: ["backend"],
    category: "database",
  },
  {
    label: "Value of Column",
    socketEvent: "valueOfColumn",
    value: "tableSelector-valueOfColumn",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 1,
      maxNoOfInputs: 1,
    },
    outputType: "any",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Values of Column",
    socketEvent: "valuesOfColumnAndWholeRow",
    value: "tableSelector-valuesOfColumn",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 1,
      maxNoOfInputs: 1,
    },
    outputType: "valueList",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Number of Rows",
    socketEvent: "numberOfRows",
    value: "tableSelector-numberOfRows",
    tableSelector: {
      additionalInput: false,
      minNoOfInputs: 0,
      maxNoOfInputs: 0,
    },
    outputType: "number",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Value Exists in Column(s)",
    socketEvent: "existsInColumns",
    value: "tableSelector-existsInColumns",
    tableSelector: {
      additionalInput: false,
      minNoOfInputs: 0,
      maxNoOfInputs: 0,
    },
    outputType: "boolean",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Sum of Columns",
    socketEvent: "sumOfColumns",
    value: "tableSelector-sumOfColumns",
    operator: " + ",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 2,
      maxNoOfInputs: Infinity,
    },
    outputType: "number",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Multiplication of Columns",
    socketEvent: "multiplicationOfColumns",
    value: "tableSelector-multiplicationOfColumns",
    operator: " * ",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 2,
      maxNoOfInputs: Infinity,
    },
    outputType: "number",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Division of Columns",
    socketEvent: "divisionOfColumns",
    value: "tableSelector-divisionOfColumns",
    operator: " / ",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 2,
      maxNoOfInputs: Infinity,
    },
    outputType: "number",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Subtraction of Columns",
    socketEvent: "substractionOfColumns",
    value: "tableSelector-substractionOfColumns",
    operator: " - ",
    tableSelector: {
      additionalInput: true,
      minNoOfInputs: 2,
      maxNoOfInputs: Infinity,
    },
    outputType: "number",
    availableFor: ["backend", "front"],
    category: "database",
  },
  {
    label: "Value from API",
    value: "valueFromApi",
    outputType: "any",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "apiConnect",
  },
  {
    label: "Get Container API Response",
    value: "getApiResponse",
    outputType: "any",
    availableFor: ["front"],
    category: "apiConnect",
  },
  {
    label: "Current Time",
    value: "currentTime",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Current Date",
    value: "currentDate",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Current Date & Time",
    value: "timestamp",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Screen Width",
    value: "screenWidth",
    outputType: "number",
    availableFor: ["front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Screen Height",
    value: "screenHeight",
    outputType: "number",
    availableFor: ["front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Device Model",
    value: "deviceModal",
    outputType: "string",
    availableFor: ["front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Device Version",
    value: "deviceVersion",
    outputType: "string",
    availableFor: ["front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Styles of element #",
    value: "stylesOfElementSelector",
    outputType: "boolean",
    availableFor: ["front"],
    category: "information",
  },
  {
    label: "Miles Between Location",
    value: "distanceBetweenLocation",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Time Between Location",
    value: "travelTimeBetweenLocation",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Random Value",
    value: "randomValue",
    outputType: "string",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },

  {
    label: "Value: Time",
    value: "customTime",
    outputType: "string",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Date",
    value: "customDate",
    outputType: "string",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Timestamp",
    value: "customTimestamp",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Number",
    value: "customNumber",
    outputType: "number",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Text",
    value: "customText",
    outputType: "any",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Input",
    value: "valueOfInputElement",
    outputType: "any",
    availableFor: ["front"],
    category: "inputValue",
  },
  {
    label: "Value: Element",
    value: "valueOfElement",
    outputType: "any",
    availableFor: ["front"],
    category: "inputValue",
  },
  {
    label: "Value: Repeating id or order",
    value: "valueOfRepeatingContainerRow",
    outputType: "any",
    availableFor: ["front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Passed Parameter",
    value: "passedParameter",
    outputType: "any",
    availableFor: ["front", "emailTemplate"],
    category: "Parameter",
  },

  // new builder value types
  {
    label: "Value: Text",
    value: "textParts",
    outputType: "any",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "inputValue",
  },
  {
    label: "Value: Database",
    value: "databaseValue",
    outputType: "any",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "database",
  },
  {
    label: "Date & Time",
    value: "dateAndTime",
    outputType: "any",
    availableFor: ["backend", "front", "emailTemplate"],
    category: "information",
  },
  {
    label: "Screen Information",
    value: "screenInformation",
    outputType: "any",
    availableFor: ["front", "emailTemplate"],
  },
  {
    label: "Device Information",
    value: "deviceInformation",
    outputType: "any",
    availableFor: ["front", "emailTemplate"],
  },
];

export const quickSelectionValueTypes = [
  {
    label: "New Record ID",
    value: "recordId",
    availableFor: ["backend", "front"],
    isAvailable: (props) => !!props?.recordId,
  },
  {
    label: "Current Value",
    value: "currentValue",
    availableFor: ["backend", "front"],
    isAvailable: (props) => !!props?.editRecord,
  },
  {
    label: "Custom Text",
    value: "customText",
    availableFor: ["backend", "front"],
    isAvailable: (props) => !props?.locationSelector,
  },
  {
    label: "Custom Address",
    value: "customAddress",
    availableFor: ["backend", "front"],
    isAvailable: (props) => props?.locationSelector,
  },
  {
    label: "Passed Parameter",
    value: "passedParameter",
    availableFor: ["front"],
  },
  {
    label: "Input Value",
    value: "valueOfInputElement",
    availableFor: ["front"],
  },
  {
    label: "Calculation",
    value: "calculation",
    availableFor: ["backend", "front"],
  },
  {
    label: "Calculation Input",
    value: "calculationInput",
    availableFor: ["backend", "front"],
    isAvailable: (props) => !!props?.calculationInputs,
  },
];

export class ValueTypes {
  constructor({ availableProps }) {
    this.availableProps = availableProps;
    this.valueTypes = valueTypes;
    this.quickSelectionValueTypes = quickSelectionValueTypes;
  }

  evalFunctions = {
    currentTableName: async (valueObj = {}, options) => {
      return { value: options?.tableName };
    },
    valueOfAffectedColumn: async (valueObj = {}, options) => {
      const columnName = valueObj.valueOfAffectedColumn;
      return { value: options?.record?.[columnName] };
    },
    databaseValue: async (valueObj = {}, options = {}) => {
      return this.databaseValueEvalFunctions[valueObj.dbData?.action](
        valueObj,
        options
      );
    },
    "tableSelector-valueOfColumn": async (valueObj = {}, options = {}) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      const row = socketData?.data?.[0];
      const column = valueObj.dbData?.columns?.[0];
      const valueOfColumn = row?.[column];

      return {
        value: valueOfColumn,
        data: socketData,
      };
    },
    "tableSelector-valuesOfColumn": async (valueObj = {}, options = {}) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        limit: 100,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);
      const rows = socketData?.data;
      const column = valueObj.dbData?.columns?.[0];
      const valuesOfColumn = rows
        .map((x) => ({
          value: x?.[column],
          row: x,
          _id: x._id,
        }))
        .filter((x) => x.value !== null);

      return {
        value: valuesOfColumn,
        data: socketData,
      };
    },
    "tableSelector-numberOfRows": async (valueObj = {}, options = {}) => {
      const dbData = {
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        skip: 0,
        limit: 0,
        countOnly: true,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      return { value: socketData?.data };
    },
    "tableSelector-existsInColumns": async (valueObj = {}, options = {}) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      return { value: !!socketData?.data?.length, data: socketData };
    },
    "tableSelector-sumOfColumns": async (valueObj = {}, options = {}) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      const row = socketData?.data?.[0];
      const column0 = valueObj.dbData?.columns?.[0];
      const column1 = valueObj.dbData?.columns?.[1];
      const sumOfColumns =
        parseFloat(row?.[column0]) + parseFloat(row?.[column1]);

      return {
        value: sumOfColumns,
        data: socketData,
      };
    },
    "tableSelector-multiplicationOfColumns": async (
      valueObj = {},
      options = {}
    ) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      const row = socketData?.data?.[0];
      const column0 = valueObj.dbData?.columns?.[0];
      const column1 = valueObj.dbData?.columns?.[1];
      const multiplicationOfColumns =
        parseFloat(row?.[column0]) * parseFloat(row?.[column1]);

      return {
        value: multiplicationOfColumns,
        data: socketData,
      };
    },
    "tableSelector-divisionOfColumns": async (valueObj = {}, options = {}) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      const row = socketData?.data?.[0];
      const column0 = valueObj.dbData?.columns?.[0];
      const column1 = valueObj.dbData?.columns?.[1];
      const divisionOfColumns =
        parseFloat(row?.[column0]) / parseFloat(row?.[column1]);

      return {
        value: divisionOfColumns,
        data: socketData,
      };
    },
    "tableSelector-substractionOfColumns": async (
      valueObj = {},
      options = {}
    ) => {
      const dbData = {
        skip: 0,
        sortby: "_id",
        order: -1,
        ...(valueObj?.dbData || {}),
        filters: await this.availableProps.evalFilters(
          valueObj?.dbData?.filters,
          options
        ),
        limit: 1,
      };

      const socketData = await this.availableProps.databaseModule.read(dbData);

      const row = socketData?.data?.[0];
      const column0 = valueObj.dbData?.columns?.[0];
      const column1 = valueObj.dbData?.columns?.[1];
      const substractionOfColumns =
        parseFloat(row?.[column0]) - parseFloat(row?.[column1]);

      return {
        value: substractionOfColumns,
        data: socketData,
      };
    },
    valueFromApi: async (valueObj = {}, options = {}) => {
      if (valueObj.valueFromApi?.action === "prevApiRequest") {
        const apiData = {
          ...valueObj.valueFromApi,
          sourceRequestId: (
            await this.availableProps.evaluateQuickSelectionValue(
              valueObj.valueFromApi.sourceRequestId
            )
          )?.value,
        };
        const resultObj =
          await this.availableProps.getFromPrevExternalApiRequest(
            apiData,
            options
          );
        return { ...resultObj, value: this.toString(resultObj.value) };
      } else {
        const resultObj = await this.availableProps.executeExternalApiRequest(
          valueObj?.valueFromApi || {},
          options
        );
        return { ...resultObj, value: this.toString(resultObj.value) };
      }
    },
    getApiResponse: async (valueObj = {}, options = {}) => {
      const data = valueObj.getApiResponse || {};
      const elementData = this.availableProps.dataStore.getClosestElement({
        targetElementId: data.element?.id,
        getterIndices: this.availableProps.indices,
        getterRowIndices: this.availableProps.rowIndices,
      });

      if (elementData?.elementId)
        this.availableProps.setDependentElements({
          id: elementData.elementId,
          ...elementData,
        });

      const apiResult = elementData?.apiResult;

      return {
        value: this.getNestedJsonValue(apiResult, data.jsonKeyPath),
      };
    },
    currentTime: async () => {
      const now = new Date();

      const hoursInMilliseconds = now.getHours() * 60 * 60 * 1000;
      const minutesInMilliseconds = now.getMinutes() * 60 * 1000;
      const secondsInMilliseconds = now.getSeconds() * 1000;
      const milliseconds = now.getMilliseconds();

      const currentTimeInMilliseconds =
        hoursInMilliseconds +
        minutesInMilliseconds +
        secondsInMilliseconds +
        milliseconds;

      return { value: currentTimeInMilliseconds };
    },
    currentDate: async () => ({
      value: new Date(Date.now()).setHours(0, 0, 0, 0),
    }),
    timestamp: async () => ({ value: Date.now() }),
    screenWidth: async () => ({
      value: await this.availableProps.utils.getScreenWidth?.(),
    }),
    screenHeight: async () => ({
      value: await this.availableProps.utils.getScreenHeight?.(),
    }),
    deviceModal: async () => ({
      value: await this.availableProps.utils.getDeviceModal?.(),
    }),
    deviceVersion: async () => ({
      value: await this.availableProps.utils.getDeviceVersion?.(),
    }),
    stylesOfElementSelector: async (valueObj) => {
      const data = valueObj?.stylesOfElementSelector || {};
      const { elementId, styles: providedStyles } = data;

      const elementData = this.availableProps.dataStore.getClosestElement({
        targetElementId: elementId,
        getterIndices: this.availableProps.indices,
        getterRowIndices: this.availableProps.rowIndices,
      });

      const elementStyles =
        elementData?.activeTab?.styleData?.[elementData?.elementType]?.[
          await this.availableProps.utils.getPlatform().OS
        ];

      let result = !!elementStyles;
      for (let i = 0; result && i < providedStyles.length; i++) {
        const providedStyle = providedStyles[i];
        if (
          providedStyle?.name &&
          elementStyles[providedStyle.name]?.toString() !==
            providedStyle.value?.toString()
        )
          result = false;
      }

      return { value: result, data: { elementStyles } };
    },
    distanceBetweenLocation: async (valueObj) => {
      function degToRad(deg) {
        return deg * (Math.PI / 180);
      }

      function calculateDistance(lat1 = 0, lon1 = 0, lat2 = 0, lon2 = 0) {
        const earthRadiusMiles = 3958.8; // Radius of the Earth in miles

        // Convert latitude and longitude from degrees to radians
        const lat1Rad = degToRad(lat1);
        const lon1Rad = degToRad(lon1);
        const lat2Rad = degToRad(lat2);
        const lon2Rad = degToRad(lon2);

        // Calculate the differences between latitudes and longitudes
        const dLat = lat2Rad - lat1Rad;
        const dLon = lon2Rad - lon1Rad;

        // Haversine formula
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1Rad) *
            Math.cos(lat2Rad) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        // Calculate the distance in miles
        const distance = earthRadiusMiles * c;

        return distance;
      }

      const coordinatesP = valueObj?.locations?.map(async (location) => {
        if (location?.valueType === "customAddress") {
          return location?.valueObj?.geometry?.location;
        } else {
          const address = (
            await this.availableProps.evaluateQuickSelectionValue(location)
          )?.value;

          if (address) {
            const locationData = await this.addressStrToLocation(address);
            return locationData?.geometry?.location;
          } else return null;
        }
      });

      const coordinates = await Promise.all(coordinatesP);

      const miles = calculateDistance(
        coordinates?.[0]?.lat,
        coordinates?.[0]?.lng,
        coordinates?.[1]?.lat,
        coordinates?.[1]?.lng
      );

      return { value: parseFloat(this.formatNumber(miles)) };
    },
    travelTimeBetweenLocation: null,
    randomValue: async (valueObj) => {
      const { method, length: lenStr } = valueObj?.randomValue || {};

      const length = !lenStr || isNaN(lenStr) ? 5 : parseInt(lenStr);

      let value;

      if (method === "numeric") {
        value = Array.from({ length }, () =>
          Math.floor(Math.random() * 10)
        ).join("");
      } else if (method === "uuid") {
        value = this.availableProps.utils.uuidv4();
      } else {
        const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";
        const charactersLength = characters.length;
        for (let i = 0; i < parseInt(length); i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        value = result;
      }

      return { value };
    },
    customTime: async (valueObj) => ({
      value: valueObj?.customTime?.toString(),
    }),
    customDate: async (valueObj) => ({
      value: valueObj?.customDate?.toString(),
    }),
    customTimestamp: async (valueObj) => ({
      value: parseInt(valueObj?.customTimestamp),
    }),
    customNumber: async (valueObj) => ({
      value: parseFloat(valueObj?.customNumber),
    }),
    customText: async (valueObj) => {
      const text = valueObj?.customText?.toString() || "";
      const value = !text?.trim() || isNaN(text) ? text : parseFloat(text);
      return { value };
    },
    textParts: async (valueObj, options = {}) => {
      const result = await this.availableProps.evaluateQuickSelectionValue(
        valueObj.textParts,
        options
      );
      return result;
    },
    valueOfInputElement: (...x) => this.evalFunctions.valueOfElement(...x),
    valueOfElement: async (valueObj, options = {}) => {
      const {
        id: elementIdsStr,
        interveneGetClosest,
        repeatingContainerInfo,
      } = valueObj.element || {};
      const elementIds = elementIdsStr?.split("-");

      const elementId = elementIds?.[0];
      const restElementIds = elementIds?.filter((x, i) => !!(i && x));

      let repeatingContainerRowInfo = [];
      if (interveneGetClosest) {
        for (const containerId in repeatingContainerInfo) {
          if (Object.hasOwnProperty.call(repeatingContainerInfo, containerId)) {
            const containerRowData = repeatingContainerInfo[containerId];
            if (containerRowData?.valueObj) {
              let rowData =
                await this.availableProps.evaluateQuickSelectionValue(
                  containerRowData,
                  options
                );

              if (rowData.value) {
                if (isNaN(rowData.value)) {
                  repeatingContainerRowInfo.push({
                    containerId,
                    rowId: rowData.value.toString(),
                  });
                } else {
                  repeatingContainerRowInfo.push({
                    containerId,
                    rowIndex: parseInt(rowData.value),
                  });
                }
              }
            }
          }
        }
      }

      const dataStore = options.dataStore || this.availableProps.dataStore;

      const elementData = dataStore.getClosestElement({
        targetElementId: elementId,
        getterIndices: options.indices || this.availableProps.indices,
        getterRowIndices: options.rowIndices || this.availableProps.rowIndices,
        repeatingContainerRowInfo,
        conditionFn: options.valueOfElementConditionFn
          ? options.valueOfElementConditionFn
          : ({ elementInstance }) =>
              !["container"].includes(elementInstance?.elementType),
      });

      if (restElementIds?.length) {
        return this.evalFunctions.valueOfElement(
          {
            ...valueObj,
            element: { id: restElementIds.join("-") },
          },
          {
            ...options,
            dataStore: elementData?.dataStore,
            // indices: [],
            // rowIndices: [],
          }
        );
      } else {
        if (elementData?.elementId)
          this.availableProps.setDependentElements({
            id: elementData.elementId,
            ...elementData,
            elementDataStore: dataStore,
          });

        return {
          data: { elementData: elementData },
          value: elementData?.value,
        };
      }
    },
    valueOfRepeatingContainerRow: async (valueObj, options = {}) => {
      let processedValue = {
        elementId: valueObj.valueOfRepeatingContainerRow?.element?.id,
        mode: valueObj.valueOfRepeatingContainerRow?.mode || "rowIndex",
      };

      const promises = ["columnName", "columnValue"].map(async (key) => {
        processedValue[key] = (
          await this.availableProps.evaluateQuickSelectionValue(
            valueObj.valueOfRepeatingContainerRow?.[key] || {}
          )
        ).value;
      });

      await Promise.all(promises);

      const elmentInstances =
        this.availableProps.dataStore.data[processedValue.elementId] || {};
      const containerInstance = Object.values(elmentInstances).find(
        (x) => x?.elementType === "container" && x?.value
      );

      const value =
        containerInstance.value && JSON.parse(containerInstance.value);

      const rowIndex = value?.findIndex(
        (x) =>
          x?.[processedValue.columnName]?.toString()?.trim() ===
          processedValue.columnValue?.toString()?.trim()
      );

      const rowId = rowIndex > -1 ? value[rowIndex]?._id || rowIndex : null;

      return { value: processedValue.mode === "rowId" ? rowId : rowIndex + 1 };
    },
    passedParameter: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;
      return this.passedParamerterEvalFunctions[
        passedParameterValueObj?.sourceType
      ]?.(valueObj, options);
    },

    multipleStatic: async (valueObj = {}, options) => {
      return { value: valueObj.multipleStatic };
    },

    staticCheckbox: async (valueObj = {}, options) => {
      return { value: !!valueObj.staticCheckbox };
    },

    staticSwitch: async (valueObj = {}, options) => {
      return { value: !!valueObj.staticSwitch };
    },

    staticUrls: async (valueObj = {}, options) => {
      return {
        value: valueObj.staticUrls?.map((x) => ({
          value: x?.toString(),
          valueType: "string",
        })),
      };
    },
    staticUrl: async (valueObj = {}, options) => {
      return {
        value: valueObj.staticUrl?.toString(),
      };
    },

    staticList: async (valueObj = {}, options) => {
      const result = {
        value: valueObj.staticList
          ?.split(",")
          .map((x) => ({ value: x.trim() })),
      };

      return result;
    },

    currentLocation: async (valueObj = {}, options) => {
      return {
        valueType: "coordinate",
        value: this.availableProps.utils.getCurrentLocation(),
      };
    },

    customAddress: async (valueObj = {}, options) => {
      return {
        valueType: "location",
        value: valueObj.customAddress,
      };
    },

    dateAndTime: async (valueObj = {}, options = {}) => {
      const data = valueObj?.dateAndTime || {};

      const currentOrCustom = data.currentOrCustom || "current";
      const formatType = data.formatType || "timestamp";
      const customFormat = data.customFormat;

      let date = new Date();
      if (currentOrCustom === "custom") {
        const customValue = (
          await this.availableProps.evaluateQuickSelectionValue(
            data.datetime || {},
            options
          )
        )?.value;

        const inputFormat = (
          await this.availableProps.evaluateQuickSelectionValue(
            data.inputFormat || {},
            options
          )
        )?.value;

        const dateString = isNaN(customValue)
          ? dateTimeModule.parseDateFromString(
              customValue?.trim(),
              inputFormat?.trim()
            )
          : parseInt(customValue);

        date = dateString ? new Date(dateString) : null;
      } else {
        this.availableProps.setDependentElements({
          id: "CURRENT_TIME",
          rowIndices: [0],
          rowIds: ["DEFAULT"],
        });
      }

      let value = dateTimeModule.formatDate(date, customFormat, formatType);

      return {
        value,
        data: { date, stringType: "date", formatType, customFormat },
        valuType: "string",
      };
    },

    screenInformation: async (valueObj = {}, options = {}) => {
      const action = valueObj.screenInformation?.action;
      if (["screenWidth", "screenHeight"].includes(action))
        return this.evalFunctions[action](valueObj, options);
      else return { value: null };
    },

    deviceInformation: async (valueObj = {}, options = {}) => {
      const action = valueObj.deviceInformation?.action;
      const fns = {
        deviceModal: this.evalFunctions.deviceModal,
        deviceVersion: this.evalFunctions.deviceVersion,
        deviceId: this.passedParamerterEvalFunctions.deviceId,
        notificationToken: this.passedParamerterEvalFunctions.notificationToken,
        microphoneStatus: this.passedParamerterEvalFunctions.microphoneStatus,
        cameraStatus: this.passedParamerterEvalFunctions.cameraStatus,
        currentLocation: async (valueObj) => {
          const coordinates =
            await this.availableProps.utils.getCurrentLocation();

          let value =
            coordinates?.coords?.[
              valueObj?.deviceInformation?.coordinateValueType
            ];

          if (
            coordinates?.coords &&
            (!valueObj?.deviceInformation?.coordinateValueType ||
              valueObj?.deviceInformation?.coordinateValueType === "latAndLng")
          )
            value = [
              coordinates?.coords?.latitude,
              coordinates?.coords?.longitude,
            ].join(", ");

          this.availableProps.setDependentElements({
            id: "CURRENT_LOCATION",
            rowIndices: [0],
            rowIds: ["DEFAULT"],
          });

          return { value };
        },
      };

      if (fns[action]) return fns[action](valueObj, options);
      else return null;
    },

    // quick selections value types
    recordId: async (valueObj, options) => ({
      value: options?.recordId?.toString() || "",
    }),
    currentValue: null,
    calculationInput: null,
  };

  databaseValueEvalFunctions = {
    valueOfColumn: this.evalFunctions["tableSelector-valueOfColumn"].bind(this),
    valuesOfColumn:
      this.evalFunctions["tableSelector-valuesOfColumn"].bind(this),
    numberOfRows: this.evalFunctions["tableSelector-numberOfRows"].bind(this),
    sumOfValues: async (valueObj, options) => {
      const { value, data } = await this.evalFunctions[
        "tableSelector-valuesOfColumn"
      ](valueObj, options);

      let result = value?.[0]?.value;
      if (!isNaN(result)) result = parseFloat(result);

      for (let i = 1; i < value?.length; i++) {
        let v = value?.[i]?.value;
        if (!isNaN(v)) v = parseFloat(v);
        result = result + v;
      }

      return { value: result, data };
    },
    substractionOfValues: async (valueObj, options) => {
      const { value, data } = await this.evalFunctions[
        "tableSelector-valuesOfColumn"
      ](valueObj, options);

      let numericalValues = value
        ?.filter((x) => !isNaN(x.value))
        .map((x) => parseFloat(x.value));

      let result = numericalValues?.length ? numericalValues[0] : null;

      for (let i = 1; i < numericalValues?.length; i++) {
        let v = numericalValues?.[i];
        result = result - v;
      }

      return { value: result, data };
    },
    multiplicationOfValues: async (valueObj, options) => {
      const { value, data } = await this.evalFunctions[
        "tableSelector-valuesOfColumn"
      ](valueObj, options);

      let numericalValues = value
        ?.filter((x) => !isNaN(x.value))
        .map((x) => parseFloat(x.value));

      let result = numericalValues?.length ? numericalValues[0] : null;

      for (let i = 1; i < numericalValues?.length; i++) {
        let v = numericalValues?.[i];
        result = result * v;
      }

      return { value: result, data };
    },
    divisionOfValues: async (valueObj, options) => {
      const { value, data } = await this.evalFunctions[
        "tableSelector-valuesOfColumn"
      ](valueObj, options);

      let numericalValues = value
        ?.filter((x) => !isNaN(x.value))
        .map((x) => parseFloat(x.value));

      let result = numericalValues?.length ? numericalValues[0] : null;

      for (let i = 1; i < numericalValues?.length; i++) {
        let v = numericalValues?.[i];
        result = result / v;
      }

      return { value: result, data };
    },
  };

  passedParamerterEvalFunctions = {
    deviceId: async () => {
      return { value: await this.availableProps.utils.getDeviceId() };
    },
    repeatingContainer: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;

      let passedParameter = (
        options?.passedParameters || this.availableProps?.passedParameters
      )?.find(
        (x) =>
          x.sourceType === passedParameterValueObj.sourceType &&
          x.elementId === passedParameterValueObj.elementId
      );

      return {
        data: { passedParameter },
        value:
          passedParameter?.repeatingContainer?.row?.[
            passedParameterValueObj?.column
          ],
      };
    },
    dataGroup: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;

      let passedParameter = (
        options?.passedParameters || this.availableProps?.passedParameters
      )?.find(
        (x) =>
          x.sourceType === passedParameterValueObj.sourceType &&
          x.groupId === passedParameterValueObj.groupId
      );

      return {
        data: { passedParameter },
        value: passedParameter?.data[passedParameterValueObj?.optionValue],
      };
    },
    repeatingMapMarker: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;

      const column = passedParameterValueObj?.column;
      const data = options?.locationMark?.row;

      return {
        data,
        value: data?.[column],
      };
    },
    notificationToken: async () => {
      return { value: await this.availableProps.utils.getNotificationToken() };
    },
    containerTabs: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;

      let passedParameter = (
        options?.passedParameters || this.availableProps?.passedParameters
      )?.find(
        (x) =>
          x.sourceType === passedParameterValueObj.sourceType &&
          x.elementId === passedParameterValueObj.elementId
      );

      if (passedParameter?.elementId) {
        this.availableProps.setDependentElements({
          id: passedParameter.elementId,
          ...passedParameter,
        });
      }

      return {
        data: { passedParameter },
        value:
          (passedParameter?.containerTabs?.tabStatus || "inactive") ===
          passedParameterValueObj.tabStatus,
      };
    },
    microphoneStatus: async () => {
      this.availableProps.setDependentElements({
        id: "MICROPHONE_MUTE_STATUS",
        rowIndices: [0],
        rowIds: ["DEFAULT"],
      });

      const isMuted = (await this.availableProps.utils.getState()).vState.APP
        ?.isMuted;

      const value = isMuted ? "mute" : "unmute";
      return { value };
    },
    cameraStatus: async () => {
      this.availableProps.setDependentElements({
        id: "CAMERA_DISABLE_STATUS",
        rowIndices: [0],
        rowIds: ["DEFAULT"],
      });

      const isCameraDisabled = (await this.availableProps.utils.getState())
        .vState.APP?.isCameraDisabled;

      const value = isCameraDisabled ? "off" : "on";
      return { value };
    },
    urlParam: async (valueObj = {}, options) => {
      const passedParameterValueObj = valueObj.passedParameter?.valueObj;
      const urlParamName = passedParameterValueObj.urlParamName;

      let passedParameter = (
        options?.passedParameters || this.availableProps?.passedParameters
      )?.find(
        (x) =>
          x.sourceType === passedParameterValueObj.sourceType &&
          x.urlParamName === urlParamName
      );

      this.availableProps.setDependentElements({
        id: "urlParam",
        urlParamName,
      });

      return {
        data: { passedParameter },
        value: passedParameter?.value,
      };
    },
  };

  getNestedJsonValue(obj, path) {
    if (!path || !path.trim()) return obj;
    return path
      .split(".")
      .reduce((acc, key) => (acc ? acc[key] : undefined), obj);
  }

  async addressStrToLocation(str) {
    const API_SESSION_TIMEOUT_SECOND = 60;

    if (
      str &&
      str.match(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d(\.\d+)?|180(\.0+)?)|\d{1,2}(\.\d+)?)(?=\s|$)/gm
      )
    ) {
      const coordinates = str.split(",").map((x) => parseFloat(x));
      return {
        geometry: {
          location: {
            lat: coordinates[0],
            lng: coordinates[1],
          },
        },
      };
    } else if (str && typeof str === "string") {
      if (
        !this.addressStrToLocationSession?.expires ||
        this.addressStrToLocationSession?.expires < Date.now()
      ) {
        this.addressStrToLocationSession = {
          token: this.availableProps.utils.uuidv4(),
          expires: Date.now() + API_SESSION_TIMEOUT_SECOND * 1000,
        };
      }

      const suggestions = await this.placeAutoComplete({
        input: str,
        sessiontoken: this.addressStrToLocationSession.token,
      })
        .then((data) => {
          if (!data || data.status !== "OK" || !data.predictions) return;

          return data.predictions;
        })
        .catch((e) => {
          console.warn("Error in googleAutocomplete", e);
          throw e;
        });

      const prediction = suggestions?.find((x) => x.place_id);

      const location =
        prediction &&
        (await this.reverseGeocode(prediction.place_id)
          .then((data) => {
            return {
              description: prediction.description,
              place_id: prediction.place_id,
              lat: data.geometry?.location?.lat,
              lng: data.geometry?.location?.lng,
              ...data,
            };
          })
          .catch((e) => {
            console.warn("Error in reverseGeocode", e);
            throw e;
          }));

      return location;
    } else {
      return null;
    }
  }

  async placeAutoComplete({ input, sessiontoken }) {
    if (placeAutoCompleteCache[input]) {
      return placeAutoCompleteCache[input];
    } else {
      return this.availableProps.api
        .post("v1/app/places/placesAutoComplete", {
          input,
          sessiontoken,
        })
        .then((data) => {
          if (data && data.status === "OK" && data.predictions) {
            placeAutoCompleteCache[input] = data;
          }

          return data;
        });
    }
  }

  async reverseGeocode(place_id) {
    if (reverseGeocode[place_id]) {
      return reverseGeocode[place_id];
    } else {
      return this.availableProps.api
        .post("v1/app/places/reverseGeocode", {
          place_id,
        })
        .then((data) => {
          if (data?.geometry?.location) {
            reverseGeocode[place_id] = data;
          }

          return data;
        });
    }
  }

  formatNumber(value) {
    if (typeof value !== "number") return value;
    const roundedValue = parseFloat(value.toFixed(2)); // Round to 2 decimal places and convert back to float
    return roundedValue % 1 === 0
      ? roundedValue.toString()
      : roundedValue.toString();
  }

  toString(value) {
    if (typeof value === "object") {
      if (value instanceof Array) {
        return value.map((x) => this.toString(x)).join(", ");
      } else {
        return JSON.stringify(value, null, 4);
      }
    } else {
      return value?.toString?.() || "";
    }
  }
}

export default valueTypes;
