import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import withRouter from "../../Components/Wrapper/with-router";
import NavBar from "../Common/NavBar";
import api from "../../Services/Api/api";
import SoruceElement from "./SoruceElement";
import Canvas from "./Canvas";
import Hierarchy from "./Hierarchy";
import SaveComponent from "./ActionButtons.js/SaveComponent";
import { ShowPaymentMethodBtn } from "./ActionButtons.js/PaymentMethods";
import { ShowCredentialBtn } from "./ActionButtons.js/ThirdPartyCredentials";
import Logout from "./ActionButtons.js/Logout";
import config from "../../Config";
import ElementProperties from "./Properties/ElementProperties";
import { ShowTriggerBtn } from "../Common/Triggers/Triggers";
import defaultStyles from "../../appxolo-engine/modules/styling/defaultStyles";

import sourceElements from "../../appxolo-engine/modules/source-elements/source-elements";
import _dom from "../../appxolo-engine/modules/dom/Dom";
import { ShowRtcLoggerBtn } from "../Common/RtcLogger";
import { ShowOnlineDeviceLoggerBtn } from "../Common/OnlineDeviceLogger";
import DatabasePanel from "../DatabasePanel/DatabasePanel";
import ChangeLog from "./ChangeLog";
import UserPresence from "./UserPresence";
import { socketService } from "../../Services/Socket/socketListeners";

const { DomTree, DomTreeNode } = _dom;

class BuilderInner extends React.Component {
  constructor(props) {
    super(props);
    this.sourceElements = this.prepareSourceElements(sourceElements);
  }

  state = {
    error: null,
    loading: false,
    component: null,
    ts: Date.now(),
    showStylePanel: process.env.REACT_APP_DEVELOPER !== "bis",
  };

  componentDidMount() {
    this.load();

    socketService.joinSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    this.componentReloadSocketSubId = socketService.subscribeSocketEvent(
      "v1/component/reload",
      (data) => {
        if (data.component.toString() === this.props.componentId?.toString()) {
          this.load();
        }
      }
    );
  }

  componentWillUnmount() {
    socketService.leavSocketRoom(
      `v1/component/reload/${this.props.componentId}`
    );

    socketService.unsubscribeSocketEvent(
      "v1/component/reload",
      this.componentReloadSocketSubId
    );
  }

  triggerRenderTimer = null;
  triggerRender() {
    clearTimeout(this.triggerRenderTimer);
    this.triggerRenderTimer = setTimeout(() => {
      this.setState({ ts: Date.now() });
    }, 50);
  }

  prepareSourceElements(sourceElements) {
    return sourceElements.map((sourceElement) => ({
      ...sourceElement,
      data: {
        ...sourceElement.data,
        tabs: (sourceElement?.data?.tabs || []).map((tab) => ({
          ...tab,
          styleData: defaultStyles[sourceElement.elementType],
        })),
      },
    }));
  }

  async load() {
    try {
      if (this.state.loading) return;

      this.dom = null;
      this.props.setScreenState({
        project: null,
        components: null,
      });

      this.setState({ error: null, loading: true });
      const { project, component, components, databases } = await api.get(
        "v1/project/builder-data",
        { component: this.props.componentId }
      );
      // console.log({ project, component, components });

      const domTreeNode = new DomTreeNode(
        "ROOT",
        { elementType: "container" },
        component?.data?.dom?.children
      );

      const domTree = new DomTree(domTreeNode, this.triggerRender.bind(this));
      this.dom = domTree;

      this.setState({ loading: false, component });
      this.props.setScreenState({ project, components, databases });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      toast.error(e.message, {
        hideProgressBar: true,
        pauseOnHover: true,
      });
    }
  }

  render() {
    const {
      props: { components, allBuilderData, setScreenState, project, databases },
      state: { loading, ts, component },
      dom,
      sourceElements,
    } = this;

    const builderData = _.pick(allBuilderData, [
      "hoverPosition",
      "hoverIndex",
      "focusedElement",
      "hoveredIndices",
    ]);

    const commonPropsToPass = {
      project,
      dom,
      builderData,
      setBuilderData: (x) => {
        setScreenState(x);
      },
      triggerRender: this.triggerRender.bind(this),
      ts,
      availableFor: "front",
      databases,
      component,
      components,
    };

    const leftPanel = (
      <div className="builderPane">
        <div className="builderPanel">
          <div className="builderTools">
            <div className="builderElementsLabel">ELEMENTS</div>
            <div className="builderElements">
              {sourceElements.map((item) => (
                <SoruceElement
                  {...commonPropsToPass}
                  key={item.elementType}
                  item={item}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="builderPanel">
          <div className="builderScreens">
            <div className="card-body" style={{ padding: "4px" }}>
              <div style={{}} className="card-title">
                Screens
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {components?.map((item) => {
                  return (
                    <Link
                      key={item._id}
                      to={"/canvas/" + item._id}
                      className="card"
                      style={Object.assign(
                        {},
                        {
                          width: "50%",
                          fontSize: "small",
                          textDecoration: "none",
                          color: "unset",
                        },
                        item._id === component?._id
                          ? {
                              background: "#ddffdd",
                            }
                          : {}
                      )}
                    >
                      <div
                        className="card-text"
                        style={{
                          padding: "4px",
                        }}
                      >
                        {item.name}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <UserPresence {...commonPropsToPass} />

        <div className="builderPanel">
          <div className="builderActions">
            <div className="card-body">
              <ShowPaymentMethodBtn
                filter={{
                  where: { project: project?._id },
                }}
              />
              <ShowCredentialBtn
                filter={{
                  where: { project: project?._id },
                }}
              />
              <ShowTriggerBtn {...{ project, databases, dom }} />
              <ShowRtcLoggerBtn {...{ project, databases, dom }} />
              <ShowOnlineDeviceLoggerBtn {...{ project, databases, dom }} />
              <Link to={`/database?projectId=${project?._id}`}>
                <button>Database Viewer</button>
              </Link>

              <Logout />
            </div>
          </div>
        </div>

        <div className="builderPanel">
          <div className="builderHierarchy">
            <Hierarchy {...commonPropsToPass} />
          </div>
        </div>

        {commonPropsToPass?.component?._id ? (
          <div>
            <ChangeLog
              {...commonPropsToPass}
              reload={() => {
                this.load().then(this.triggerRender.bind(this));
              }}
            />
          </div>
        ) : null}
      </div>
    );

    const centerPanel = (
      <div className="builderCanvas">
        <Canvas {...commonPropsToPass} />
        <DatabasePanel />

        <div className="modalWrapper">
          <div className="modalItem">
            <div className="detailScreen">
              <div className="pageContainer">
                <div className="sideMenu">
                  <div className="sideMenuLogo">Voice Relayer</div>
                  <div className="sideMenuItem active">Details</div>
                  <div className="sideMenuItem">Members</div>
                  <div className="sideMenuItem">Resources</div>
                  <div className="sideMenuItem">Triggers</div>
                  <div className="sideMenuItem">Backups</div>
                  <div className="sideMenuItem">Integrations</div>
                  <div className="sideMenuItem">Email Templates</div>
                </div>
                <div className="mainContainer displayNone">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Details</div>
                      <span>View and manage your project</span>
                    </div>

                    <div className="contentBody">
                      <div className="tLabel mb10">Information</div>

                      <div className="borderBox mb20">
                        <div className="projectIco">
                          <img
                            src={require("../../Assets/img/options/project/projectIco.jpeg")}
                          ></img>
                          <div className="overlayChange">
                            <div className="minLightButton">Change</div>
                          </div>
                        </div>

                        <div className="">
                          <div className="borderItem">
                            <div className="mLabel">App Name</div>
                            <input
                              type="text"
                              className="mInput"
                              placeholder="App Name"
                              defaultValue="Voice Relayer"
                            />

                            <div className="minActionRow minAdjust">
                              <div className="minButton">Save</div>
                            </div>
                          </div>

                          <div className="borderItem">
                            <div className="mLabel">App Type</div>
                            <div className="mValue">Mobile Application</div>
                          </div>
                        </div>
                      </div>

                      <div className="tLabel mb10">Status</div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">iOS App</div>
                          <div className="publishRow">
                            <div className="mValue">Unpublished</div>
                            <div className="publishAction">Apply</div>
                          </div>

                          <div className="publishDetails">
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">iOS App Name</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder="App Name"
                                />
                              </div>
                            </div>

                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">App ID</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                              <div className="pbItem">
                                <div className="pbLabel">Bundle Code</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">
                                  Camera permission text
                                </div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">
                                  Microphone permission text
                                </div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className="minActionRow">
                              <div className="minButton">Submit</div>
                              <div className="minLightButton">Cancel</div>
                            </div>
                          </div>
                        </div>
                        <div className="borderItem">
                          <div className="mLabel">Android App</div>
                          <div className="publishRow">
                            <div className="mValue">
                              Published on <span>04/12/2024 3:22pm</span>
                            </div>
                            <div className="publishAction">Republish</div>
                          </div>
                        </div>
                      </div>

                      <div className="tLabel mb10">Status</div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">Staging subdomain</div>
                          <div className="publishRow">
                            <div className="mValue">
                              https://voicerelayer.appwolo.com
                            </div>
                            <div className="publishAction">Change</div>
                          </div>

                          <div className="publishDetails">
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">Staging Subdomain</div>
                                <div className="pbDomainRow">
                                  <input
                                    type="text"
                                    className="mInput"
                                    placeholder="subdomain"
                                  />
                                  <div className="pbDomain">.appxolo.com</div>
                                </div>
                                <div className="domainLabel mt10 success">
                                  Subdomain is available
                                </div>
                              </div>
                            </div>

                            <div className="minActionRow">
                              <div className="minButton">Submit</div>
                              <div className="minLightButton">Cancel</div>
                            </div>
                          </div>
                        </div>
                        <div className="borderItem">
                          <div className="mLabel">Production domain</div>
                          <div className="publishRow">
                            <div className="mValue">
                              https://wwww.voicerelayer.com
                            </div>
                            <div className="publishAction">Publish</div>
                          </div>

                          <div className="publishDetails">
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">Domain Name</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder="http://example.com"
                                />
                              </div>
                            </div>

                            <div className="minActionRow">
                              <div className="minButton">Check Connection</div>
                              <div class="domainLabel fail">
                                Connection failed.
                              </div>
                            </div>
                          </div>

                          <div className="publishDetails">
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">A Record</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  value="23.56.35.72"
                                />
                              </div>
                            </div>
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">CNAME</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  value="appxolo"
                                />
                              </div>
                            </div>
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">TXT</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  value="g7df78d87g98sf"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tLabel mb10">Details</div>
                      <div className="borderBox mb20">
                        <div className="">
                          <div className="borderItem">
                            <div className="mLabel">Screens</div>
                            <div className="mValue">12</div>
                          </div>

                          <div className="borderItem">
                            <div className="mLabel">App Description</div>
                            <div className="mValue">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat.
                            </div>
                          </div>

                          <div className="borderItem">
                            <div className="mLabel">Action</div>
                            <div className="minActionRow jStart">
                              <div className="minButton mbGray">
                                Transfer App
                              </div>
                              <div className="minButton mbRed">Delete App</div>
                            </div>

                            <div className="publishDetails">
                              <div className="pbRow">
                                <div className="pbItem">
                                  <div className="pbLabel">Send to:</div>
                                  <input
                                    type="text"
                                    className="mInput"
                                    placeholder="example@gmail.com"
                                  />
                                </div>
                              </div>
                              <div className="minActionRow">
                                <div className="minButton">Submit</div>
                                <div className="minLightButton">Cancel</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer displayNone">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Members</div>
                      <span>View and manage project members</span>
                    </div>

                    <div className="contentBody">
                      <div className="tLabel mb10">Members</div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">Project users</div>
                          <input
                            type="text"
                            className="teamSearch"
                            placeholder="Search users"
                          />

                          <div className="mLabel mt10">1 member</div>

                          <div className="teamItem">
                            <div className="teamContext">
                              <div className="teamIco">AM</div>
                              <div className="teamDetails">
                                <div className="teamName">Adam Smith</div>
                                <div className="teamSubline">
                                  adam.smith@gmail.com
                                </div>
                              </div>
                            </div>
                            <div className="teamRemove">
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                          <div className="teamItem">
                            <div className="teamContext">
                              <div className="teamIco">JL</div>
                              <div className="teamDetails">
                                <div className="teamName">Jessica Louise</div>
                                <div className="teamSubline">
                                  jessica.louise@gmail.com
                                </div>
                              </div>
                            </div>
                            <div className="teamRemove">
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer displayNone">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Resources</div>
                      <span>View your resource usage</span>
                    </div>

                    <div className="contentBody">
                      <div className="tLabel mb10">Usage</div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">Data Transfer</div>
                          <div class="usLabel">24.5 mb</div>
                        </div>
                        <div className="borderItem">
                          <div className="mLabel">Data Storage</div>
                          <div class="usLabel">4 mb</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer displayNone">
                  <div className="containerAdjust w900">
                    <div className="pageTitle">
                      <div>Project Triggers</div>
                      <span>Manage your project triggers</span>
                    </div>

                    <div className="contentBody">
                      <div className="dataTableActionRow">
                        <div className="minLightButton">
                          <div className="buttonPlus">
                            <div></div>
                            <div></div>
                          </div>
                          Add
                        </div>
                        <div className="minButton mbRed">Delete Selected</div>
                      </div>
                      <div className="dataTable removeBorder">
                        <table>
                          <thead>
                            <th>id</th>
                            <th>On</th>
                            <th>Database</th>
                            <th>Trigger</th>
                            <th>Action</th>
                            <th>Status</th>
                            <th></th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdRadio active">
                                    <div className="tdRadioDecor"></div>
                                  </div>
                                  <div className="tdID">1</div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="tdDropdown add">
                                    <div className="tdDropdownLabel">
                                      Add Record
                                    </div>
                                    <div className="tdDropdownChev">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="tdDropdown">
                                    <img
                                      className="tdDropdownIco"
                                      src={require("../../Assets/img/options/options/database.png")}
                                    ></img>
                                    <div className="tdDropdownLabel">
                                      iMessenger
                                    </div>
                                    <div className="tdDropdownChev">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  Push Notification
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div class="minActionRow">
                                    <div class="minButton">Set</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="switchInput">
                                    <div className="switchBall"></div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div class="minActionRow">
                                    <div class="removeButton">Delete</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={7} className="triggerWrapper">
                                <div className="triggerContainer">
                                  <div className="triggerCondition shadow">
                                    <div className="conditionHead active">
                                      <div className="conditionHeadContext">
                                        <div className="conditionTitle">
                                          Action
                                        </div>
                                        <div className="optionHeadTabs">
                                          <div className="optionHeadTabItem">
                                            Fixed
                                          </div>
                                          <div className="optionHeadTabItem active">
                                            Conditional
                                          </div>
                                        </div>
                                      </div>

                                      <div className="conditionAction">
                                        <div></div>
                                      </div>
                                    </div>

                                    <div className="optionCondition">
                                      <div className="optionConditionItem">
                                        <div className="optionConditionItemInner">
                                          <div className="optionConditionItemHead">
                                            <div className="optionConditionItemContext">
                                              <div className="optionConditionItemMeta">
                                                <div className="optionConditionItemMetaField">
                                                  If
                                                </div>
                                                <div className="tdDropdownChev">
                                                  <div className="one"></div>
                                                  <div className="two"></div>
                                                </div>
                                              </div>

                                              <div className="optionConditionItemValue">
                                                <div className="optionConditionItemValueField">
                                                  Column value
                                                </div>
                                                <div className="tdDropdownChev">
                                                  <div className="one"></div>
                                                  <div className="two"></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="optionConditionAdd">
                                              <div className="optionConditionAddLine"></div>
                                              <div className="optionConditionAddLine"></div>
                                            </div>
                                          </div>

                                          <div className="calcItemBody">
                                            <div className="calcItemDecor"></div>
                                            <div className="calcItemBodyMain">
                                              <div className="calcItemUnit">
                                                <div className="calcItemUnitBox">
                                                  <div className="calcItemUnitDecor"></div>
                                                  <div className="calcItemUnitMain">
                                                    <div className="calcItemUnitMainLabel">
                                                      Database Table
                                                    </div>
                                                    <div className="calcItemUnitMainDropdown">
                                                      <img
                                                        className="calcItemUnitMainDropdownIco"
                                                        src={require("../../Assets/img/options/options/database.png")}
                                                      ></img>
                                                      <div className="calcItemUnitMainDropdownLabel">
                                                        Reservations
                                                      </div>
                                                      <div className="calcItemUnitMainDropdownFooter">
                                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                                          <div className="one"></div>
                                                          <div className="two"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="calcItemUnitBox">
                                                  <div className="calcItemDatabaseFilter">
                                                    <div className="filterSet">
                                                      <div className="calcItemDatabaseFilterLabelRow">
                                                        <div className="calcItemDatabaseFilterLabel">
                                                          Filter
                                                        </div>

                                                        <div className="filterFooter">
                                                          <div className="addFilter">
                                                            <div className="one"></div>
                                                            <div className="two"></div>
                                                          </div>

                                                          <div className="removeFilter">
                                                            <div className="one"></div>
                                                            <div className="two"></div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="calcItemDatabaseFilterBox">
                                                        <div className="calcItemDatabaseFilterBoxInner">
                                                          <div className="calcItemDatabaseFilterBoxInnerRow">
                                                            <div className="calcItemUnitMainDropdown">
                                                              <div className="calcItemUnitMainDropdownLabel">
                                                                Column
                                                              </div>
                                                              <div className="calcItemUnitMainDropdownFooter">
                                                                <div className="calcItemUnitMainDropdownFooterArrow">
                                                                  <div className="one"></div>
                                                                  <div className="two"></div>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="calcItemUnitMainDropdown">
                                                              <div className="calcItemUnitMainDropdownLabel">
                                                                Equals
                                                              </div>
                                                              <div className="calcItemUnitMainDropdownFooter">
                                                                <div className="calcItemUnitMainDropdownFooterArrow">
                                                                  <div className="one"></div>
                                                                  <div className="two"></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="calcItemDatabaseFilterBoxInnerRow">
                                                            <div className="calcDatabaseFilterMultiBox">
                                                              Type here
                                                              <div className="parameterButton">
                                                                P
                                                              </div>
                                                              <div className="dbfRemove">
                                                                <div className="calculationCloseLine one"></div>
                                                                <div className="calculationCloseLine two"></div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="removeFilterButton">
                                                            Remove
                                                          </div>
                                                        </div>
                                                      </div>

                                                      {/* <div className="pad6">
                                  <div className="addFilterButton dark">
                                    Add Filter
                                  </div>
                                </div> */}
                                                    </div>

                                                    <div className="pad6">
                                                      <div className="addFilterButton dark">
                                                        Add Filter Set
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="calcItemUnitFooter">
                                                  <div className="calcItemUnitMain">
                                                    <div className="calcItemUnitMainLabel">
                                                      Action
                                                    </div>
                                                    <div className="calcItemUnitMainDropdown">
                                                      <div className="calcItemUnitMainDropdownLabel">
                                                        Value of column
                                                      </div>
                                                      <div className="calcItemUnitMainDropdownFooter">
                                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                                          <div className="one"></div>
                                                          <div className="two"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="calcItemUnitMain">
                                                    <div className="calcItemUnitMainLabel">
                                                      Select column
                                                    </div>
                                                    <div className="calcItemUnitMainDropdown">
                                                      <div className="calcItemUnitMainDropdownLabel">
                                                        Column name
                                                      </div>
                                                      <div className="calcItemUnitMainDropdownFooter">
                                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                                          <div className="one"></div>
                                                          <div className="two"></div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="optionConditionItem">
                                        <div className="optionConditionItemInner">
                                          <div className="optionConditionItemHead">
                                            <div className="optionConditionItemContext">
                                              <div className="optionConditionItemMeta">
                                                <div className="optionConditionItemMetaField">
                                                  And
                                                </div>
                                                <div className="tdDropdownChev">
                                                  <div className="one"></div>
                                                  <div className="two"></div>
                                                </div>
                                              </div>

                                              <div className="optionConditionItemValue">
                                                <div className="optionConditionItemValueField">
                                                  Number of rows
                                                </div>
                                                <div className="tdDropdownChev">
                                                  <div className="one"></div>
                                                  <div className="two"></div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="optionConditionRemove">
                                              <div className="optionConditionRemoveLine"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="triggerSeperator"></div>

                                  <div className="linkingBoxMain">
                                    <div className="linkingBoxHead">
                                      <div className="optionItemBody">
                                        <div className="linkLabel">Link to</div>

                                        <div className="linkingToBox flexColumn flex1 br10 pad6">
                                          <div className="pad6 flexRow aCenter">
                                            <div className="cDark flex1 cPurple">
                                              Send SMS
                                            </div>
                                            <img
                                              className="optionDatabaseSortKeyIco"
                                              src={require("../../Assets/img/options/container/chevronDown.png")}
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="linkingComponent">
                                      <div className="linkingBoxBody">
                                        <div className="linkingBoxDecor"></div>
                                        <div className="linkingBoxItem">
                                          <div className="removeX">
                                            <div className="removeXLine one"></div>
                                            <div className="removeXLine two"></div>
                                          </div>

                                          <div className="optionItemBody">
                                            <div className="databaseOperationModule">
                                              <div className="domHeader">
                                                <div className="domLabel">
                                                  Recipient
                                                </div>
                                                <div className="calcDatabaseFilterMultiBox spaceTopify">
                                                  Type here
                                                  <div className="parameterButton">
                                                    P
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="domBody">
                                                <div className="calcItemDatabaseFilterBox">
                                                  <div className="calcItemDatabaseFilterBoxInner">
                                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                                      <div className="calcItemDBLabel">
                                                        Message
                                                      </div>
                                                    </div>
                                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                                      <div className="calcDatabaseFilterMultiBox">
                                                        Type here
                                                        <div className="parameterButton">
                                                          P
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="linkingBoxFooter">
                                      <div className="optionItemBody">
                                        <div className="linkLabel">Trigger</div>
                                        <div className="optionItemRow">
                                          <div className="linkingToBox flexColumn flex1 br10 pad6">
                                            <div className="pad6 flexRow aCenter">
                                              <div className="cDark flex1 cPurple">
                                                On Press
                                              </div>
                                              <img
                                                className="optionDatabaseSortKeyIco"
                                                src={require("../../Assets/img/options/container/chevronDown.png")}
                                              ></img>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="triggerSeperator"></div>

                                  <div className="linkingBoxMain">
                                    <div className="linkingBoxHead">
                                      <div className="optionItemBody">
                                        <div className="linkLabel success">
                                          On Success - Link to
                                        </div>

                                        <div className="linkingToBox flexColumn flex1 br10 pad6">
                                          <div className="pad6 flexRow aCenter">
                                            <div className="cDark flex1 cPurple">
                                              Send SMS
                                            </div>
                                            <img
                                              className="optionDatabaseSortKeyIco"
                                              src={require("../../Assets/img/options/container/chevronDown.png")}
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="triggerSeperator"></div>

                                  <div className="linkingBoxMain">
                                    <div className="linkingBoxHead">
                                      <div className="optionItemBody">
                                        <div className="linkLabel fail">
                                          On Fail - Link to
                                        </div>

                                        <div className="linkingToBox flexColumn flex1 br10 pad6">
                                          <div className="pad6 flexRow aCenter">
                                            <div className="cDark flex1 cPurple">
                                              Send SMS
                                            </div>
                                            <img
                                              className="optionDatabaseSortKeyIco"
                                              src={require("../../Assets/img/options/container/chevronDown.png")}
                                            ></img>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="saveTrigger shadow">
                                    Exit Builder
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdRadio">
                                    <div className="tdRadioDecor"></div>
                                  </div>
                                  <div className="tdID">2</div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="tdDropdown edit">
                                    <div className="tdDropdownLabel">
                                      Edit Record
                                    </div>
                                    <div className="tdDropdownChev">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="tdDropdown">
                                    <img
                                      className="tdDropdownIco"
                                      src={require("../../Assets/img/options/options/database.png")}
                                    ></img>
                                    <div className="tdDropdownLabel">
                                      iMessenger
                                    </div>
                                    <div className="tdDropdownChev">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  Push Notification
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div class="minActionRow">
                                    <div class="minButton">Set</div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="switchInput active">
                                    <div className="switchBall"></div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div class="minActionRow">
                                    <div class="removeButton">Delete</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer displayNone">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Backups</div>
                      <span>Create and manage your backups</span>
                    </div>

                    <div className="contentBody">
                      <div className="dataTableActionRow">
                        <div className="minLightButton">
                          <div className="buttonPlus">
                            <div></div>
                            <div></div>
                          </div>
                          Create Backup
                        </div>
                      </div>
                      <div className="dataTable">
                        <table>
                          <thead>
                            <th>id</th>
                            <th>Created</th>
                            <th>Status</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdID">1</div>
                                </div>
                              </td>

                              <td>
                                <div className="tableData">
                                  12/23/2024 <span>3:12 pm</span>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">Processing</div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div class="minActionRow"></div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdID">2</div>
                                </div>
                              </td>

                              <td>
                                <div className="tableData">
                                  12/23/2024 <span>3:12 pm</span>
                                </div>
                              </td>
                              <td>
                                <div className="tableData cGreen">Complete</div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="minActionRow">
                                    <div className="minButton">Restore</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer displayNone">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Integrations</div>
                      <span>View and manage your integrations</span>
                    </div>

                    <div className="contentBody">
                      <div className="tLabel mb10">Communication</div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">Emails</div>
                          <div className="publishRow">
                            <div className="mValue">Sendgrid not setup</div>
                            <div className="publishAction">Link</div>
                          </div>

                          <div className="publishDetails">
                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">Sendgrid API Key</div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className="pbRow">
                              <div className="pbItem">
                                <div className="pbLabel">
                                  Sendgrid API Secret
                                </div>
                                <input
                                  type="text"
                                  className="mInput"
                                  placeholder=""
                                />
                              </div>
                            </div>

                            <div className="minActionRow">
                              <div className="minButton">Submit</div>
                              <div className="minLightButton">Cancel</div>
                            </div>
                          </div>
                        </div>
                        <div className="borderItem">
                          <div className="mLabel">Push Notifications</div>
                          <div className="publishRow">
                            <div className="mValue">Firebase not setup</div>
                            <div className="publishAction">Link</div>
                          </div>
                        </div>

                        <div className="borderItem">
                          <div className="mLabel">Text Messaging</div>
                          <div className="publishRow">
                            <div className="mValue">Twillio not setup</div>
                            <div className="publishAction">Link</div>
                          </div>
                        </div>
                      </div>

                      <div className="tLabel mb10 pt50">
                        Payment Configuration
                      </div>
                      <div className="borderBox mb20">
                        <div className="borderItem">
                          <div className="mLabel">Stripe</div>
                          <div className="publishRow">
                            <div className="mValue">Stripe not set up.</div>
                            <div className="publishAction">Link</div>
                          </div>
                        </div>
                        <div className="borderItem">
                          <div className="mLabel">PayPal</div>
                          <div className="publishRow">
                            <div className="mValue">PayPal not setup</div>
                            <div className="publishAction">Link</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mainContainer">
                  <div className="containerAdjust w600">
                    <div className="pageTitle">
                      <div>Project Emails</div>
                      <span>Create and manage your email templates</span>
                    </div>

                    <div className="contentBody">
                      <div className="dataTableActionRow">
                        <div className="minLightButton">
                          <div className="buttonPlus">
                            <div></div>
                            <div></div>
                          </div>
                          Create Template
                        </div>
                        <div className="minButton mbRed">Delete Selected</div>
                      </div>
                      <div className="dataTable">
                        <table>
                          <thead>
                            <th>id</th>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Action</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdRadio active">
                                    <div className="tdRadioDecor"></div>
                                  </div>
                                  <div className="tdID">1</div>
                                </div>
                              </td>

                              <td>
                                <div className="tableData">
                                  <input type="text" className="tdInput" placeholder="Enter template name" defaultValue="Signup Verification"/>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  12/23/2024 <span>3:12 pm</span>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="minActionRow">
                                    <div className="minButton">Open</div>
                                  </div>
                                </div>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <div className="tableData">
                                  <div className="tdRadio">
                                    <div className="tdRadioDecor"></div>
                                  </div>
                                  <div className="tdID">2</div>
                                </div>
                              </td>

                              <td>
                                <div className="tableData">
                                  <input type="text" className="tdInput" placeholder="Enter template name" defaultValue="Purchase Confirmation"/>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  12/23/2024 <span>3:12 pm</span>
                                </div>
                              </td>
                              <td>
                                <div className="tableData">
                                  <div className="minActionRow">
                                    <div className="minButton">Open</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const rightPanel = (
      <div className="builderOptions">
        <div className="optionsPanel">
          <button
            style={{ position: "absolute", right: 0, marginTop: "-35px" }}
            onClick={() =>
              this.setState({ showStylePanel: !this.state.showStylePanel })
            }
          >
            {this.state.showStylePanel ? "Hide" : "Show"} Style Panel
          </button>
          <div
            style={{ display: this.state.showStylePanel ? "block" : "none" }}
          >
            <div className="optionsHeader">
              <div className="optionsElementName">
                Container <span>Element</span>
              </div>
              <div className="optionsElementMeta">
                ID: <span></span>2345
              </div>
            </div>

            <div className="calculationOutside">
              <div className="calculationWindow">
                <div className="calcHead">
                  <div className="calcHeadAction">
                    <div className="calculationHeadBackLine one"></div>
                    <div className="calculationHeadBackLine two"></div>
                    <div className="calculationHeadBackLineHorizontal"></div>
                  </div>
                  <div className="calcHeadMeta">
                    <div className="calcHeadLabel">Calculation</div>
                    <span className="calcHeadSublineLabel">
                      <span>4</span> Steps in Calculation
                    </span>
                  </div>
                </div>

                <div className="calcBody">
                  <div className="calcItem">
                    <div className="calcItemHead">
                      <div className="calcItemHeadOrder">1</div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation active">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Information panel
                          </div>
                          <div className="calcItemBodyMainHeaderAction">
                            Clear
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecor"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Date & Time
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <input
                                  className="calcItemUnitMainDropdownLabel"
                                  placeholder="HH:MM"
                                />
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterLabel">
                                    Time
                                  </div>
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">2</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase active">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Value of column
                          </div>
                          <div className="calcItemBodyMainHeaderAction">
                            Clear
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecor"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Database Table
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <img
                                  className="calcItemUnitMainDropdownIco"
                                  src={require("../../Assets/img/options/options/database.png")}
                                ></img>
                                <div className="calcItemUnitMainDropdownLabel">
                                  Reservations
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitBox">
                            <div className="calcItemDatabaseFilter">
                              <div className="filterSet">
                                <div className="calcItemDatabaseFilterLabelRow">
                                  <div className="calcItemDatabaseFilterLabel">
                                    Filter
                                  </div>

                                  <div className="filterFooter">
                                    <div className="addFilter">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>

                                    <div className="removeFilter">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>

                                <div className="calcItemDatabaseFilterBox">
                                  <div className="calcItemDatabaseFilterBoxInner">
                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                      <div className="calcItemUnitMainDropdown">
                                        <div className="calcItemUnitMainDropdownLabel">
                                          Column
                                        </div>
                                        <div className="calcItemUnitMainDropdownFooter">
                                          <div className="calcItemUnitMainDropdownFooterArrow">
                                            <div className="one"></div>
                                            <div className="two"></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="calcItemUnitMainDropdown">
                                        <div className="calcItemUnitMainDropdownLabel">
                                          Equals
                                        </div>
                                        <div className="calcItemUnitMainDropdownFooter">
                                          <div className="calcItemUnitMainDropdownFooterArrow">
                                            <div className="one"></div>
                                            <div className="two"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                      <div className="calcDatabaseFilterMultiBox">
                                        Type here
                                        <div className="parameterButton">P</div>
                                        <div className="dbfRemove">
                                          <div className="calculationCloseLine one"></div>
                                          <div className="calculationCloseLine two"></div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="removeFilterButton">
                                      Remove
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="pad6">
                                  <div className="addFilterButton dark">
                                    Add Filter
                                  </div>
                                </div> */}
                              </div>

                              <div className="filterSet">
                                <div className="calcItemDatabaseFilterLabelRow">
                                  <div className="calcItemDatabaseFilterOptions">
                                    <div className="filterOptionItem active">
                                      And
                                    </div>
                                    <div className="filterOptionItem">Or</div>
                                  </div>

                                  <div className="filterFooter">
                                    <div className="addFilter">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>

                                    <div className="removeFilter">
                                      <div className="one"></div>
                                      <div className="two"></div>
                                    </div>
                                  </div>
                                </div>

                                <div className="calcItemDatabaseFilterBox">
                                  <div className="calcItemDatabaseFilterBoxInner">
                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                      <div className="calcItemUnitMainDropdown">
                                        <div className="calcItemUnitMainDropdownLabel">
                                          Column
                                        </div>
                                        <div className="calcItemUnitMainDropdownFooter">
                                          <div className="calcItemUnitMainDropdownFooterArrow">
                                            <div className="one"></div>
                                            <div className="two"></div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="calcItemUnitMainDropdown">
                                        <div className="calcItemUnitMainDropdownLabel">
                                          Equals
                                        </div>
                                        <div className="calcItemUnitMainDropdownFooter">
                                          <div className="calcItemUnitMainDropdownFooterArrow">
                                            <div className="one"></div>
                                            <div className="two"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBoxInnerRow">
                                      <div className="calcDatabaseFilterMultiBox">
                                        Type here
                                        <div className="parameterButton">P</div>
                                        <div className="dbfRemove">
                                          <div className="calculationCloseLine one"></div>
                                          <div className="calculationCloseLine two"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="removeFilterButton">
                                      Remove
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="pad6">
                                  <div className="addFilterButton dark">
                                    Add Filter
                                  </div>
                                </div> */}
                              </div>

                              <div className="pad6">
                                <div className="addFilterButton dark">
                                  Add Filter Set
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitFooter">
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Action
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <div className="calcItemUnitMainDropdownLabel">
                                  Value of column
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Select column
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <div className="calcItemUnitMainDropdownLabel">
                                  Column name
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">3</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue active">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Value function
                          </div>
                          <div className="calcItemBodyMainHeaderAction">
                            Clear
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecor"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Value of Element
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <div className="calcItemUnitMainDropdownLabel">
                                  Container #OH839
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">4</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase active">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Value of API
                          </div>
                          <div className="calcItemBodyMainHeaderAction">
                            Clear
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecor"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainCombo">
                                <div className="calcItemUnitMainLabel">
                                  API Request
                                </div>
                                <div className="calcItemUnitSublineLabel">
                                  ID: <span>480-382</span>
                                </div>
                              </div>

                              <div className="calcItemUnitMainDropdown adjust">
                                <div className="calcItemUnitMainDropdownLabel">
                                  New API Request
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>

                              <div className="calcDatabaseFilterMultiBox spaceTopify">
                                Endpoint URL
                                <div className="parameterButton">P</div>
                              </div>

                              <div className="calcItemUnitMainDropdown adjust spaceTopify">
                                <div className="calcItemUnitMainDropdownLabel">
                                  POST
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitBox">
                            <div className="calcItemDatabaseFilter">
                              <div className="calcItemHeadTab apiTabItem spaceTopify">
                                <div className="calcItemHeadTabItem active">
                                  Headers
                                </div>
                                <div className="calcItemHeadTabItem">
                                  Params
                                </div>
                                <div className="calcItemHeadTabItem">Body</div>
                              </div>

                              <div class="calcItemDatabaseFilterLabel">
                                1 Header
                              </div>

                              <div className="calcItemDatabaseFilterBox">
                                <div className="calcItemDatabaseFilterBoxInner">
                                  <div className="calcItemDatabaseFilterBoxInnerRow">
                                    <div className="calcItemUnitMainDropdown">
                                      <input
                                        className="calcItemUnitMainDropdownLabel"
                                        placeholder="Key"
                                      ></input>
                                    </div>
                                  </div>
                                  <div className="calcItemDatabaseFilterBoxInnerRow">
                                    <div className="calcDatabaseFilterMultiBox">
                                      Value
                                      <div className="parameterButton">P</div>
                                      <div className="dbfRemove">
                                        <div className="calculationCloseLine one"></div>
                                        <div className="calculationCloseLine two"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="removeFilterButton">
                                    Remove
                                  </div>
                                </div>
                              </div>

                              <div className="pad6">
                                <div className="addFilterButton dark">
                                  Add Header
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitFooter">
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Path to Result
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <input
                                  className="calcItemUnitMainDropdownLabel"
                                  placeholder="data.Email"
                                />
                              </div>
                            </div>

                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Content Type
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <div className="calcItemUnitMainDropdownLabel">
                                  JSON
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">4.5</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase active">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Value of API
                          </div>
                          <div className="calcItemBodyMainHeaderAction">
                            Clear
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecor"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainCombo">
                                <div className="calcItemUnitMainLabel">
                                  API Request
                                </div>
                                <div className="calcItemUnitSublineLabel">
                                  ID: <span>480-382</span>
                                </div>
                              </div>

                              <div className="calcItemUnitMainDropdown adjust">
                                <div className="calcItemUnitMainDropdownLabel">
                                  Previous API Request
                                </div>
                                <div className="calcItemUnitMainDropdownFooter">
                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                    <div className="one"></div>
                                    <div className="two"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitBox">
                            <div className="calcItemDatabaseFilter">
                              <div className="calcItemDatabaseFilterBox">
                                <div className="calcItemDatabaseFilterBoxInner">
                                  <div className="calcItemDatabaseFilterBoxInnerRow">
                                    <div className="calcItemDBLabel">
                                      Request ID
                                    </div>
                                  </div>
                                  <div className="calcItemDatabaseFilterBoxInnerRow">
                                    <div className="calcDatabaseFilterMultiBox">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="calcItemUnitFooter">
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainLabel">
                                Path to Result
                              </div>
                              <div className="calcItemUnitMainDropdown">
                                <input
                                  className="calcItemUnitMainDropdownLabel"
                                  placeholder="data.Email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">5</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue active">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Parent
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Repeating Container
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Repeating RTC
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemBodyMainHeaderSecondary">
                          <div className="calcItemBodyMainHeaderLabel">
                            Value
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Value of element
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Custom value
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Order of repeating element
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Passed Parameter
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">6</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation active">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Information panel
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Date & Time
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Screen Information
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Device Information
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Distance Between Locations
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Random Value
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHeadAction">
                      <div className="calcItemHeadOrder">7</div>
                      <div className="calcDropdown">
                        <div className="calcDropdownLabel">
                          Select Operation
                        </div>
                        <div className="calcItemUnitMainDropdownFooterArrow">
                          <div className="one"></div>
                          <div className="two"></div>
                        </div>
                      </div>
                    </div>
                    <div className="calcItemHead">
                      <div className="calcItemRemove">
                        <div className="calculationCloseLine one"></div>
                        <div className="calculationCloseLine two"></div>
                      </div>
                      <div className="calcItemHeadTab">
                        <div className="calcItemHeadTabItem tabValue">
                          Value
                        </div>
                        <div className="calcItemHeadTabItem tabDatabase active">
                          Database
                        </div>
                        <div className="calcItemHeadTabItem tabInformation">
                          Information
                        </div>
                      </div>
                    </div>

                    <div className="calcItemBody">
                      <div className="calcItemDecor"></div>
                      <div className="calcItemBodyMain">
                        <div className="calcItemBodyMainHeader">
                          <div className="calcItemBodyMainHeaderLabel">
                            Database operations
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                Database value(s)
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="calcItemUnit">
                          <div className="calcItemUnitBox">
                            <div className="calcItemUnitDecorUnselected"></div>
                            <div className="calcItemUnitMain">
                              <div className="calcItemUnitMainUnselectedLabel">
                                External API Request
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="calcItem">
                    <div className="calcItemHead">
                      <div className="addOperation">
                        <div className="addOperationLabel">Add Operation</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem mainCondition displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Data</div>
                    <div className="optionHeadTabs">
                      <div className="optionHeadTabItem">Fixed</div>
                      <div className="optionHeadTabItem active">
                        Conditional
                      </div>
                    </div>
                  </div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionConditionItemHead">
                  <div className="optionConditionHeader">
                    <div className="optionConditionHeaderItem active">
                      <div>Condition</div> <span>1</span>
                      <div className="removeConditionIco">
                        <div className="removeConditionIcoLine one"></div>
                        <div className="removeConditionIcoLine two"></div>
                      </div>
                    </div>
                    <div className="optionConditionHeaderItem">
                      <div>Condition</div> <span>2</span>
                      <div className="removeConditionIco">
                        <div className="removeConditionIcoLine one"></div>
                        <div className="removeConditionIcoLine two"></div>
                      </div>
                    </div>
                    <div className="optionConditionHeaderItemAdd">
                      <div className="optionConditionHeaderItemLine one"></div>
                      <div className="optionConditionHeaderItemLine two"></div>
                    </div>
                  </div>
                </div>

                <div className="optionCondition">
                  <div className="optionConditionItem">
                    <div className="optionConditionItemInner">
                      <div className="optionConditionItemHead">
                        <div className="optionConditionItemContext">
                          <div className="optionConditionItemMeta">
                            <div className="optionConditionItemMetaField">
                              If
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>

                          <div className="optionConditionItemValue">
                            <div className="optionConditionItemValueField">
                              Select condition
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionConditionItem">
                    <div className="optionConditionItemInner">
                      <div className="optionConditionItemHead">
                        <div className="optionConditionItemContext">
                          <div className="optionConditionItemMeta">
                            <div className="optionConditionItemMetaField">
                              If
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>

                          <div className="optionConditionItemValue">
                            <div className="optionConditionItemValueField">
                              Number of rows
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>
                        </div>

                        <div className="optionConditionAdd">
                          <div className="optionConditionAddLine one"></div>
                          <div className="optionConditionAddLine two"></div>
                        </div>
                      </div>

                      <div className="optionConditionTableSelector">
                        <div className="optionConditionTableSelectorHead">
                          <div className="optionConditionTableSelectorHeadSubline">
                            Table:
                          </div>
                          <div className="optionConditionTableSelectorHeadSelect">
                            <div className="optionConditionTableSelectorHeadSelectLabel">
                              Orders
                            </div>
                            <img
                              className="optionConditionTableSelectorHeadSelectIco"
                              src={require("../../Assets/img/options/options/chevronDownTeal.png")}
                            ></img>
                          </div>
                        </div>
                        <div className="optionConditionTableSelectorBody">
                          <div className="optionConditionTableSelectorHeadSublineFilter">
                            Filter:
                          </div>
                          <div className="optionConditionTableSelectorBodyList">
                            <div className="optionConditionTableSelectorFilterItem">
                              <div className="optionConditionTableSelectorFilterRemove">
                                <div className="optionConditionTableSelectorFilterLine one"></div>
                                <div className="optionConditionTableSelectorFilterLine two"></div>
                              </div>
                              <div className="optionConditionTableSelectorFilterItemContext">
                                <div className="optionConditionTableSelectorFilterItemHead">
                                  <div className="optionConditionTableParam">
                                    Product Name
                                  </div>
                                  <div className="jointActionRow">
                                    <div className="jointActionClose">
                                      <div className="jointActionCloseItem one"></div>
                                      <div className="jointActionCloseItem two"></div>
                                    </div>
                                    <img
                                      className="optionConditionTableFilterMetaRequirementIco"
                                      src={require("../../Assets/img/options/container/chevronDown.png")}
                                    ></img>
                                  </div>
                                </div>

                                <div className="optionConditionTableFilterMeta">
                                  <div className="optionConditionTableFilterMetaParams">
                                    <div className="optionConditionTableFilterMetaRequirement">
                                      <div className="optionConditionTableFilterMetaRequirementLabel">
                                        Equals
                                      </div>
                                      <img
                                        className="optionConditionTableFilterMetaRequirementIco"
                                        src={require("../../Assets/img/options/container/chevronDown.png")}
                                      ></img>
                                    </div>

                                    <div className="optionConditionTableFilterMetaType">
                                      <div className="optionConditionTableFilterMetaTypeLabel">
                                        Calculation
                                      </div>
                                      <img
                                        className="optionConditionTableFilterMetaTypeIco"
                                        src={require("../../Assets/img/options/container/chevronDown.png")}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="optionConditionTableFilterMetaQuery">
                                    <textarea
                                      type="text"
                                      placeholder="1-9999"
                                      className="optionConditionTableFilterMetaQueryInput"
                                    />
                                    <div className="paramListRow">
                                      <div className="paramListItem">P</div>
                                    </div>
                                    <div className="filterRemoveRow">
                                      <div className="filterRemoveItem">
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="optionConditionTableSelectorFilterItem">
                              <div className="optionConditionTableSelectorFilterRemove">
                                <div className="optionConditionTableSelectorFilterLine one"></div>
                                <div className="optionConditionTableSelectorFilterLine two"></div>
                              </div>
                              <div className="optionConditionTableSelectorFilterItemContext">
                                <div className="optionConditionTableSelectorFilterItemHead">
                                  <div className="optionConditionTableParam">
                                    Product Name
                                  </div>
                                  <div className="jointActionRow">
                                    <div className="jointActionClose">
                                      <div className="jointActionCloseItem one"></div>
                                      <div className="jointActionCloseItem two"></div>
                                    </div>
                                    <img
                                      className="optionConditionTableFilterMetaRequirementIco"
                                      src={require("../../Assets/img/options/container/chevronDown.png")}
                                    ></img>
                                  </div>
                                </div>

                                <div className="optionConditionTableFilterMeta">
                                  <div className="optionConditionTableFilterMetaParams">
                                    <div className="optionConditionTableFilterMetaRequirement">
                                      <div className="optionConditionTableFilterMetaRequirementLabel">
                                        Equals
                                      </div>
                                      <img
                                        className="optionConditionTableFilterMetaRequirementIco"
                                        src={require("../../Assets/img/options/container/chevronDown.png")}
                                      ></img>
                                    </div>

                                    <div className="optionConditionTableFilterMetaType">
                                      <div className="optionConditionTableFilterMetaTypeLabel">
                                        Calculation
                                      </div>
                                      <img
                                        className="optionConditionTableFilterMetaTypeIco"
                                        src={require("../../Assets/img/options/container/chevronDown.png")}
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="optionConditionTableFilterMetaQuery">
                                    <textarea
                                      type="text"
                                      placeholder="1-9999"
                                      className="optionConditionTableFilterMetaQueryInput"
                                    />
                                    <div className="paramListRow">
                                      <div className="paramListItem">P</div>
                                    </div>
                                    <div className="filterRemoveRow">
                                      <div className="filterRemoveItem">
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="optionConditionTableSelectorAddFilter">
                            <div className="optionConditionTableSelectorAddFilterLabel">
                              Add Filter
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="optionDatabaseValueSelector">
                        <div className="optionDatabaseValueSelectorLabel">
                          Value of column
                        </div>
                        <div className="optionDatabaseValueSelectorSelect">
                          <div className="optionDatabaseValueSelectorSelectValue">
                            Product Quantity
                          </div>
                          <img
                            className="optionDatabaseValueSelectorSelectIco"
                            src={require("../../Assets/img/options/container/chevronDown.png")}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionConditionItem">
                    <div className="optionConditionItemInner">
                      <div className="optionConditionItemHead">
                        <div className="optionConditionItemContext">
                          <div className="optionConditionItemMeta">
                            <div className="optionConditionItemMetaField">
                              And
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>

                          <div className="optionConditionItemValue">
                            <div className="optionConditionItemValueField">
                              Number of rows
                            </div>
                            <img
                              className="optionConditionItemChevron"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>
                        </div>

                        <div className="optionConditionRemove">
                          <div className="optionConditionRemoveLine"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Media</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody pad5t">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor">
                            <div className="triangle"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Video</div>
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor">
                            <div className="audioDecor">
                              <div className="audioDecorLine one"></div>
                              <div className="audioDecorLine two"></div>
                              <div className="audioDecorLine three"></div>
                              <div className="audioDecorLine four"></div>
                              <div className="audioDecorLine five"></div>
                            </div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Audio</div>
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor">
                            <div className="chartDecor">
                              <div className="chartDecorLine one"></div>
                              <div className="chartDecorLine two"></div>
                              <div className="chartDecorLine three"></div>
                              <div className="chartDecorLine four"></div>
                            </div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Chart</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Data</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem active">File</div>
                      <div className="optionHeadTabItem">Reference</div>
                    </div>
                  </div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>
                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Video File
                        </div>
                        <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
                          <div className="uploadPhotoItem">
                            <div className="uploadPhoto"></div>
                            <div className="deletePhoto">
                              <div className="deletePhotoLine one"></div>
                              <div className="deletePhotoLine two"></div>
                            </div>
                          </div>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterUpload">
                            <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
                            <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Video Reference
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Reference to video"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Audio File
                        </div>
                        <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
                          <div className="uploadPhotoItem">
                            <div className="uploadPhoto"></div>
                            <div className="deletePhoto">
                              <div className="deletePhotoLine one"></div>
                              <div className="deletePhotoLine two"></div>
                            </div>
                          </div>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterUpload">
                            <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
                            <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Audio Reference
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Reference to audio"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">X Axis</div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Comma seperated data"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">Y Axis</div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Comma seperated data"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Image</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody pad5t">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor">
                            <div className="containerDecorPlusButton"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Single</div>
                      </div>
                    </div>

                    {/* <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor galleryDecor">
                            <div className="containerDecorPlusButton"></div>
                            <div className="containerDecorPlusButton"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">
                          Gallery
                        </div>
                      </div>
                    </div> */}

                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecorPlusButtonSecond">
                            <div className="containerDecorPlusButtonLine one"></div>
                            <div className="containerDecorPlusButtonLine two"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">
                          Gallery
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Zoombox on popup</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="optionItemRow">
                  <div className="optionItemBox">
                    <div className="advancedRadioItem">
                      <div className="advancedMultiLabel tCenter">Screen</div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Select</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem active">File</div>
                      <div className="optionHeadTabItem">URL</div>
                    </div>
                  </div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>
                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Image File
                        </div>
                        <div className="optionDatabaseTextBoxBody uploadPhotoContainer">
                          <div className="uploadPhotoItem">
                            <div className="uploadPhoto"></div>
                            <div className="deletePhoto">
                              <div className="deletePhotoLine one"></div>
                              <div className="deletePhotoLine two"></div>
                            </div>
                          </div>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterUpload">
                            <div className="optionDatabaseTextBoxFooterUploadLine one"></div>
                            <div className="optionDatabaseTextBoxFooterUploadLine two"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Image URL
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Reference to image"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Type</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Text Box</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionItemHead pad5t">
                  <div className="optionItemLabel">Options</div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow pad7">
                    <div className="linkingOpen flexColumn bgWhite shadow flex1 br10 pad6">
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio stillRadio active">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Bar</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Open</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Infinite</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Dropdown</div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Photo</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Video</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Date</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Time</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Options</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Active Tab
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Enter element ID, or order number"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexColumn bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Loop</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Pagination</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Auto Play</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Auto Play Interval
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="In milliseconds"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Slides to Scroll
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            placeholder="Number of slides to scroll"
                          ></textarea>
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow pad7">
                    <div className="linkingOpen flexColumn bgWhite shadow flex1 br10 pad6">
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio stillRadio active">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Bar</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Open</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Infinite</div>
                      </div>
                      <div className="pad6 flexRow flex1">
                        <div className="optionDatabaseRadio">
                          <div className="optionDatabaseRadioDecor"></div>
                        </div>
                        <div className="cGrey">Dropdown</div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Photo</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Video</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Date</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Time</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Appearance</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxIco"
                          src={require("../../Assets/img/options/text/font.png")}
                        ></img>
                        <div className="optionInputIconBoxField">
                          Times New Roman
                        </div>

                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox max90w">
                      <div className="optionInputIconBox">
                        <input
                          type="text"
                          className="optionInputIconBoxField"
                          placeholder="18"
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Bold</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionItemHead pad5t">
                  <div className="optionItemLabel">Colors</div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgWhite"></div>
                        <div className="optionInputIconBoxText">Text Color</div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgGrey"></div>
                        <div className="optionInputIconBoxText">
                          Placeholder Color
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgBlack"></div>
                        <div className="optionInputIconBoxText">
                          Background Color
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Data</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="flex column pad7 flex1">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio checkBoxify stillRadio active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Single</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio stillRadio checkBoxify">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Range</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow pad7">
                    <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                      <div className="optionConditionTableSelectorFilterItemHead">
                        <div className="optionConditionTableFilterMetaRequirementLabel">
                          Minimum Date
                        </div>
                      </div>

                      <div className="optionConditionTableFilterMeta">
                        <div className="optionConditionTableFilterMetaParams">
                          <div className="optionConditionTableFilterMetaType">
                            <div className="optionConditionTableFilterMetaTypeLabel">
                              Calculation
                            </div>
                            <img
                              className="optionConditionTableFilterMetaTypeIco"
                              src={require("../../Assets/img/options/container/chevronDown.png")}
                            ></img>
                          </div>
                        </div>
                        <div className="optionConditionTableFilterMetaQuery">
                          <textarea
                            type="text"
                            placeholder="1-9999"
                            className="optionConditionTableFilterMetaQueryInput"
                          />
                          <div className="paramListRow">
                            <div className="paramListItem">P</div>
                          </div>
                          <div className="filterRemoveRow">
                            <div className="filterRemoveItem">Remove</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">Value</div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            defaultValue=""
                            placeholder="Enter text here..."
                          />
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Default Value
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            defaultValue=""
                            placeholder="Enter text here..."
                          />
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">
                          Placeholder Value
                        </div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            defaultValue=""
                            placeholder="Enter comma seperated values. E.g: apples, bananas"
                          />
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Appearance</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxIco"
                          src={require("../../Assets/img/options/text/font.png")}
                        ></img>
                        <div className="optionInputIconBoxField">
                          Times New Roman
                        </div>

                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox max90w">
                      <div className="optionInputIconBox">
                        <input
                          type="text"
                          className="optionInputIconBoxField"
                          placeholder="18"
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Bold</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionItemHead pad5t">
                  <div className="optionItemLabel">Colors</div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgWhite"></div>
                        <div className="optionInputIconBoxText">Text Color</div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgBlack"></div>
                        <div className="optionInputIconBoxText">
                          Background Color
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Container</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody pad5t">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="verticalOptions jStart">
                          <div className="containerDecor">
                            <div className="containerDecorPlusButton"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">
                          Container
                        </div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecorPlusButtonSecond">
                            <div className="containerDecorPlusButtonLine one"></div>
                            <div className="containerDecorPlusButtonLine two"></div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Slider</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jStart">
                          <div className="containerDecorNew">
                            <div className="containerDecorPlusButtonTabs">
                              <div className="containerDecorInner"></div>
                              <div className="containerDecorInnerTwo"></div>
                            </div>
                          </div>
                        </div>
                        <div className="advancedMultiLabel tCenter">Tabs</div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="optionItemRow">
                  <div className="optionItemBox">
                    <div className="advancedRadioItem">
                      <div className="advancedMultiLabel tCenter">Screen</div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Appearance</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxIco"
                          src={require("../../Assets/img/options/container/widthIco.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxField"
                          placeholder="Auto"
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxIco rotate90"
                          src={require("../../Assets/img/options/container/widthIco.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxField"
                          placeholder="Auto"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Vertical</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">Horizontal</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Horizontal Orientation</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="horizontalOptions jLeft">
                          <div className="horizontal one"></div>
                          <div className="horizontal two"></div>
                          <div className="horizontal three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Default <span>Start left</span>
                        </div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="horizontalOptions jRight">
                          <div className="horizontal one"></div>
                          <div className="horizontal two"></div>
                          <div className="horizontal three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Right <span>Start right</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="horizontalOptions jSpaceBetween">
                          <div className="horizontal one"></div>
                          <div className="horizontal two"></div>
                          <div className="horizontal three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Stretch <span>End to end</span>
                        </div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="horizontalOptions jSpaceEvenly">
                          <div className="horizontal one"></div>
                          <div className="horizontal two"></div>
                          <div className="horizontal three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Even <span>Spaced equal</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="horizontalOptions jCenter">
                          <div className="horizontal one"></div>
                          <div className="horizontal two"></div>
                          <div className="horizontal three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Center <span>Horizontally centered</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Vertical Orientation</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="verticalOptions jStart">
                          <div className="vertical one"></div>
                          <div className="vertical two"></div>
                          <div className="vertical three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Default <span>Start top</span>
                        </div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jEnd">
                          <div className="vertical one"></div>
                          <div className="vertical two"></div>
                          <div className="vertical three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Bottom <span>Start end</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jSpaceBetween">
                          <div className="vertical one"></div>
                          <div className="vertical two"></div>
                          <div className="vertical three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Stretch <span>End to end</span>
                        </div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="verticalOptions jCenter">
                          <div className="vertical one"></div>
                          <div className="vertical two"></div>
                          <div className="vertical three"></div>
                        </div>
                        <div className="advancedMultiLabel">
                          Center <span>Middle</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Background</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgWhite"></div>
                        <div className="optionInputIconBoxText">
                          Background Color
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionDivider"></div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionColorItem">
                        <div className="optionColorCircle bgImageIco">
                          <div className="bgImageLine one"></div>
                          <div className="bgImageLine two"></div>
                        </div>
                        <div className="optionInputIconBoxText">
                          Background Image
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Repeat</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Contain</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Scrolling</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem active">On</div>
                      <div className="optionHeadTabItem">Off</div>
                    </div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Vertical</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">Horizontal</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Page</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem active">On</div>
                      <div className="optionHeadTabItem">Off</div>
                    </div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">
                          Screen Name
                        </div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Visibility</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Show</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">Hide</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Wrap</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody revealOffset">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Off</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">On</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionItemHead pad5t">
                  <div className="optionItemLabel">Safe Area</div>
                </div>

                <div className="optionItemBody revealOffset">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Off</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">On</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Options</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody revealOffset">
                  <div className="optionItemRow">
                    <div className="optionItemListLabel">Wrap <div className="optionItemListLabelIco">i</div></div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Off</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">On</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionItemBody revealOffset">
                  <div className="optionItemRow">
                    <div className="optionItemListLabel">Safe Area <div className="optionItemListLabelIco">i</div></div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem lightActive">
                        <div className="advancedRadioLabel">Off</div>
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="advancedRadioItem">
                        <div className="advancedRadioLabel">On</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Spacing</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="spacingSubline mt10">Margin (outside)</div>

                <div className="optionItemBody pt0">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate180"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate270"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="spacingSubline">Padding (inside)</div>

                <div className="optionItemBody pt0">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate180"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate270"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Position</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem">Default</div>
                      <div className="optionHeadTabItem active">Fixed</div>
                    </div>
                  </div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate180"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate270"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Radius</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate180"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate270"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Shadow</div>
                    <div className="optionHeadTabs headTabsDark">
                      <div className="optionHeadTabItem active">On</div>
                      <div className="optionHeadTabItem">Off</div>
                    </div>
                  </div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionItemBox fInitial">
                      <div className="borderColorPicker"></div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/container/widthIco.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/container/widthIco.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabel">Border</div>
                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody revealOffset">
                  <div className="optionItemRow">
                    <div className="optionItemBox fInitial">
                      <div className="borderColorPicker"></div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Solid</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox fInitial">
                      <div className="borderColorPicker"></div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate180"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Solid</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox fInitial">
                      <div className="borderColorPicker"></div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate270"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Solid</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionItemBox fInitial">
                      <div className="borderColorPicker"></div>
                    </div>
                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <img
                          className="optionInputIconBoxArrowIco rotate90"
                          src={require("../../Assets/img/options/options/greyArrow.png")}
                        ></img>
                        <input
                          type="text"
                          className="optionInputIconBoxSpacingField"
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="optionItemBox">
                      <div className="optionInputIconBox">
                        <div className="optionInputIconBoxField">Solid</div>
                        <img
                          className="optionDatabaseSelectChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Content</div>
                    <div className="optionHeadTabs">
                      <div className="optionHeadTabItem">Fixed</div>
                      <div className="optionHeadTabItem active">Repeating</div>
                    </div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionDatabaseSelector">
                  <div className="optionDatabaseSelectorItem">
                    <div className="optionDatabaseSelectorLeft">
                      <div className="optionDatabaseSelectorIco">
                        <div></div>
                      </div>
                      <div className="optionDatabaseSelectorInfo">
                        <div className="optionDatabaseSelectorSubline">
                          Database
                        </div>
                        <div className="optionDatabaseSelectorLabel">
                          Select table
                        </div>
                      </div>
                    </div>

                    <img
                      className="optionDatabaseSelectorChevron"
                      src={require("../../Assets/img/options/container/chevronDown.png")}
                    ></img>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow filterItemUnit">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseBox">
                        <div className="optionDatabaseBoxLabel">
                          Filter Data
                        </div>
                        <div className="optionDatabaseFilters">
                          <div className="optionDatabaseFilterItem">
                            <div className="optionDatabaseFilterContext">
                              <div className="optionDatabaseFilterKey">
                                <div className="optionDatabaseFilterKeyLabel">
                                  Product Quantity
                                </div>
                                <img
                                  className="optionDatabaseFilterKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDownPurple.png")}
                                ></img>
                              </div>
                              <div className="optionDatabaseFilterMeta">
                                <div className="optionDatabaseFilterMetaParams">
                                  <div className="optionDatabaseFilterMetaColumn">
                                    <div className="optionDatabaseFilterMetaColumnLabel">
                                      Product Name
                                    </div>
                                    <img
                                      className="optionDatabaseFilterMetaRequirementIco"
                                      src={require("../../Assets/img/options/container/chevronDown.png")}
                                    ></img>
                                  </div>
                                </div>

                                <div className="optionDatabaseFilterMetaParams">
                                  <div className="optionDatabaseFilterMetaRequirement">
                                    <div className="optionDatabaseFilterMetaRequirementLabel">
                                      Equals
                                    </div>
                                    <img
                                      className="optionDatabaseFilterMetaRequirementIco"
                                      src={require("../../Assets/img/options/container/chevronDown.png")}
                                    ></img>
                                  </div>

                                  <div className="optionDatabaseFilterMetaType">
                                    <div className="optionDatabaseFilterMetaTypeLabel">
                                      Calculation
                                    </div>
                                    <img
                                      className="optionDatabaseFilterMetaTypeIco"
                                      src={require("../../Assets/img/options/container/chevronDown.png")}
                                    ></img>
                                  </div>
                                </div>
                                <div className="optionDatabaseFilterMetaQuery">
                                  <textarea
                                    type="text"
                                    placeholder="1-9999"
                                    className="optionDatabaseFilterMetaQueryInput"
                                  />
                                  <div className="lightParamListRow">
                                    <div className="lightParamListItem">P</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="optionDatabaseFilterClose">
                              <div className="optionDatabaseFilterCloseLine one"></div>
                              <div className="optionDatabaseFilterCloseLine two"></div>
                            </div>
                          </div>

                          <div className="optionDatabaseFilterAddMore">
                            Add Filter
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper modifiedBoxes">
                      <div className="optionDatabaseBoxLabel">Order Data</div>
                      <div className="optionDatabaseBox">
                        <div className="optionDatabaseOrder">
                          <div className="optionDatabaseOrderItem">
                            <div className="optionDatabaseOrderContext">
                              <div className="optionDatabaseOrderKey">
                                <div className="optionDatabaseOrderKeyLabel">
                                  Select
                                </div>
                                <img
                                  className="optionDatabaseFilterKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDownPurple.png")}
                                ></img>
                              </div>

                              <div className="optionDatabaseSortKey">
                                <div className="optionDatabaseSortKeyLabel">
                                  Ascending
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper modifiedBoxes">
                      <div className="optionDatabaseBoxLabel">Pagination</div>
                      <div className="optionDatabaseBox">
                        <div className="optionDatabasePagination">
                          <div className="optionDatabasePaginationItem">
                            <div className="optionDatabaseRadio">
                              <div className="optionDatabaseRadioDecor"></div>
                            </div>
                            <div className="optionDatabasePaginationContext">
                              <div className="optionDatabasePaginationKey">
                                <div className="optionDatabasePaginationKeyLabel">
                                  Limit Results
                                </div>
                              </div>
                              <div className="optionDatabasePaginationMeta">
                                <input
                                  className="optionDatabasePaginationMetaInput"
                                  type="text"
                                  placeholder="Value"
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="optionDatabasePaginationItem">
                            <div className="optionDatabaseRadio active">
                              <div className="optionDatabaseRadioDecor"></div>
                            </div>
                            <div className="optionDatabasePaginationContext">
                              <div className="optionDatabasePaginationKey">
                                <div className="optionDatabasePaginationKeyLabel">
                                  Infinite Load Interval
                                </div>
                              </div>
                              <div className="optionDatabasePaginationMeta">
                                <input
                                  className="optionDatabasePaginationMetaInput"
                                  type="text"
                                  placeholder="Value"
                                ></input>
                              </div>
                            </div>
                          </div>

                          <div className="optionDatabasePaginationItem">
                            <div className="optionDatabaseRadio">
                              <div className="optionDatabaseRadioDecor"></div>
                            </div>
                            <div className="optionDatabasePaginationContext">
                              <div className="optionDatabasePaginationKey">
                                <div className="optionDatabasePaginationKeyLabel">
                                  Pages
                                </div>
                              </div>
                              <div className="optionDatabasePaginationMeta">
                                <input
                                  className="optionDatabasePaginationMetaInput"
                                  type="text"
                                  placeholder="Value"
                                ></input>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Map</div>
                    <div className="optionHeadTabs">
                      <div className="optionHeadTabItem">Fixed</div>
                      <div className="optionHeadTabItem active">Repeating</div>
                    </div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="pad7">
                  <div className="optionItemBody">
                    <div className="optionItemRow">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio stillRadio active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Marker</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio stillRadio">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Route</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionMapDatabaseWrapper">
                  <div className="optionMapDatabase">
                    <div className="optionDatabaseSelector mt0">
                      <div className="optionDatabaseSelectorItem">
                        <div className="optionDatabaseSelectorLeft">
                          <div className="optionDatabaseSelectorIco">
                            <div></div>
                          </div>
                          <div className="optionDatabaseSelectorInfo">
                            <div className="optionDatabaseSelectorSubline">
                              Database
                            </div>
                            <div className="optionDatabaseSelectorLabel">
                              Select table
                            </div>
                          </div>
                        </div>

                        <img
                          className="optionDatabaseSelectorChevron"
                          src={require("../../Assets/img/options/container/chevronDown.png")}
                        ></img>
                      </div>
                    </div>

                    <div className="optionItemBody">
                      <div className="optionItemRow filterItemUnit">
                        <div className="optionDatabaseBoxWrapper">
                          <div className="optionDatabaseBox">
                            <div className="optionDatabaseBoxLabel">
                              Filter Data
                            </div>
                            <div className="optionDatabaseFilters">
                              <div className="optionDatabaseFilterItem">
                                <div className="optionDatabaseFilterContext">
                                  <div className="optionDatabaseFilterKey">
                                    <div className="optionDatabaseFilterKeyLabel">
                                      Product Quantity
                                    </div>
                                    <img
                                      className="optionDatabaseFilterKeyIco"
                                      src={require("../../Assets/img/options/container/chevronDownPurple.png")}
                                    ></img>
                                  </div>
                                  <div className="optionDatabaseFilterMeta">
                                    <div className="optionDatabaseFilterMetaParams">
                                      <div className="optionDatabaseFilterMetaColumn">
                                        <div className="optionDatabaseFilterMetaColumnLabel">
                                          Product Name
                                        </div>
                                        <img
                                          className="optionDatabaseFilterMetaRequirementIco"
                                          src={require("../../Assets/img/options/container/chevronDown.png")}
                                        ></img>
                                      </div>
                                    </div>

                                    <div className="optionDatabaseFilterMetaParams">
                                      <div className="optionDatabaseFilterMetaRequirement">
                                        <div className="optionDatabaseFilterMetaRequirementLabel">
                                          Equals
                                        </div>
                                        <img
                                          className="optionDatabaseFilterMetaRequirementIco"
                                          src={require("../../Assets/img/options/container/chevronDown.png")}
                                        ></img>
                                      </div>

                                      <div className="optionDatabaseFilterMetaType">
                                        <div className="optionDatabaseFilterMetaTypeLabel">
                                          Calculation
                                        </div>
                                        <img
                                          className="optionDatabaseFilterMetaTypeIco"
                                          src={require("../../Assets/img/options/container/chevronDown.png")}
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="optionDatabaseFilterMetaQuery">
                                      <textarea
                                        type="text"
                                        placeholder="1-9999"
                                        className="optionDatabaseFilterMetaQueryInput"
                                      />
                                      <div className="lightParamListRow">
                                        <div className="lightParamListItem">
                                          P
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="optionDatabaseFilterClose">
                                  <div className="optionDatabaseFilterCloseLine one"></div>
                                  <div className="optionDatabaseFilterCloseLine two"></div>
                                </div>
                              </div>

                              <div className="optionDatabaseFilterAddMore">
                                Add Filter
                              </div>

                              <div className="addressSelector">
                                <div className="optionDatabaseBoxLabel">
                                  Address
                                </div>

                                <div className="optionDatabaseValueSelectorSelect">
                                  <div className="optionDatabaseValueSelectorSelectValue">
                                    Select Column
                                  </div>
                                  <img
                                    className="optionDatabaseValueSelectorSelectIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>

                              <div className="addressSelector mapIconSection">
                                <div className="optionDatabaseBoxLabelMultiUnit">
                                  <div className="optionDatabaseBoxLabel">
                                    Map Icon
                                  </div>
                                  <div className="optionHeadTabs headTabsDark">
                                    <div className="optionHeadTabItem active">
                                      Database
                                    </div>
                                    <div className="optionHeadTabItem">
                                      Custom
                                    </div>
                                  </div>
                                </div>

                                <div className="optionItemRow optionItemIconDimension">
                                  <div className="optionItemBox">
                                    <div className="optionInputIconBox">
                                      <img
                                        className="optionInputIconBoxArrowIco"
                                        src={require("../../Assets/img/options/container/widthIco.png")}
                                      ></img>
                                      <input
                                        type="text"
                                        className="optionInputIconBoxSpacingField"
                                        placeholder=""
                                        defaultValue="24"
                                      />
                                    </div>
                                  </div>
                                  <div className="optionItemBox">
                                    <div className="optionInputIconBox">
                                      <img
                                        className="optionInputIconBoxArrowIco rotate90"
                                        src={require("../../Assets/img/options/container/widthIco.png")}
                                      ></img>
                                      <input
                                        type="text"
                                        className="optionInputIconBoxSpacingField"
                                        placeholder=""
                                        defaultValue="24"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="optionDatabaseValueSelectorSelect">
                                  <div className="optionDatabaseValueSelectorSelectValue">
                                    Select Column
                                  </div>
                                  <img
                                    className="optionDatabaseValueSelectorSelectIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>

                              <div className="addressSelector">
                                <div className="optionDatabaseBoxLabel">
                                  Markers
                                </div>

                                <div className="optionDatabaseTextBox mt10">
                                  <div className="optionDatabaseTextBoxLabel">
                                    Plot points within (km)
                                  </div>
                                  <div className="optionDatabaseTextBoxBody">
                                    <textarea
                                      className="optionDatabaseTextInput"
                                      placeholder="Enter text here..."
                                    ></textarea>
                                  </div>
                                  <div className="optionDatabaseTextBoxFooter">
                                    <div className="optionDatabaseTextBoxFooterItem">
                                      P
                                    </div>
                                  </div>
                                </div>

                                <div className="optionDatabaseTextBox mt10">
                                  <div className="optionDatabaseTextBoxLabel">
                                    Within radius of
                                  </div>
                                  <div className="optionDatabaseTextBoxBody">
                                    <textarea
                                      className="optionDatabaseTextInput"
                                      placeholder="Enter text here..."
                                    ></textarea>
                                  </div>
                                  <div className="optionDatabaseTextBoxFooter">
                                    <div className="optionDatabaseTextBoxFooterItem">
                                      P
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="addressSelector">
                                <div className="optionDatabaseBoxLabel">
                                  Map Center
                                </div>

                                <div className="optionDatabaseValueSelectorSelect">
                                  <div className="optionDatabaseValueSelectorSelectValue">
                                    Based on Data
                                  </div>
                                  <img
                                    className="optionDatabaseValueSelectorSelectIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Map</div>
                    <div className="optionHeadTabs">
                      <div className="optionHeadTabItem">Fixed</div>
                      <div className="optionHeadTabItem active">Repeating</div>
                    </div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="pad7">
                  <div className="optionItemBody">
                    <div className="optionItemRow">
                      <div className="linkingOpen flexRow bgWhite shadow flex1 br10 pad6">
                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio stillRadio active">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Marker</div>
                        </div>

                        <div className="pad6 flexRow flex1">
                          <div className="optionDatabaseRadio stillRadio">
                            <div className="optionDatabaseRadioDecor"></div>
                          </div>
                          <div className="cGrey">Route</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="optionMapDatabaseWrapper">
                  <div className="optionMapCombo">
                    <div className="optionMapLeft">
                      <div className="optionMapNumberWrapper">
                        <div className="optionMapNumber">1</div>
                      </div>

                      <div className="optionMapLeftDecor"></div>
                    </div>

                    <div className="mapLinkingCombo">
                      <div className="optionMapDatabase optionMapSingular">
                        <div className="optionItemBody">
                          <div className="optionItemRow filterItemUnit">
                            <div className="optionDatabaseBoxWrapper">
                              <div className="optionDatabaseBox">
                                <div className="optionDatabaseFilters">
                                  <div className="addressSelector optionMapAdjust">
                                    <div className="optionDatabaseBoxLabel">
                                      Address
                                    </div>

                                    <div className="optionDatabaseTextBox mt10">
                                      <div className="optionDatabaseTextBoxLabel">
                                        Enter Location
                                      </div>
                                      <div className="optionDatabaseTextBoxBody">
                                        <textarea
                                          className="optionDatabaseTextInput"
                                          placeholder="Enter text here..."
                                        ></textarea>
                                      </div>
                                      <div className="optionDatabaseTextBoxFooter">
                                        <div className="optionDatabaseTextBoxFooterItem">
                                          P
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="addressSelector mapIconSection">
                                    <div className="optionDatabaseBoxLabelMultiUnit">
                                      <div className="optionDatabaseBoxLabel">
                                        Icon
                                      </div>
                                      <div className="optionHeadTabs headTabsDark">
                                        <div className="optionHeadTabItem active">
                                          Database
                                        </div>
                                        <div className="optionHeadTabItem">
                                          Custom
                                        </div>
                                      </div>
                                    </div>

                                    <div className="optionItemRow optionItemIconDimension">
                                      <div className="optionItemBox">
                                        <div className="optionInputIconBox">
                                          <img
                                            className="optionInputIconBoxArrowIco"
                                            src={require("../../Assets/img/options/container/widthIco.png")}
                                          ></img>
                                          <input
                                            type="text"
                                            className="optionInputIconBoxSpacingField"
                                            placeholder=""
                                            defaultValue="24"
                                          />
                                        </div>
                                      </div>
                                      <div className="optionItemBox">
                                        <div className="optionInputIconBox">
                                          <img
                                            className="optionInputIconBoxArrowIco rotate90"
                                            src={require("../../Assets/img/options/container/widthIco.png")}
                                          ></img>
                                          <input
                                            type="text"
                                            className="optionInputIconBoxSpacingField"
                                            placeholder=""
                                            defaultValue="24"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="optionDatabaseTextBox mt10">
                                      <div className="optionDatabaseTextBoxLabel">
                                        Icon Image
                                      </div>
                                      <div className="optionDatabaseTextBoxBody">
                                        <textarea
                                          className="optionDatabaseTextInput"
                                          placeholder="Reference to Image"
                                        ></textarea>
                                      </div>
                                      <div className="optionDatabaseTextBoxFooter">
                                        <div className="optionDatabaseTextBoxFooterItem">
                                          P
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mapLinkingWrapper">
                        <div className="optionAddLinkingButton">
                          Add Linking
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionMapCombo">
                    <div className="optionMapLeft">
                      <div className="optionMapNumberWrapper">
                        <div className="optionMapNumber">2</div>
                      </div>

                      <div className="optionMapLeftDecor"></div>
                    </div>

                    <div className="mapLinkingCombo">
                      <div className="optionMapDatabase optionMapSingular">
                        <div className="optionItemBody">
                          <div className="optionItemRow filterItemUnit">
                            <div className="optionDatabaseBoxWrapper">
                              <div className="optionDatabaseBox">
                                <div className="optionDatabaseFilters">
                                  <div className="addressSelector optionMapAdjust">
                                    <div className="optionDatabaseBoxLabel">
                                      Address
                                    </div>

                                    <div className="optionDatabaseTextBox mt10">
                                      <div className="optionDatabaseTextBoxLabel">
                                        Enter Location
                                      </div>
                                      <div className="optionDatabaseTextBoxBody">
                                        <textarea
                                          className="optionDatabaseTextInput"
                                          placeholder="Enter text here..."
                                        ></textarea>
                                      </div>
                                      <div className="optionDatabaseTextBoxFooter">
                                        <div className="optionDatabaseTextBoxFooterItem">
                                          P
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="addressSelector mapIconSection">
                                    <div className="optionDatabaseBoxLabelMultiUnit">
                                      <div className="optionDatabaseBoxLabel">
                                        Icon
                                      </div>
                                      <div className="optionHeadTabs headTabsDark">
                                        <div className="optionHeadTabItem active">
                                          Database
                                        </div>
                                        <div className="optionHeadTabItem">
                                          Custom
                                        </div>
                                      </div>
                                    </div>

                                    <div className="optionItemRow optionItemIconDimension">
                                      <div className="optionItemBox">
                                        <div className="optionInputIconBox">
                                          <img
                                            className="optionInputIconBoxArrowIco"
                                            src={require("../../Assets/img/options/container/widthIco.png")}
                                          ></img>
                                          <input
                                            type="text"
                                            className="optionInputIconBoxSpacingField"
                                            placeholder=""
                                            defaultValue="24"
                                          />
                                        </div>
                                      </div>
                                      <div className="optionItemBox">
                                        <div className="optionInputIconBox">
                                          <img
                                            className="optionInputIconBoxArrowIco rotate90"
                                            src={require("../../Assets/img/options/container/widthIco.png")}
                                          ></img>
                                          <input
                                            type="text"
                                            className="optionInputIconBoxSpacingField"
                                            placeholder=""
                                            defaultValue="24"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="optionDatabaseTextBox mt10">
                                      <div className="optionDatabaseTextBoxLabel">
                                        Icon Image
                                      </div>
                                      <div className="optionDatabaseTextBoxBody">
                                        <textarea
                                          className="optionDatabaseTextInput"
                                          placeholder="Reference to Image"
                                        ></textarea>
                                      </div>
                                      <div className="optionDatabaseTextBoxFooter">
                                        <div className="optionDatabaseTextBoxFooterItem">
                                          P
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mapLinkingWrapper">
                        <div className="optionAddLinkingButton">
                          Add Linking
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Content</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">Text</div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            defaultValue="My name is David, and I am a jazz singer."
                            placeholder="Enter text here..."
                          />
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem displayNone">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Content</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="optionDatabaseBoxWrapper">
                      <div className="optionDatabaseTextBox">
                        <div className="optionDatabaseTextBoxLabel">Text</div>
                        <div className="optionDatabaseTextBoxBody">
                          <textarea
                            className="optionDatabaseTextInput"
                            defaultValue="My name is David, and I am a jazz singer."
                            placeholder="Enter text here..."
                          />
                        </div>
                        <div className="optionDatabaseTextBoxFooter">
                          <div className="optionDatabaseTextBoxFooterItem">
                            P
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Select
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>

                            <div className="linkDropdown">
                              <div className="linkDropdownHead">
                                <div className="calcItemHeadTab">
                                  <div className="calcItemHeadTabItem active">
                                    General
                                  </div>
                                  <div className="calcItemHeadTabItem">
                                    Stream
                                  </div>
                                  <div className="calcItemHeadTabItem">
                                    Connect
                                  </div>
                                </div>
                              </div>
                              <div className="linkDropdownBody">
                                <div className="linkDropdownLabel">
                                  General linking
                                </div>

                                <div className="linkDropdownRow">
                                  <div className="calcItemUnit active">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Screen
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          URL
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="linkDropdownRow">
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Database
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Payment
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="linkDropdownRow">
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Alert
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Visibility
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="linkDropdownRow">
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Set Value
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="calcItemUnit">
                                    <div className="calcItemUnitBox">
                                      <div className="calcItemUnitDecorUnselected"></div>
                                      <div className="calcItemUnitMain">
                                        <div className="calcItemUnitMainUnselectedLabel">
                                          Loop
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Database
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="databaseItemHead">
                            <div className="databaseItemHeadOrder">1</div>
                            {/* <div className="databaseItemHeadTab">
                            <div className="databaseItemHeadTabItem add active">Add Row</div>
                            <div className="databaseItemHeadTabItem edit">Edit Row</div>
                            <div className="databaseItemHeadTabItem delete">Delete Row</div>
                          </div> */}

                            <div className="calcDropdown add">
                              <div className="calcDropdownLabel">
                                Add Record
                              </div>
                              <div className="calcItemUnitMainDropdownFooterArrow">
                                <div className="one"></div>
                                <div className="two"></div>
                              </div>
                            </div>
                          </div>

                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseItemActionBar">
                                  <div className="databaseItemActionLabel">
                                    Database
                                  </div>
                                  <div className="databaseItemActionRemove">
                                    Clear
                                  </div>
                                </div>

                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Table</div>

                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <img
                                        className="calcItemUnitMainDropdownIco"
                                        src={require("../../Assets/img/options/options/database.png")}
                                      />
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Reservations
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Username
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Password
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="optionItemBody displayNone">
                                <div className="cDark fwSemiBold f14 mb10">
                                  Alert
                                </div>
                                <div className="optionItemRow">
                                  <div className="optionConditionTableSelectorFilterItemContext">
                                    <div className="optionConditionTableSelectorFilterItemHead">
                                      <div className="optionConditionTableFilterMetaRequirementLabel">
                                        Alert Text
                                      </div>
                                    </div>

                                    <div className="optionConditionTableFilterMeta">
                                      <div className="optionConditionTableFilterMetaParams">
                                        <div className="optionConditionTableFilterMetaType">
                                          <div className="optionConditionTableFilterMetaTypeLabel">
                                            Calculation
                                          </div>
                                          <img
                                            className="optionConditionTableFilterMetaTypeIco"
                                            src={require("../../Assets/img/options/container/chevronDown.png")}
                                          ></img>
                                        </div>
                                      </div>
                                      <div className="optionConditionTableFilterMetaQuery">
                                        <textarea
                                          type="text"
                                          placeholder="1-9999"
                                          className="optionConditionTableFilterMetaQueryInput"
                                        />
                                        <div className="paramListRow">
                                          <div className="paramListItem">P</div>
                                        </div>
                                        <div className="filterRemoveRow">
                                          <div className="filterRemoveItem">
                                            Remove
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="databaseItemHead">
                            <div className="databaseItemHeadOrder">2</div>
                            {/* <div className="databaseItemHeadTab">
                            <div className="databaseItemHeadTabItem add active">Add Row</div>
                            <div className="databaseItemHeadTabItem edit">Edit Row</div>
                            <div className="databaseItemHeadTabItem delete">Delete Row</div>
                          </div> */}

                            <div className="calcDropdown edit">
                              <div className="calcDropdownLabel">
                                Edit Record
                              </div>
                              <div className="calcItemUnitMainDropdownFooterArrow">
                                <div className="one"></div>
                                <div className="two"></div>
                              </div>
                            </div>
                          </div>

                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseItemActionBar">
                                  <div className="databaseItemActionLabel">
                                    Database
                                  </div>
                                  <div className="databaseItemActionRemove">
                                    Clear
                                  </div>
                                </div>

                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Table</div>
                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <img
                                        className="calcItemUnitMainDropdownIco"
                                        src={require("../../Assets/img/options/options/database.png")}
                                      />
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Reservations
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="calcItemUnitBox">
                                    <div className="calcItemDatabaseFilter">
                                      <div className="filterSet">
                                        <div className="calcItemDatabaseFilterLabelRow">
                                          <div className="calcItemDatabaseFilterLabel">
                                            Filter
                                          </div>

                                          <div className="filterFooter">
                                            <div className="addFilter">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>

                                            <div className="removeFilter">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="calcItemDatabaseFilterBox">
                                          <div className="calcItemDatabaseFilterBoxInner">
                                            <div className="calcItemDatabaseFilterBoxInnerRow">
                                              <div className="calcItemUnitMainDropdown">
                                                <div className="calcItemUnitMainDropdownLabel">
                                                  Column
                                                </div>
                                                <div className="calcItemUnitMainDropdownFooter">
                                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                                    <div className="one"></div>
                                                    <div className="two"></div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="calcItemUnitMainDropdown">
                                                <div className="calcItemUnitMainDropdownLabel">
                                                  Equals
                                                </div>
                                                <div className="calcItemUnitMainDropdownFooter">
                                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                                    <div className="one"></div>
                                                    <div className="two"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="calcItemDatabaseFilterBoxInnerRow">
                                              <div className="calcDatabaseFilterMultiBox">
                                                Type here
                                                <div className="parameterButton">
                                                  P
                                                </div>
                                                <div className="dbfRemove">
                                                  <div className="calculationCloseLine one"></div>
                                                  <div className="calculationCloseLine two"></div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="removeFilterButton">
                                              Remove
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="pad6">
                                  <div className="addFilterButton dark">
                                    Add Filter
                                  </div>
                                </div> */}
                                      </div>

                                      <div className="filterSet">
                                        <div className="calcItemDatabaseFilterLabelRow">
                                          <div className="calcItemDatabaseFilterOptions">
                                            <div className="filterOptionItem active">
                                              And
                                            </div>
                                            <div className="filterOptionItem">
                                              Or
                                            </div>
                                          </div>

                                          <div className="filterFooter">
                                            <div className="addFilter">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>

                                            <div className="removeFilter">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="calcItemDatabaseFilterBox">
                                          <div className="calcItemDatabaseFilterBoxInner">
                                            <div className="calcItemDatabaseFilterBoxInnerRow">
                                              <div className="calcItemUnitMainDropdown">
                                                <div className="calcItemUnitMainDropdownLabel">
                                                  Column
                                                </div>
                                                <div className="calcItemUnitMainDropdownFooter">
                                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                                    <div className="one"></div>
                                                    <div className="two"></div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="calcItemUnitMainDropdown">
                                                <div className="calcItemUnitMainDropdownLabel">
                                                  Equals
                                                </div>
                                                <div className="calcItemUnitMainDropdownFooter">
                                                  <div className="calcItemUnitMainDropdownFooterArrow">
                                                    <div className="one"></div>
                                                    <div className="two"></div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="calcItemDatabaseFilterBoxInnerRow">
                                              <div className="calcDatabaseFilterMultiBox">
                                                Type here
                                                <div className="parameterButton">
                                                  P
                                                </div>
                                                <div className="dbfRemove">
                                                  <div className="calculationCloseLine one"></div>
                                                  <div className="calculationCloseLine two"></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="removeFilterButton">
                                              Remove
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div className="pad6">
                                  <div className="addFilterButton dark">
                                    Add Filter
                                  </div>
                                </div> */}
                                      </div>

                                      <div className="pad6">
                                        <div className="addFilterButton dark">
                                          Add Filter Set
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="pad12 bgWhite">
                                    <div className="domLabel pad">
                                      Edit Record
                                    </div>

                                    <div className="linkingOptionsBox spaceTopify">
                                      <div className="pad6 flexRow flex1">
                                        <div className="optionDatabaseRadio checkBoxify active">
                                          <div className="optionDatabaseRadioDecor"></div>
                                        </div>
                                        <div className="cGrey">
                                          Edit current
                                        </div>
                                      </div>
                                      <div className="pad6 flexRow flex1">
                                        <div className="optionDatabaseRadio checkBoxify">
                                          <div className="optionDatabaseRadioDecor"></div>
                                        </div>
                                        <div className="cGrey">
                                          Create new if doesn't exist
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Username
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Password
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="optionItemBody displayNone">
                                <div className="cDark fwSemiBold f14 mb10">
                                  Alert
                                </div>
                                <div className="optionItemRow">
                                  <div className="optionConditionTableSelectorFilterItemContext">
                                    <div className="optionConditionTableSelectorFilterItemHead">
                                      <div className="optionConditionTableFilterMetaRequirementLabel">
                                        Alert Text
                                      </div>
                                    </div>

                                    <div className="optionConditionTableFilterMeta">
                                      <div className="optionConditionTableFilterMetaParams">
                                        <div className="optionConditionTableFilterMetaType">
                                          <div className="optionConditionTableFilterMetaTypeLabel">
                                            Calculation
                                          </div>
                                          <img
                                            className="optionConditionTableFilterMetaTypeIco"
                                            src={require("../../Assets/img/options/container/chevronDown.png")}
                                          ></img>
                                        </div>
                                      </div>
                                      <div className="optionConditionTableFilterMetaQuery">
                                        <textarea
                                          type="text"
                                          placeholder="1-9999"
                                          className="optionConditionTableFilterMetaQueryInput"
                                        />
                                        <div className="paramListRow">
                                          <div className="paramListItem">P</div>
                                        </div>
                                        <div className="filterRemoveRow">
                                          <div className="filterRemoveItem">
                                            Remove
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel success">
                              On Success - Link to
                            </div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Select
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel fail">
                              On Fail - Link to
                            </div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Select
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Screen
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Destination</div>
                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Select Screen
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterLabel">
                                      Parameters
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemUnitMainDropdown">
                                            <input
                                              className="calcItemUnitMainDropdownLabel"
                                              placeholder="Parameter Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Parameter Value
                                            <div className="parameterButton">
                                              P
                                            </div>
                                            <div className="dbfRemove">
                                              <div className="calculationCloseLine one"></div>
                                              <div className="calculationCloseLine two"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="removeFilterButton">
                                          Remove
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pad6">
                                      <div className="addFilterButton dark">
                                        Add Parameter
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Format</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Screen
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">URL</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Destination</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      http://example.com
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterLabel">
                                      Send Parameters
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemUnitMainDropdown">
                                            <input
                                              className="calcItemUnitMainDropdownLabel"
                                              placeholder="Parameter Name"
                                            />
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Parameter Value
                                            <div className="parameterButton">
                                              P
                                            </div>
                                            <div className="dbfRemove">
                                              <div className="calculationCloseLine one"></div>
                                              <div className="calculationCloseLine two"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="removeFilterButton">
                                          Remove
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pad6">
                                      <div className="addFilterButton dark">
                                        Add Parameter
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Window</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Current
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Alert</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Alert Text</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Payment
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">
                                      Payment Options
                                    </div>

                                    <div className="linkingOptionsBox spaceTopify">
                                      <div className="pad6 flexRow flex1">
                                        <div className="optionDatabaseRadio checkBoxify active">
                                          <div className="optionDatabaseRadioDecor"></div>
                                        </div>
                                        <div className="cGrey">PayPal</div>
                                      </div>
                                      <div className="pad6 flexRow flex1">
                                        <div className="optionDatabaseRadio checkBoxify">
                                          <div className="optionDatabaseRadioDecor"></div>
                                        </div>
                                        <div className="cGrey">Stripe</div>
                                      </div>
                                      <div className="pad6 flexRow flex1">
                                        <div className="optionDatabaseRadio checkBoxify active">
                                          <div className="optionDatabaseRadioDecor"></div>
                                        </div>
                                        <div className="cGrey">
                                          In App Purchase
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Product ID
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Product Price
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Payment Type</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    One-Time
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Broadcast
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Behavior</div>

                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Join Room
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Room ID
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Join With</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Video
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Broadcast
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Behavior</div>

                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Camera
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Action</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Flip Camera
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Visibility
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Element</div>

                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Select Element
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Action</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Hide
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Set Value
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Element</div>

                                    <div className="calcItemUnitMainDropdown spaceTopify">
                                      <div className="calcItemUnitMainDropdownLabel">
                                        Select Element
                                      </div>
                                      <div className="calcItemUnitMainDropdownFooter">
                                        <div className="calcItemUnitMainDropdownFooterArrow">
                                          <div className="one"></div>
                                          <div className="two"></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Element Value
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Loop</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="loopHead">
                          <div className="loopDecor"></div>
                          <div className="loopContext">
                            <div className="optionItemBody">
                              <div className="linkLabel">Loop type</div>
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Database
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>

                              <div className="linkLabel mt10">Database</div>

                              <div className="databaseOperationModule loopDatabase">
                                <div className="domHeader">
                                  <div className="calcItemUnitMainDropdown">
                                    <img
                                      className="calcItemUnitMainDropdownIco"
                                      src={require("../../Assets/img/options/options/database.png")}
                                    />
                                    <div className="calcItemUnitMainDropdownLabel">
                                      Reservations
                                    </div>
                                    <div className="calcItemUnitMainDropdownFooter">
                                      <div className="calcItemUnitMainDropdownFooterArrow">
                                        <div className="one"></div>
                                        <div className="two"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="domBody">
                                  <div className="calcItemDatabaseFilterLabelRow">
                                    <div className="calcItemDatabaseFilterLabel">
                                      Filter
                                    </div>

                                    <div className="filterFooter">
                                      <div className="addFilter">
                                        <div className="one"></div>
                                        <div className="two"></div>
                                      </div>

                                      <div className="removeFilter">
                                        <div className="one"></div>
                                        <div className="two"></div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="calcItemDatabaseFilterBox">
                                    <div className="calcItemDatabaseFilterBoxInner">
                                      <div className="calcItemDatabaseFilterBoxInnerRow">
                                        <div className="calcItemUnitMainDropdown">
                                          <div className="calcItemUnitMainDropdownLabel">
                                            Column
                                          </div>
                                          <div className="calcItemUnitMainDropdownFooter">
                                            <div className="calcItemUnitMainDropdownFooterArrow">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="calcItemUnitMainDropdown">
                                          <div className="calcItemUnitMainDropdownLabel">
                                            Equals
                                          </div>
                                          <div className="calcItemUnitMainDropdownFooter">
                                            <div className="calcItemUnitMainDropdownFooterArrow">
                                              <div className="one"></div>
                                              <div className="two"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="calcItemDatabaseFilterBoxInnerRow">
                                        <div className="calcDatabaseFilterMultiBox">
                                          Type here
                                          <div className="parameterButton">
                                            P
                                          </div>
                                          <div className="dbfRemove">
                                            <div className="calculationCloseLine one"></div>
                                            <div className="calculationCloseLine two"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="removeFilterButton">
                                        Remove
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pad6">
                                    <div className="addFilterButton dark">
                                      Add Filter Set
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="loopSeperator"></div>

                        <div className="loopLinking">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Alert</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Alert Text</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel success">
                              On Success - Link to
                            </div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Select
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel fail">
                              On Fail - Link to
                            </div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Select
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel completion">
                              On Completion - Link to
                            </div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    Select
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Phone</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Phone Number</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Email</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">
                                      Email Address
                                    </div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Send Email
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">From</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            To
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Subject
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Email Body
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Send SMS
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Recipient</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Message
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">
                                  Push Notification
                                </div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Device ID(s)</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                  <div className="domBody">
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Notification Title
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="calcItemDatabaseFilterBox">
                                      <div className="calcItemDatabaseFilterBoxInner">
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcItemDBLabel">
                                            Notification Description
                                          </div>
                                        </div>
                                        <div className="calcItemDatabaseFilterBoxInnerRow">
                                          <div className="calcDatabaseFilterMultiBox">
                                            Type here
                                            <div className="parameterButton">
                                              P
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="optionItem linkingModule developmentHide">
              <div className="optionItemInner">
                <div className="optionItemHead">
                  <div className="optionItemLabelCombo">
                    <div className="optionItemLabel">Linking</div>
                  </div>

                  <div className="optionItemDecor">
                    <div className="optionItemDecorLine one"></div>
                    <div className="optionItemDecorLine two"></div>
                  </div>
                </div>

                <div className="optionItemBody">
                  <div className="optionItemRow">
                    <div className="linkingBoxWrapper">
                      <div className="linkingBoxMain">
                        <div className="linkingBoxHead">
                          <div className="optionItemBody">
                            <div className="linkLabel">Link to</div>

                            <div className="linkingToBox flexColumn flex1 br10 pad6">
                              <div className="pad6 flexRow aCenter">
                                <div className="cDark flex1 cPurple">Share</div>
                                <img
                                  className="optionDatabaseSortKeyIco"
                                  src={require("../../Assets/img/options/container/chevronDown.png")}
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingComponent">
                          <div className="linkingBoxBody">
                            <div className="linkingBoxDecor"></div>
                            <div className="linkingBoxItem">
                              <div className="removeX">
                                <div className="removeXLine one"></div>
                                <div className="removeXLine two"></div>
                              </div>

                              <div className="optionItemBody">
                                <div className="databaseOperationModule">
                                  <div className="domHeader">
                                    <div className="domLabel">Content</div>
                                    <div className="calcDatabaseFilterMultiBox spaceTopify">
                                      Type here
                                      <div className="parameterButton">P</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="linkingBoxFooter">
                          <div className="optionItemBody">
                            <div className="linkLabel">Trigger</div>
                            <div className="optionItemRow">
                              <div className="linkingToBox flexColumn flex1 br10 pad6">
                                <div className="pad6 flexRow aCenter">
                                  <div className="cDark flex1 cPurple">
                                    On Press
                                  </div>
                                  <img
                                    className="optionDatabaseSortKeyIco"
                                    src={require("../../Assets/img/options/container/chevronDown.png")}
                                  ></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {builderData.focusedElement?.element?.id ? (
            <ElementProperties
              key={builderData.focusedElement?.element?.id}
              focusedElement={builderData.focusedElement}
              {...commonPropsToPass}
            />
          ) : null}
        </div>
      </div>
    );

    const headerRight = (
      <div className="xoloPush">
        <SaveComponent
          component={{
            ...component,
            data: { ...(component?.data || {}), dom: dom?.tree },
          }}
        />
        <div className="xoloStatus">
          <div className="xoloStatusInner"></div>
        </div>
        <a
          href={`//${project?.subdomain}.${config.frontDomain}/${component?.name}`}
          target="execute"
        >
          <div className="xoloRepublish">
            <div className="xoloRepublishLabel">Republish</div>
            <div className="xoloRepublishIco"></div>
          </div>
        </a>
      </div>
    );

    const headerBottom = (
      <div className="xoloHeaderBottom">
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link className="xoloBionicLabel" to={`/project/${project?._id}`}>
              Screens
            </Link>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <Link
              className="xoloBionicLabel"
              to={`/database?projectId=${project?._id}`}
            >
              Database
            </Link>
          </div>
        </div>
        <div className="xoloPlatform">
          <div className="xoloPlatformItem active">iOS</div>
          <div className="xoloPlatformItem">Android</div>
        </div>
        <div className="xoloHeaderAction">
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Templates</div>
          </div>
          <div className="xoloBionic">
            <div className="xoloBionicIco"></div>
            <div className="xoloBionicLabel">Admin Panel</div>
          </div>
        </div>
      </div>
    );

    return (
      <>
        <NavBar
          activeLink="/projects"
          right={headerRight}
          bottom={headerBottom}
        />
        {loading && !components ? (
          <center>Loading...</center>
        ) : dom && component && project ? (
          <div className="appxoloBuilder">
            {leftPanel}
            {centerPanel}
            {rightPanel}
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.vState.BUILDER.project,
  databases: state.vState.BUILDER.databases,
  components: state.vState.BUILDER.components,
  allBuilderData: state.vState.BUILDER,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "BUILDER") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const Builder = DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(BuilderInner)
);

const BuilderScreen = withRouter((props) => (
  <Builder
    key={props.router.params.componentId}
    {...{ ...props.router.params }}
  />
));

export default BuilderScreen;
