import React from "react";
import { connect } from "react-redux";
import _ from 'lodash'

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import { Link } from "react-router-dom";

const SCREEN_NAME = "SIDENAV";

class SideNav extends React.PureComponent {
  render() {
    const {
      props: { activeSidebarItem },
    } = this;

    return (
      <div className="upSidebar">
        <div className="upsMain">
          <div className="upLabel">Action</div>
          <div className="upComboPack">
            {[
              {
                value: "projects",
                icon: require("../../Assets/img/user/projects2.png"),
              },
              {
                value: "users",
                icon: require("../../Assets/img/user/users4.png"),
              },
              {
                value: "databases",
                icon: require("../../Assets/img/user/database5.png"),
              },
              {
                value: "backups",
                icon: require("../../Assets/img/user/backups2.png"),
              },
              {
                value: "analytics",
                icon: require("../../Assets/img/user/analytics3.png"),
              },
            ].map((item) => (
              <Link
                className={
                  "upComboItem " +
                  (item.value === activeSidebarItem ? "active" : "")
                }
                key={item.value}
                to={"/" + item.value}
              >
                <img className="upComboIco" src={item.icon}></img>
                <div className="upComboLabel">{_.startCase(item.value)}</div>
              </Link>
            ))}
          </div>
        </div>
        <div className="upsSub">
          <div className="upLabel">Action</div>

          <div className="upComboPack">
            {[
              {
                value: "help",
                icon: require("../../Assets/img/user/help3.png"),
              },
              {
                value: "account",
                icon: require("../../Assets/img/user/account.png"),
              },
            ].map((item) => (
              <Link
                className={
                  "upComboItem " +
                  (item.value === activeSidebarItem ? "active" : "")
                }
                key={item.value}
                to={"/" + item.value}
              >
                <img className="upComboIco" src={item.icon}></img>
                <div className="upComboLabel">{_.startCase(item.value)}</div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);
