/* eslint-disable no-unused-expressions */

import _, { includes } from "lodash";
import { HtmlBuilder } from "../htmlGenerator/HtmlBuilder.js";

const linkingValueTypes = [
  {
    label: "Screen",
    value: "linkToScreen",
    availableFor: ["front"],
  },
  {
    label: "URL",
    value: "linkToURL",
    availableFor: ["front"],
  },
  {
    label: "Alert",
    value: "linkToAlert",
    availableFor: ["front"],
  },
  {
    label: "Payment",
    value: "linkToPayment",
    availableFor: ["front"],
  },
  {
    label: "Call User",
    value: "linkToWebrtc",
    availableFor: ["front"],
  },
  {
    label: "WebRTC Room",
    value: "webrtcRoom",
    availableFor: ["front"],
    valueTypes: [
      { label: "Join Room", value: "joinWebrtcRoom", shortLabel: "Join" },
      { label: "Leave Room", value: "leaveWebrtcRoom", shortLabel: "Leave" },
    ],
  },
  {
    label: "Microphone",
    value: "microphone",
    availableFor: ["front"],
  },
  {
    label: "Camera",
    value: "camera",
    availableFor: ["front"],
  },
  {
    label: "Flip Camera",
    value: "flipCamera",
    availableFor: ["front"],
  },
  {
    label: "Database",
    value: "rowOperations",
    availableFor: ["front", "backend"],
    valueTypes: [
      { label: "Add Record", value: "addRecord", shortLabel: "Add" },
      { label: "Edit Record", value: "editRecord", shortLabel: "Edit" },
      { label: "Delete Record", value: "deleteRecord", shortLabel: "Delete" },
    ],
  },
  {
    label: "Loop",
    value: "repeatingAction",
    availableFor: ["front"],
  },
  {
    label: "API",
    value: "linkToApi",
    availableFor: ["front", "backend"],
  },
  {
    label: "Update API",
    value: "updateExternalApiReq",
    availableFor: ["front"],
  },
  {
    label: "Refresh Element",
    value: "refreshElement",
    availableFor: ["front"],
  },
  {
    label: "Push Notification",
    value: "triggerPushNotification",
    availableFor: ["front", "backend"],
  },
  {
    label: "Send SMS",
    value: "sendSMS",
    availableFor: ["front", "backend"],
  },
  {
    label: "Send Email",
    value: "sendEmail", // @TODO use from key
    availableFor: ["front", "backend"],
  },
  {
    label: "Visibility",
    value: "setElementStyle", // @TODO execution
    availableFor: ["front"],
  },
  {
    label: "Set Value",
    value: "setElementValue", //@TODO execution
    availableFor: ["front"],
  },
  {
    label: "Broadcast",
    value: "broadcast", //@TODO execution
    availableFor: ["front"],
    valueTypes: [
      { label: "Join Room", value: "joinWebrtcRoom", shortLabel: "Join" },
      { label: "Leave Room", value: "leaveWebrtcRoom", shortLabel: "Leave" },
      { label: "Camera", value: "camera", shortLabel: "Camera" },
      { label: "Microphone", value: "microphone", shortLabel: "Mic" },
    ],
  },
  {
    label: "Phone",
    value: "linkToPhone", //@TODO execution
    availableFor: ["front"],
  },
  {
    label: "Share",
    value: "shareToExternal", //@TODO execution
    availableFor: ["front"],
  },
];

export class LinkingValueTypes {
  constructor({ availableProps }) {
    this.availableProps = availableProps;
    this.linkingValueTypes = linkingValueTypes;
  }

  evalFunctions = {
    linkToScreen: async (valueObj = {}, options) => {
      const { to, openAs, urlParameters } = valueObj;

      let processedUrlParameters = await this.processUrlParameters(
        urlParameters,
        options
      );

      if (openAs === "modal")
        this.availableProps.utils.setScreenModal(to, processedUrlParameters);
      else this.availableProps.utils.navigate(to, processedUrlParameters);
    },
    linkToURL: async (valueObj = {}, options) => {
      // @TODO add target blank or same window
      const { to, urlParameters } = valueObj;

      let processedUrlParameters = await this.processUrlParameters(
        urlParameters,
        options
      );

      if (to) {
        let link = (
          await this.availableProps.evaluateQuickSelectionValue(to, options)
        )?.value?.toString();

        link = link + (link.includes("?") ? "&" : "?");
        for (const key in processedUrlParameters) {
          if (Object.hasOwnProperty.call(processedUrlParameters, key)) {
            const value = processedUrlParameters[key];
            link = `${link}${key}=${value}&`;
          }
        }
        this.availableProps.utils.openLink(link);
      }
    },

    linkToAlert: async (valueObj, options) => {
      this.availableProps.utils.showAlert({
        title: "",
        message: (
          await this.availableProps.evaluateQuickSelectionValue(
            valueObj?.alertMsg,
            options
          )
        )?.value?.toString(),
      });
    },

    linkToPayment: async (valueObj, options) => {
      let data = { ...valueObj };
      await Promise.all(
        ["paymentType", "price", "productId"].map(async (key) => {
          data[key] = (
            await this.availableProps.evaluateQuickSelectionValue(
              valueObj?.[key] || {},
              options
            )
          )?.value;
        })
      );

      const paymentProp = {
        ...data,
        display: true,
        callback: async (err, data1) => {
          console.log("callback: ", { err, data1 });

          let postOp;
          if (err) postOp = data.onFail?.linkings;
          else postOp = data.onSuccess?.linkings;

          await this.availableProps.handleLinkings(postOp, options);
        },
      };

      console.warn({ paymentProp });

      await this.availableProps.utils.setPaymentPopup(paymentProp);
    },

    linkToWebrtc: async (valueObj, options = {}) => {
      let data = {};
      await Promise.all(
        Object.keys(valueObj).map(async (key) => {
          data[key] = (
            await this.availableProps.evaluateQuickSelectionValue(
              valueObj?.[key] || {},
              options
            )
          )?.value;
        })
      );
      return this.availableProps.utils.initiateWebrtcCall(data);
    },

    webrtcRoom: async (value, options = {}) => {
      const { valueType, valueObj = {}, onSuccess, onFail } = value;

      let postOp = null;
      let errorMessage = null;

      try {
        let config = {};
        await Promise.all(
          Object.keys(valueObj[valueType] || {}).map(async (key) => {
            config[key] = (
              await this.availableProps.evaluateQuickSelectionValue(
                valueObj[valueType][key] || {},
                options
              )
            ).value;
          })
        );

        console.log({ webrtcRoomLinkingObj: value, config });

        if (valueType === "joinWebrtcRoom") {
          await this.availableProps.utils.callModule.joinMeeting(config);
        } else if (valueType === "leaveWebrtcRoom") {
          await this.availableProps.utils.callModule.endCall(config);
        }

        postOp = onSuccess;
      } catch (e) {
        console.warn("Error in rtcromm operation: ", e.message);
        postOp = onFail;
        errorMessage = e.message;
      }

      if (postOp?.linkings && options.handleLinkings) {
        return options.handleLinkings(postOp.linkings, {
          ...options,
          errorMessage,
        });
      }
    },

    microphone: async (valueObj = {}, options) => {
      const action = valueObj?.action || "toggle";

      await this.availableProps.utils.setMicrophoneStatus({
        status: action,
      });

      const dataStore = this.availableProps?.dataStore;
      dataStore.mergeData({
        elementId: "MICROPHONE_MUTE_STATUS",
        rowIndices: [0],
        rowIds: ["DEFAULT"],
        obj: {
          value: Date.now(),
          updatedAt: Date.now(),
        },
      });
    },

    camera: async (valueObj = {}, options) => {
      const action = valueObj?.action || "toggle";

      await this.availableProps.utils.setCameraStatus({
        status: action,
      });

      const dataStore = this.availableProps?.dataStore;
      dataStore.mergeData({
        elementId: "CAMERA_DISABLE_STATUS",
        rowIndices: [0],
        rowIds: ["DEFAULT"],
        obj: {
          value: Date.now(),
          updatedAt: Date.now(),
        },
      });
    },

    rowOperations: async (valueObj = {}, options = {}) => {
      const { operations, onSuccess, onFail } = valueObj;

      let postOp = null;
      let errorMessage = null;

      try {
        for (let i = 0; i < operations?.length; i++) {
          const operation = operations?.[i] || {};

          let document = {};
          if (operation.document && Object.keys(operation.document).length) {
            await Promise.all(
              Object.keys(operation.document).map(async (key) => {
                document[key] = {
                  method: operation.document[key]?.method || "replace",
                  value: (
                    await this.availableProps.evaluateQuickSelectionValue(
                      operation.document[key] || {},
                      options
                    )
                  )?.value,
                };
                return null;
              })
            );
          }

          let payload = {
            ..._.omit(operation, ["query"]),
            enableDbTrigger: true,
            document,
          };

          if (["editRecord", "deleteRecord"].includes(operation.valueType)) {
            if (operation.query.valueType === "currentValue") {
              const currentValue =
                await this.availableProps.evaluateQuickSelectionValue(
                  {
                    valueType: "passedParameter",
                    valueObj: {
                      passedParameter: {
                        valueObj: {
                          ...(operation.query.valueObj.valueObj || {}),
                          column: "_id",
                        },
                      },
                    },
                  },
                  options
                );

              if (!currentValue)
                throw new Error(
                  "Error evaluating current value for row operation"
                );

              payload = {
                ...payload,
                dbId: operation.query.valueObj.valueObj.dbData.dbId,
                tableId: operation.query.valueObj.valueObj.dbData.tableId,
                filters: [
                  {
                    condition: "eq",
                    name: "_id",
                    value: currentValue.value,
                  },
                ],
              };
            } else {
              payload = {
                ...payload,
                dbId: operation.query.valueObj.dbId,
                tableId: operation.query.valueObj.tableId,
                filters: await this.availableProps.evalFilters(
                  operation.query.valueObj.filters,
                  options
                ),
              };
            }
          }

          const socketData = await this.availableProps.databaseModule.write(
            payload
          );

          const recordId = socketData?.records?.[0]?._id;

          if (operation.recordIdOperations?.operations?.length) {
            await this.evalFunctions.rowOperations(
              operation.recordIdOperations,
              { ...options, recordId }
            );
          }
        }

        postOp = onSuccess;
      } catch (e) {
        console.error("Error in rowOperation: ", e);
        postOp = onFail;
        errorMessage = e.message;
      }

      if (postOp?.linkings && options.handleLinkings) {
        return options.handleLinkings(postOp.linkings, {
          ...options,
          errorMessage,
        });
      }
    },

    repeatingAction: async (valueObj = {}, options = {}) => {
      const loopType = valueObj.loopType;
      const linking = valueObj.linking;

      if (loopType === "repeatingContainer") {
        const lastElement = valueObj[loopType]?.id?.split("-").pop();

        const valueOfElement =
          await this.availableProps.evaluateQuickSelectionValue(
            {
              valueObj: { element: valueObj[loopType] },
              valueType: "valueOfElement",
            },
            {
              ...options,
              valueOfElementConditionFn: ({ elementInstance }) => {
                const flag =
                  elementInstance.elementId === lastElement
                    ? true
                    : !["container"].includes(elementInstance?.elementType);
                return flag;
              },
            }
          );

        const elementData = valueOfElement?.data?.elementData || {};
        const repeatingContainers = elementData?.repeatingContainers;
        // console.log(lastElement, elementData, repeatingContainers);

        for (let index = 0; index < repeatingContainers.length; index++) {
          const item = repeatingContainers[index];

          const rowIndices = [...elementData.rowIndices, index];
          const rowIds = [...elementData.rowIds, item.uid];
          const indices = elementData.indices;

          const passedParameter = {
            sourceType: "repeatingContainer",
            elementId: elementData.elementId,
            rowIndices,
            rowIds,
            repeatingContainer: item,
          };
          const passedParameters = [
            ...(options.passedParameters ||
              this.availableProps.passedParameters ||
              []),
            passedParameter,
          ];

          const optionsToPass = {
            ...options,
            rowIndices,
            rowIds,
            passedParameters,
            indices,
          };

          const { valueType, valueObj } = linking?.tabs?.[0]?.linkingData || {};

          if (valueType && valueObj) {
            await this.evalFunctions[valueType]?.(valueObj?.[valueType], {
              ...optionsToPass,
            });
          }
        }
      } else if (loopType === "databaseData") {
        const dbData = {
          skip: 0,
          sortBy: "updatedAt",
          order: -1,
          limit: 0,
          dbId: valueObj[loopType]?.dbId,
          tableId: valueObj[loopType]?.tableId,
          filters: await this.availableProps.evalFilters(
            valueObj[loopType]?.filters,
            options
          ),
        };

        const socketData = await this.availableProps.databaseModule.read(
          dbData
        );

        let rows = socketData?.data;
        console.log({ rows });

        for (let index = 0; index < rows?.length; index++) {
          const item = rows[index];
          const passedParameter = {
            sourceType: "dataGroup",
            groupId: dbData.dbId + "-" + dbData.tableId,
            data: item,
          };

          const passedParameters = [
            ...(options.passedParameters ||
              this.availableProps.passedParameters ||
              []),
            passedParameter,
          ];

          const optionsToPass = {
            ...options,
            passedParameters,
          };

          const { valueType, valueObj } = linking?.tabs?.[0]?.linkingData || {};

          if (valueType && valueObj) {
            await this.evalFunctions[valueType]?.(valueObj?.[valueType], {
              ...optionsToPass,
            });
          }
        }
      } else if (loopType === "staticValues") {
        const dataString = (
          await this.availableProps.evaluateQuickSelectionValue(
            valueObj[loopType],
            options
          )
        )?.value;
        const values =
          dataString
            ?.split(",")
            .map((x) => x.trim())
            .filter((x) => x) || [];

        for (let index = 0; index < values?.length; index++) {
          const value = values[index];
          const passedParameter = {
            sourceType: "dataGroup",
            groupId: "staticValueLoopData",
            data: {
              ["staticValue.index"]: index,
              ["staticValue.value"]: value,
            },
          };

          const passedParameters = [
            ...(options.passedParameters ||
              this.availableProps.passedParameters ||
              []),
            passedParameter,
          ];

          const optionsToPass = {
            ...options,
            passedParameters,
          };

          const { valueType, valueObj } = linking?.tabs?.[0]?.linkingData || {};

          if (valueType && valueObj) {
            await this.evalFunctions[valueType]?.(valueObj?.[valueType], {
              ...optionsToPass,
            });
          }
        }
      } else if (loopType === "noOfLoop") {
        const dataString = (
          await this.availableProps.evaluateQuickSelectionValue(
            valueObj[loopType],
            options
          )
        )?.value;
        const noOfLoop = isNaN(dataString) ? 0 : parseInt(dataString);

        for (let index = 1; index <= noOfLoop; index++) {
          const passedParameter = {
            sourceType: "dataGroup",
            groupId: "noOfLoopLoopData",
            data: { ["noOfLoop.index"]: index },
          };

          const passedParameters = [
            ...(options.passedParameters ||
              this.availableProps.passedParameters ||
              []),
            passedParameter,
          ];

          const optionsToPass = {
            ...options,
            passedParameters,
          };

          const { valueType, valueObj } = linking?.tabs?.[0]?.linkingData || {};

          if (valueType && valueObj) {
            await this.evalFunctions[valueType]?.(valueObj?.[valueType], {
              ...optionsToPass,
            });
          }
        }
      }

      const onCompletion = valueObj.onCompletion;
      if (onCompletion?.linkings?.length) {
        for (let i = 0; i < onCompletion.linkings.length; i++) {
          const linking = onCompletion.linkings[i];

          const { valueType, valueObj } = linking?.tabs?.[0]?.linkingData || {};

          if (valueType && valueObj) {
            await this.evalFunctions[valueType]?.(valueObj?.[valueType], {
              ...options,
            });
          }
        }
      }
    },

    linkToApi: async (valueObj = {}, options) => {
      const { onSuccess, onFail } = valueObj;

      let postOp = null;
      let errorMessage = null;
      try {
        await this.availableProps.executeExternalApiRequest(valueObj, options);
        postOp = onSuccess;
      } catch (e) {
        console.error("Error in linkToApi: ", e.message);
        postOp = onFail;
        errorMessage = e.message;
      }

      if (postOp?.linkings && options.handleLinkings) {
        return options.handleLinkings(postOp.linkings, {
          ...options,
          errorMessage,
        });
      }
    },

    updateExternalApiReq: async (valueObj = {}, options) => {
      console.log({ valueObj, options });
      const { targetApiRequestId } = valueObj;

      const dataStore = this.availableProps?.dataStore;

      if (targetApiRequestId && dataStore) {
        const requestIdData =
          dataStore?.externalApiRequestIds?.[targetApiRequestId];

        console.log({ requestIdData });
        if (requestIdData) {
          const apiData = await this.availableProps.loadExternalApiPayload(
            valueObj,
            options
          );

          dataStore.externalApiRequestIds[targetApiRequestId] = {
            ...requestIdData,
            overideJSON: apiData,
          };

          if (requestIdData.elementId) {
            this.evalFunctions.refreshElement(
              { element: { id: requestIdData.elementId } },
              options
            );
          }
        }
      }
    },

    refreshElement: async (valueObj = {}, options = {}) => {
      const elementId = valueObj.element?.id;
      if (elementId) {
        const combinedELementData =
          this.availableProps?.dataStore?.data?.[elementId];

        for (const key in combinedELementData) {
          if (Object.hasOwnProperty.call(combinedELementData, key)) {
            const elementRow = combinedELementData[key];
            elementRow?.triggers
              ?.reload()
              ?.catch((e) =>
                console.warn("Error reloading element: ", e, elementRow)
              );
          }
        }
      }
    },

    triggerPushNotification: async (valueObj = {}, options) => {
      let data = {};
      await Promise.all(
        ["targetDeviceIds", "notificationTitle", "notificationBody"].map(
          async (key) => {
            data[key] = (
              await this.availableProps.evaluateQuickSelectionValue(
                valueObj?.[key] || {},
                options
              )
            )?.value;
          }
        )
      );

      if (data.targetDeviceIds) {
        data.targetDeviceIds = this.getStringArrayFromCalculationResult(
          data.targetDeviceIds
        );

        return this.availableProps.utils.sendPushNotification(data);
      }
    },

    sendSMS: async (valueObj = {}, options) => {
      let data = {};
      await Promise.all(
        ["targetPhoneNumbers", "smsText"].map(async (key) => {
          data[key] = (
            await this.availableProps.evaluateQuickSelectionValue(
              valueObj?.[key] || {},
              options
            )
          )?.value;
        })
      );

      if (data.targetPhoneNumbers) {
        data.targetPhoneNumbers = this.getStringArrayFromCalculationResult(
          data.targetPhoneNumbers
        );

        return this.availableProps.utils.sendSMS({
          targetPhoneNumbers: data.targetPhoneNumbers,
          smsPayload: { body: data.smsText },
        });
      }
    },

    sendEmail: async (valueObj = {}, options = {}) => {
      let data = {};

      const emailFormat = valueObj?.emailFormat || "template";

      await Promise.all(
        ["targetEmailAddresses", "subject", "body", "emailTemplate"].map(
          async (key) => {
            data[key] = (
              await this.availableProps.evaluateQuickSelectionValue(
                valueObj?.[key] || {},
                options
              )
            )?.value;
          }
        )
      );

      if (data.emailTemplate && emailFormat === "template") {
        let processedUrlParameters = await this.processUrlParameters(
          data.urlParameters,
          options
        );

        let passedParameters = (options.urlParameters || []).filter(
          (x) =>
            x &&
            (x?.sourceType !== "urlParam" ||
              !Object.keys(processedUrlParameters).includes(x.urlParamName))
        );

        for (const key in processedUrlParameters) {
          if (Object.hasOwnProperty.call(processedUrlParameters, key)) {
            const value = processedUrlParameters[key];
            passedParameters.push({
              uid: Math.random(),
              urlParamName: key,
              value,
              sourceType: "urlParam",
            });
          }
        }

        const htmlBuilder = new HtmlBuilder({
          availableProps: this.availableProps,
        });

        const emailTemplate = this.availableProps.projectData?.screens?.find(
          (x) => x._id === data.emailTemplate
        );

        const generatedEmail = await htmlBuilder.generateHtml(
          { json: emailTemplate?.data || {} },
          { ...options, passedParameters }
        );

        data.body = generatedEmail;
      }

      if (data.targetEmailAddresses) {
        data.targetEmailAddresses = this.getStringArrayFromCalculationResult(
          data.targetEmailAddresses
        );

        const emailData = {
          targetEmailAddresses: data.targetEmailAddresses,
          emailPayload: {
            subject: data.subject,
            text: data.body,
            html: data.body,
          },
        };

        console.log({
          subject: emailData.emailPayload.subject,
          body: emailData.emailPayload.html,
          emailIds: emailData.targetEmailAddresses,
        });
        
        return this.availableProps.utils.sendEmail(emailData);
      }
    },
  };

  getStringArrayFromCalculationResult(input) {
    if (typeof input === "object" && input instanceof Array) {
      return input?.map((x) => x.value || x?.toString());
    } else {
      return input
        ?.toString?.()
        ?.split(",")
        .map((x) => x.trim())
        .filter((x) => !!x);
    }
  }

  async processUrlParameters(urlParameters, options) {
    let processedUrlParameters = {};
    if (urlParameters)
      await Promise.all(
        urlParameters?.map(async (item) => {
          const [parameterName, parameterValue] = await Promise.all(
            ["parameterName", "parameterValue"].map(async (key) => {
              return (
                await this.availableProps.evaluateQuickSelectionValue(
                  item?.[key],
                  options
                )
              )?.value;
            })
          );
          processedUrlParameters[parameterName] = parameterValue;
        })
      );

    return processedUrlParameters;
  }
}

const linkingObj = {
  valueTypes: linkingValueTypes,
};

export default linkingObj;
