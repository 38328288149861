import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import update from "immutability-helper";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import api from "../../Services/Api/api";
import CustomSelect from "../../Components/etc/CustomSelect";
import TableViewer from "./TableViewer";
import { AddRecordBtn } from "./AddRecord";

class DatabasePanel extends React.Component {
  state = { visible: false };

  componentDidMount() {
    this.load();
  }

  async load() {
    await this.loadDatabases();

    const database = this.props.databases?.find((x) => x.type === "remote");
    const tableId = database?.tables?.[0]?._id;
    this.props.setScreenState({
      database,
      tableId,
    });
  }

  async loadDatabases() {
    try {
      this.props.setScreenState({ loading: true });
      const { databases } = await api.get("v1/database");
      this.props.setScreenState({ databases }, false, "BUILDER");
      this.props.setScreenState({ loading: false });
    } catch (e) {
      console.error(e);
      this.props.setScreenState({ loading: false });
      toast.error(e.message);
    }
  }

  selectDatabase(dbId) {
    this.props.setScreenState({
      database: this.props.databases?.find((x) => x._id === dbId),
    });
  }

  async deleteSelected() {
    try {
      const selectedItems = this.props.selectedItems;

      if (!selectedItems?.length) return;
      const payload = {
        dbId: this.props.database._id,
        tableId: this.props.tableId,
        filters: [
          {
            name: "[OR]",
            type: "filterGroup",
            filters: selectedItems.map((x) => ({
              name: "_id",
              value: x._id,
            })),
          },
        ],
        limit: 0,
        valueType: "deleteRecord",
      };

      return api.post("v1/database/write", payload).then((data) => {
        let indices = [],
          deletedIds = data?.records?.map((x) => x._id);
        for (let i = 0; i < this.props.items?.length; i++) {
          const item = this.props.items[i];

          if (deletedIds.includes(item._id)) indices.push(i);
        }

        if (indices.length) {
          this.props.setScreenState({
            items: update(this.props.items || [], {
              $splice: indices.map((i) => [i, 1]),
            }),
          });
        }

        return data;
      });
    } catch (e) {
      toast.error("Error deleting item(s): ", e.message);
    }
  }

  getTable() {
    return this.props.database?.tables?.find(
      (x) => x._id === this.props.tableId
    );
  }

  render() {
    const {
      props: { databases = [], database, tableId },
    } = this;

    const header = (
      <div className="builderDatabaseInner">
        <div className="panelDatabaseSelector">
          <div className="pdsLabel">Database</div>
          <CustomSelect
            onChange={(option) => this.selectDatabase(option.value)}
            value={database?._id || ""}
            options={databases.map((x) => ({
              value: x._id,
              label: `${x.name} (${x.type})`,
            }))}
            placeholder={"Select Database"}
            classNames={{
              head: "optionInputIconBox",
              label: "optionInputIconBoxField",
              chevron: "optionDatabaseSelectChevron",
            }}
          />
        </div>
        <div className="databaseRowOperationActions">
          <AddRecordBtn
            {...{
              database,
              tableId,
              columns: this.getTable()?.columns?.map((x) => x.name),
              onSuccess: (x) => {
                if (x.records?.length)
                  this.props.setScreenState({
                    items: [...x.records, ...(this.props.items || [])],
                  });
              },
            }}
          >
            Add
          </AddRecordBtn>
          <div
            onClick={() =>
              this.props.setScreenState({ triggerEdit: Date.now() })
            }
          >
            Edit
          </div>
          <div onClick={() => this.deleteSelected()}>Delete</div>
        </div>
      </div>
    );

    const sidebar = (
      <div className="dbpanelTableHolder">
        <div className="pdsLabel">Tables</div>
        <div className="dbpanelTableHolderInner">
          {database?.tables?.map((table) => {
            const active = table._id === tableId;
            return (
              <div
                className={`dbpanel-sidebar-table-item ${
                  active ? "active" : ""
                }`}
                key={table._id}
                onClick={() =>
                  this.props.setScreenState({ tableId: table._id })
                }
              >
                {table.name}
              </div>
            );
          })}
        </div>
      </div>
    );

    return (
      <div className="builderDatabaseWrapper">
        <div
          className={
            "databaseAnchorAction " + (this.state.visible ? "active" : "")
          }
          onClick={() => this.setState({ visible: !this.state.visible })}
        >
          <div className="daaDecor">
            +
            {/* <img
              className="daaDecorIco"
              src={require("../../Assets/img/options/database/view.png")}
            ></img> */}
          </div>
          <div className="daaLabel">Database</div>
        </div>
        <div
          className="builderDatabase"
          style={{
            maxHeight: this.state.visible ? "600px" : "0px",
            transition: " all .75s ease",
            overflow: "hidden",
          }}
        >
          {header}
          <div>
            {sidebar}
            <TableViewer key={database?._id + tableId} />
          </div>
        </div>
      </div>
    );
  }
}

const SCREEN_NAME = "DATABASE_PANEL";

const mapStateToProps = (state) => ({
  databases: state.vState.BUILDER.databases,
  database: state.vState[SCREEN_NAME].database,
  tableId: state.vState[SCREEN_NAME].tableId,
  selectedItems: state.vState[SCREEN_NAME].selectedItems,
  items: state.vState[SCREEN_NAME].items,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatabasePanel);
