import React from "react";
import update from "immutability-helper";

const TabButton = ({ removeTab, active, onClick, name }) => {
  return (
    <div
      className={`optionConditionHeaderItem${active ? " active" : ""}`}
      onClick={onClick}
    >
      <div>Condition</div> <span>{name}</span>
      <div
        className="removeConditionIco"
        onClick={(e) => {
          e.stopPropagation();
          removeTab();
        }}
      >
        <div className="removeConditionIcoLine one"></div>
        <div className="removeConditionIcoLine two"></div>
      </div>
    </div>
  );
};

const ConditionTabs = ({ value = {}, onChange }) => {
  const tabs = value.tabs || [];
  const setTabs = (tabs) => onChange({ ...value, tabs });

  const addTab = () => setTabs([...tabs, { uid: Date.now() }]);
  const removeTab = (i) => {
    const updattedTabs = update(tabs, { $splice: [[i, 1]] });
    setTabs(updattedTabs);
  };

  const activeTabIndex = value.activeTabIndex || 0;
  const setActiveTab = (i) => onChange({ ...value, activeTabIndex: i });

  return (
    <div className="optionConditionItemHead revealOffset">
      <div className="optionConditionHeader">
        {tabs.map((tab, i) => (
          <TabButton
            key={tab.uid}
            removeTab={() => removeTab(i)}
            onClick={() => setActiveTab(i)}
            active={i === activeTabIndex}
            name={`${i + 1}`}
          />
        ))}
        <div className="optionConditionHeaderItemAdd" onClick={addTab}>
          <div className="optionConditionHeaderItemLine one"></div>
          <div className="optionConditionHeaderItemLine two"></div>
        </div>
      </div>
    </div>
  );
};

export default ConditionTabs;
