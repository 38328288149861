import React from "react";
import _ from "lodash";

import QuickValueSelector from "../../../../Common/QuickValueSelector/QuickValueSelector";
import OptionItem from "../../../../../Components/etc/OptionItem";
import { RadioBoxHorizontal } from "../../../../../Components/etc/RadioBox";
import RichTextData from "../../Common/DataProperties/RichTextData";
import { LinkingBoxes, LinkingBoxesPopup } from "../../../../Common/Linking/LinkingBox";

const InputData = (props) => {
  const { value = {}, onChange, inputType = { group: "textInput" } } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const setTextParts = (valueObj) => {
    mergeChange({
      valueType: "textParts",
      valueObj: { ...(value.valueObj || {}), ...valueObj },
    });
  };

  const inputDataSource = (inputType) => {
    switch (inputType) {
      case "textInput":
      case "locationInput":
      case "switch":
      case "checkBox":
        return (
          <OptionItem optionItemLabel="DATA">
            <div className="optionItemBody">
              <div className="optionItemRow pad7">
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      Default Value
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: inputType,
                      value: value.valueObj || {},
                      onChange: setTextParts,
                    }}
                  />
                </div>
              </div>
            </div>
          </OptionItem>
        );

      case "radio":
      case "selectBox":
        return (
          <OptionItem optionItemLabel="DATA">
            <div className="optionItemBody">
              <div className="optionItemRow pad7">
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      Selected Option
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: inputType + "-selected",
                      placeholder: "Selected Value",
                      value: value.selected?.valueObj || {},
                      onChange: (valueObj) =>
                        mergeChange({
                          selected: { valueObj, valueType: "textParts" },
                        }),
                    }}
                  />
                </div>
              </div>
              <div className="optionItemRow pad7">
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      Available Options
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: inputType + "-options",
                      placeholder: "Comma separated value",
                      value: value.options?.valueObj || {},
                      onChange: (valueObj) =>
                        mergeChange({
                          options: { valueObj, valueType: "textParts" },
                        }),
                    }}
                  />
                </div>
              </div>
            </div>
          </OptionItem>
        );

      case "imagePicker":
      case "filePicker":
        return (
          <OptionItem optionItemLabel="DATA">
            <div className="optionItemBody">
              <div className="optionItemRow pad7">
                <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                  <div className="optionConditionTableSelectorFilterItemHead">
                    <div className="optionConditionTableFilterMetaRequirementLabel">
                      Label Text
                    </div>
                  </div>

                  <RichTextData
                    {...{
                      ...props,
                      key: inputType + "-labeltext",
                      placeholder: "Upload",
                      value: value.label?.valueObj || {},
                      onChange: (valueObj) =>
                        mergeChange({
                          label: { valueObj, valueType: "textParts" },
                        }),
                    }}
                  />
                </div>
              </div>
            </div>
          </OptionItem>
        );

      case "dateTimePicker":
        return (
          <OptionItem optionItemLabel="DATA">
            <div className="optionItemBody">
              <div className="optionItemRow">
                <RadioBoxHorizontal
                  value={value.datetimePicker?.selectType}
                  onChange={(x) =>
                    mergeChange({
                      datetimePicker: {
                        ...(value.datetimePicker || {}),
                        selectType: x,
                      },
                    })
                  }
                  options={[
                    { value: "singleSelect", label: "Single" },
                    { value: "rangeSelect", label: "Range" },
                  ]}
                />
              </div>
              {[
                { value: "minDate", label: "Minimum Date" },
                { value: "maxDate", label: "Maximum Date" },
                { value: "minTime", label: "Minimum Time" },
                { value: "maxTime", label: "Maximum Time" },
                { value: "disabledDates", label: "Disable Dates" },
                { value: "allowedDates", label: "Allowed Dates" },
              ].map((item) => {
                const itemValue = value.datetimePicker?.[item.value] || {};
                const onChange = (valueObj) =>
                  mergeChange({
                    datetimePicker: {
                      ...(value.datetimePicker || {}),
                      [item.value]: {
                        ...itemValue,
                        valueObj,
                        valueType: "textParts",
                      },
                    },
                  });

                return (
                  <div className="optionItemRow pad7" key={item.value}>
                    <div className="optionConditionTableSelectorFilterItemContext lightenMode">
                      <div className="optionConditionTableSelectorFilterItemHead">
                        <div className="optionConditionTableFilterMetaRequirementLabel">
                          {item.label || _.startCase(item.value)}
                        </div>
                      </div>

                      <RichTextData
                        {...{
                          ...props,
                          key: inputType + "-" + item.value,
                          value: itemValue.valueObj,
                          onChange,
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </OptionItem>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {inputDataSource(inputType.group)}
      {["textInput"].includes(inputType.group) ? (
        <LinkingBoxes
          {...{
            ...props,
            value: value.linkings,
            onChange: (linkings) => mergeChange({ linkings }),
            availableFor: ["emailTemplate"].includes(props.availableFor)
            ? props.availableFor
            : "front",
            autoAddOneEmptyLinking: false,
            triggerOnOptions: ["onPress", "onKeyPress", "onLoad"].map((x) => ({
              value: x,
              label: _.startCase(x),
            })),
            linkBoxOptions: {},
          }}
        />
      ) : null}
    </>
  );
};

export default InputData;
