import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import update from "immutability-helper";
import _ from "lodash";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";

import { isLoggedIn } from "../../Stores/redux/Persisted/Selectors";
import modules from "../../Modules";
import AppModal from "../../Components/Models/app-modal";
import api from "../../Services/Api/api";

const LoginScreen = ({ isLoggedIn }) => {
  const [newUser, setNewUser] = useState(false);
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginRes, setLoginRes] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      const gotohome = () => {
        let defaultNextScreen = "/dashboard";
        let nextScreen =
          modules.navigation.getQuery("nextScreen") || defaultNextScreen;
        nextScreen = modules.navigation.matchCurrentPath(nextScreen)
          ? defaultNextScreen
          : nextScreen;

        navigate(nextScreen);
      };

      gotohome();
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn) return null;

  const updateForm = (obj) => {
    setForm(update(form, { $merge: obj }));
  };
  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      if (loading) return;
      setLoading(true);
      setFormError(null);

      const loginRes = await api.post("v1/user/login", {
        email: form?.email,
        password: form?.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      setLoading(false);

      let inviteAcceptedTeams = loginRes.user.teams.filter(
        (x) => x.acceptedAt || x.role === "owner"
      );

      loginRes.user.teams = inviteAcceptedTeams;

      if (inviteAcceptedTeams.length > 1) {
        setLoginRes(loginRes);
      } else {
        await onTeamSelect(inviteAcceptedTeams[0], loginRes);
      }
    } catch (e) {
      setFormError(e.message);
      setLoading(false);
    }
  };

  const onTeamSelect = async (team, loginRes) => {
    modules.auth.initAuth({ ...loginRes, teamId: team.user._id, team });
  };

  const signup = async (e) => {
    try {
      e.preventDefault();

      if (loading) return;
      setLoading(true);
      setFormError(null);

      const payload = {
        firstName: form.name,
        email: form?.email,
        password: form?.password,
      };
      await modules.auth.signup(payload);

      const loginRes = await api.post("v1/user/login", {
        email: payload.email,
        password: payload.password,
        clientType: "web",
        deviceId: await modules.etc.getDeviceToken(),
      });

      setLoading(false);

      if (loginRes.user.teams.length > 1) {
        setLoginRes(loginRes);
      } else {
        await onTeamSelect(loginRes.user.teams[0], loginRes);
      }
    } catch (e) {
      setLoading(false);
      setFormError(e.message);
    }
  };

  return (
    <div className="login-page">
      <div className="form">
        {formError ? <div className="error">{formError}</div> : ""}
        <form
          className="register-form"
          style={!newUser ? styleObj.hidden : {}}
          onSubmit={(e) => signup(e)}
        >
          <input
            type="text"
            placeholder="Full Name"
            onChange={(e) => {
              updateForm({ name: e.target.value });
            }}
            required
          />
          <input
            type="text"
            placeholder="Phone Number"
            onChange={(e) => {
              updateForm({ phone: e.target.value });
            }}
            required
          />
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            required
          />
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => {
              updateForm({ password: e.target.value });
            }}
            required
          />
          <button>{loading ? "Creating..." : "create"}</button>
          <p className="message">
            Already registered?{" "}
            <span className="a" onClick={() => setNewUser(false)}>
              Sign In
            </span>
          </p>
        </form>
        <form
          className="login-form"
          style={newUser ? styleObj.hidden : {}}
          onSubmit={(e) => handleLogin(e)}
        >
          <input
            type="email"
            placeholder="email"
            onChange={(e) => {
              updateForm({ email: e.target.value });
            }}
            required
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => {
              updateForm({ password: e.target.value });
            }}
            required
          />
          <button>{loading ? "Logging In" : "login"}</button>
          <p className="message">
            Not registered?{" "}
            <span className="a" onClick={() => setNewUser(true)}>
              Create an account
            </span>
          </p>
        </form>
      </div>

      <AppModal
        showFooter={false}
        title={"Select Team"}
        visible={!!loginRes}
        handleClose={() => setLoginRes(null)}
      >
        {loginRes?.user.teams.map((team) => (
          <div
            key={team.user._id}
            onClick={() => onTeamSelect(team, loginRes)}
            style={{
              cursor: "pointer",
              padding: "10px",
              backgroundColor: "#efefef",
              marginBottom: "10px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              {team.user?.firstName} {team.user?.lastName}
            </div>
            <div>{_.startCase(team.role)}</div>
          </div>
        ))}
      </AppModal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = "LOGIN_SCREEN") =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const styleObj = {
  hidden: {
    display: "none",
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
