import React, { useEffect } from "react";
import update from "immutability-helper";

import PlotMarks from "./PlotMarks";
import CreateRoute from "./CreateRoute";
import Navigate from "./Navigate";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../Components/Input/ToggleButton";
import MapCenter from "./MapCenter";

const MapData = (props) => {
  const { value = {}, onChange } = props;
  const mergeChange = (obj) => onChange({ ...value, ...obj });

  const operations = value.operations || [
    { uid: Math.random(), typeOfData: "fixed" },
  ];
  const setOperation = (operation, i) => {
    mergeChange({
      operations: update(operations, { $merge: { [i]: operation } }),
    });
  };

  return (
    <div>
      {operations.map((operation, i) => (
        <MapOperation
          {...props}
          key={i}
          value={operation}
          onChange={(value) => setOperation(value, i)}
          delete={() =>
            mergeChange({
              operations: update(operations, { $splice: [[i, 1]] }),
            })
          }
        />
      ))}

      <div
        onClick={() =>
          setOperation(
            { uid: Math.random(), typeOfData: "fixed" },
            operations.length
          )
        }
        className="mapLinkingWrapper"
      >
        <div className="optionAddLinkingButton">Add Map Item</div>
      </div>

      <div className="mapLinkingWrapper">
        <div className="optionDatabaseTextBox mt10">
          <div className="optionDatabaseTextBoxLabel">Map Center</div>
          <MapCenter
            {...props}
            value={value.mapCenter}
            onChange={(value) => mergeChange({ mapCenter: value })}
          />
        </div>
      </div>
    </div>
  );
};

const MapOperation = (props) => {
  const { value, onChange } = props;

  const operation = value || {};
  const setOperationObj = (obj) => {
    onChange(update(operation, { $merge: obj }));
  };

  useEffect(() => {
    if (!operation.operation) setOperationObj({ operation: "mark" });
  }, []);

  const components = {
    mark: <PlotMarks {...props} value={operation} onChange={onChange} />,
    route: <CreateRoute {...props} value={operation} onChange={onChange} />,
    navigate: <Navigate {...props} value={operation} onChange={onChange} />,
  };

  const selectedOperation = operation.operation;

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={operation.typeOfData}
      onChange={(value) => setOperationObj({ typeOfData: value })}
      options={[
        { label: "Fixed", value: "fixed" },
        { label: "Repeating", value: "repeating" },
      ]}
    />
  );

  return (
    <>
      <OptionItem optionItemLabel="MAP" optionHeadTabs={optionHeadTabs}>
        <div className="optionItemBody">
          <div className="optionItemRow">
            <div className="linkingBoxWrapper">
              <div className="linkingBoxMain">
                <div className="linkingBoxHead">
                  <div className="optionItemBody">
                    <div className="linkLabel">Map Item</div>
                    <div className="linkDropdown">
                      <div className="linkDropdownHead">
                        <div className="calcItemHeadTab">
                          {operationOptions?.map((item) => {
                            const isActive = operation.operation === item.value;

                            return (
                              <div
                                key={item.value}
                                className={`calcItemHeadTabItem ${
                                  isActive ? "active" : ""
                                }`}
                                onClick={() =>
                                  setOperationObj({ operation: item.value })
                                }
                              >
                                {item.label}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {components[selectedOperation] || null}

                {props.delete ? (
                  <div className="removeX" onClick={props.delete}>
                    <div className="removeXLine one"></div>
                    <div className="removeXLine two"></div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </OptionItem>
    </>
  );
};

const operationOptions = [
  {
    label: "Marker",
    value: "mark",
  },
  {
    label: "Route",
    value: "route",
  },
  // {
  //   label: "Navigate",
  //   value: "navigate",
  // },
];

export default MapData;
