import React, { useEffect } from "react";
import { connect } from "react-redux";
import CustomSelect from "../../../Components/etc/CustomSelect";

const DatabaseSortingQuery = (props) => {
  const [columns, setColumns] = React.useState([]);

  const { value = {}, onChange, databases } = props;
  const mergeChange = (obj) => onChange({ ...(value || {}), ...obj });

  const { order, sortBy, dbId, tableId } = value;

  useEffect(() => {
    const table = databases
      ?.find((x) => x._id === dbId)
      ?.tables?.find((x) => x._id === tableId);

    setColumns(table?.columns?.map((x) => x.name) || []);
  }, [dbId, tableId, databases]);

  return (
    <div className="calcItemUnitFooter" style={{ flexDirection: "row" }}>
      <div className="calcItemUnitMain">
        <div className="calcItemUnitMainLabel">Sort By</div>

        <CustomSelect
          classNames={{
            head: "calcItemUnitMainDropdown",
            label: "calcItemUnitMainDropdownLabel",
          }}
          value={sortBy || ""}
          onChange={(option) => mergeChange({ sortBy: option.value })}
          options={columns.map((x) => ({ value: x, label: x }))}
          placeholder={"Select Column"}
          jointActionRow={chev}
        />
      </div>

      <div className="calcItemUnitMain">
        <div className="calcItemUnitMainLabel">Order</div>

        <CustomSelect
          classNames={{
            head: "calcItemUnitMainDropdown",
            label: "calcItemUnitMainDropdownLabel",
          }}
          value={order || "asc"}
          onChange={(option) => mergeChange({ order: option.value })}
          options={[
            { value: "asc", label: "Ascending" },
            { value: "dsc", label: "Descending" },
          ]}
          placeholder={"Select Direction"}
          jointActionRow={chev}
        />
      </div>
    </div>
  );

  return (
    <div className="optionItemRow">
      <div className="optionDatabaseBoxWrapper modifiedBoxes">
        <div className="optionDatabaseBoxLabel">Order Data</div>
        <div className="optionDatabaseBox">
          <div className="optionDatabaseOrder">
            <div className="optionDatabaseOrderItem">
              <div className="optionDatabaseOrderContext">
                <CustomSelect
                  value={sortBy || ""}
                  onChange={(option) => mergeChange({ sortBy: option.value })}
                  options={columns.map((x) => ({ value: x, label: x }))}
                  placeholder={"Select Column"}
                  classNames={{
                    head: "optionDatabaseOrderKey",
                    label: "optionDatabaseOrderKeyLabel",
                    chevron: "optionDatabaseFilterKeyIco",
                  }}
                />

                <CustomSelect
                  value={order || "asc"}
                  onChange={(option) => mergeChange({ order: option.value })}
                  options={[
                    { value: "asc", label: "Ascending" },
                    { value: "dsc", label: "Descending" },
                  ]}
                  placeholder={"Select Direction"}
                  classNames={{
                    head: "optionDatabaseSortKey",
                    label: "optionDatabaseSortKeyLabel",
                    chevron: "optionDatabaseSortKeyIco",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const chev = (
  <div className="calcItemUnitMainDropdownFooter">
    <div className="calcItemUnitMainDropdownFooterArrow">
      <div className="one"></div>
      <div className="two"></div>
    </div>
  </div>
);

export default connect((state) => ({
  databases: state.vState.BUILDER.databases,
}))(DatabaseSortingQuery);
