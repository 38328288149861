import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import PActions from "../../Stores/redux/Persisted/Actions";
import modules from "../../Modules";
import ConfirmationModal from "../../Components/Models/confirmation-modal";
import api from "../../Services/Api/api";
import Avatar from "../../Components/etc/Avatar";

const SCREEN_NAME = "NAVBAR";

class NavBar extends React.PureComponent {
  renderOld() {
    const {
      props: { activeLink },
    } = this;

    return (
      <Navbar bg="light" variant="light" expand="lg" className="topnavbar">
        <Container>
          <Navbar.Brand as={Link} to="/">
            Appxolo
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="topnavbar-nav" />
          <Navbar.Collapse id="topnavbar-nav">
            <Nav className="me-auto">
              {[
                ["/dashboard", "Dashboard"],
                ["/projects", "Projects"],
                ["/database", "Database"],
              ].map((item) => (
                <Nav.Link
                  key={item[0]}
                  as={Link}
                  to={item[0]}
                  active={activeLink === item[0]}
                >
                  {item[1]}
                </Nav.Link>
              ))}
            </Nav>
            <Nav>
              <NavDropdown title="User" id="topnav-user-dropdown" align="end">
                <NavDropdown.Item
                  as={Link}
                  to="/profile"
                  active={activeLink === "/profile"}
                >
                  Profile
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <LogoutButton history={this.props.history} />
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  render() {
    const {
      props: { right, bottom, activeLink, project },
    } = this;

    return (
      <div>
        <div className="xoloHeader">
          <div className="xoloHeaderTop">
            {project?._id ? (
              <div className="currentAppMeta">
                {project.image ? (
                  <img
                    className="currentAppIco"
                    src={api.getFileLink(project.image)}
                  />
                ) : null}
                <div>
                  <div className="f16 fwSemiBold">{project?.name} Console</div>
                  <div>Register Screen</div>
                </div>
              </div>
            ) : null}

            <div className="sideToggle">
              <div className="sideToggleItem one"></div>
              <div className="sideToggleItem two"></div>
            </div>

            <Link className="xoloLogo" to="/">
              App<span>Wolo</span>
            </Link>

            <div className="userHeaderDropdown">
              <Avatar user={this.props.team?.user} />
            </div>

            <div className="xoloMenu">
              {[
                ["/projects", "My Apps"],
                ["#", "Users"],
                ["#", "Reports"],
              ].map(([link, label], i) => (
                <Link
                  key={i}
                  className={
                    "xoloMenuItem" + (activeLink === link ? " active" : "")
                  }
                  to={link}
                >
                  {label}
                </Link>
              ))}
            </div>
            {right}
          </div>
          {bottom}
        </div>
        {/* {this.renderOld()} */}
      </div>
    );
  }
}

class LogoutButton extends React.PureComponent {
  state = {};

  logout() {
    this.setState({ showModal: false });
    modules.auth.logout();
  }

  render() {
    return (
      <>
        <NavDropdown.Item onClick={() => this.setState({ showModal: true })}>
          Logout
        </NavDropdown.Item>
        <ConfirmationModal
          visible={!!this.state.showModal}
          handleClose={() => this.setState({ showModal: false })}
          onSubmit={this.logout.bind(this)}
          body="Are you sure you want to logout ?"
          submitBtnText="Logout"
          title="Confirm Logout"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  screenState: state.vState[SCREEN_NAME] || {},
  team: state.pState.AUTH.team,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
