import React from "react";
import ControlledInput from "../../../../../Components/Input/ControlledInput";
import OptionItem from "../../../../../Components/etc/OptionItem";
import ToggleButton from "../../../../../Components/Input/ToggleButton";

const PositionStyle = (props) => {
  const styles = props.value || {};
  const mergeStyle = (obj) => props.onChange({ ...styles, ...obj });

  const position = styles.position || "relative";

  const optionHeadTabs = (
    <ToggleButton
      classNames={{
        container: "optionHeadTabs headTabsDark",
        item: "optionHeadTabItem",
      }}
      overideDefaultStyle
      value={position}
      onChange={(x) =>
        mergeStyle({
          position: x,
        })
      }
      options={[
        { value: "relative", label: "Default" },
        { value: "absolute", label: "Fixed" },
      ]}
    />
  );

  return (
    <OptionItem optionHeadTabs={optionHeadTabs} optionItemLabel={"Position"}>
      <div className="optionItemBody revealOffset">
        <div className="optionItemRow">
          {[
            { key: "top", r: "" },
            { key: "bottom", r: "rotate180" },
            { key: "left", r: "rotate270" },
            { key: "right", r: "rotate90" },
          ].map(({ key, r }) => {
            return (
              <div className="optionItemBox" key={key}>
                <div className="optionInputIconBox">
                  <img
                    className={"optionInputIconBoxArrowIco " + r}
                    src={require("../../../../../Assets/img/options/options/greyArrow.png")}
                  ></img>
                  <ControlledInput
                    type="text"
                    onChange={(event) =>
                      mergeStyle({ [key]: event.target.value })
                    }
                    className="optionInputIconBoxSpacingField"
                    value={styles[key] || ""}
                    key={key}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </OptionItem>
  );
};

export default PositionStyle;
